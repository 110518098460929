<script setup>
import { defineProps, ref, toRefs, computed, defineEmits } from 'vue'
import { DraggableItem } from '@/tmp/components/behaviour/draggable';
import { SelectableItem } from '@/tmp/components/behaviour/selectable';
import { FolderIcon, FolderOpenIcon } from 'lucide-vue-next';
import { ResourceItem, ResourceName, ResourceIcon } from '@/tmp/components/resources';
import { SidebarMenuSubItem, SidebarMenuSubButton, SidebarMenuButton, SidebarMenuItem } from '@/components/ui/sidebar';
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';
import { useRoute } from 'vue-router';
import { Folder } from '@/models';
import LibraryTreeContent from './LibraryTreeContent.vue';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

const route = useRoute();

const props = defineProps({
    item: {
        type: Object,
        required: false,
        default: null
    },
    subButton: {
        type: Boolean,
        required: false,
        default: false
    },
})


const { item } = toRefs(props);
const isActive = computed(() => {
    return (
        route.name === 'project-documents'
        || route.name === 'documents'
        || route.name === 'project-document'
        || route.name === 'document'
        || route.name === 'project-sheet'
        || route.name === 'sheet'
        || route.name === 'project-thread'
        || route.name === 'thread'
    )
        && route.params?.projectId == item.value?.project_id
        && (
            route.query?.folderId === item.value?.id
            || route.params?.sheetId === item.value?.id
            || route.params?.threadId === item.value?.id
            || route.params?.documentId === item.value?.id
            || route.params?.resourceId === item.value?.id
        )
});
const isExpanded = ref(isActive.value)
const isFolder = computed(() => item.value instanceof Folder)
const emit = defineEmits(['open', 'drop'])
</script>

<template>
    <SelectableItem :item="item" v-slot="{ isSelected, toggleSelect }">
        <TooltipProvider>
            <Tooltip :disabled="isExpanded">
                <TooltipTrigger asChild>
                    <DraggableItem :item="item" v-slot="{ isDraggedOver }" @long-dragover="isExpanded = true">
                        <component @drop.prevent.stop="emit('drop', item, $event)" v-if="isFolder"
                            :is="subButton ? SidebarMenuSubItem : SidebarMenuItem" :class="subButton ? 'pr-3' : 'pr-3'"
                            draggable="true">
                            <Collapsible v-model:open="isExpanded" class="group/collapsible" as-child>
                                <ResourceItem :item="item" @drop="emit('drop', item, $event)">
                                    <component :is="subButton ? SidebarMenuSubButton : SidebarMenuButton"
                                        @dblclick="emit('open', item); isExpanded = true"
                                        @click="(event) => { isExpanded = !isExpanded; toggleSelect(event) }"
                                        :is-active="isActive" :size="size"
                                        :class="{ [subButton ? 'bg-muted' : 'bg-sidebar-accent/80']: isSelected || isDraggedOver }"
                                        class="-mr-2">
                                        <FolderIcon v-if="!isExpanded" :stroke-width="isActive ? 2.5 : 2" />
                                        <FolderOpenIcon v-else :stroke-width="isActive ? 2.5 : 2" />
                                        <ResourceName :class="{ 'pr-5': subButton }" />
                                    </component>
                                </ResourceItem>
                                <CollapsibleContent class="-mr-[0.22rem]">
                                    <LibraryTreeContent :project-id="item.project_id" :folder-id="item.id"
                                        :sub-button="subButton" />
                                </CollapsibleContent>
                            </Collapsible>
                        </component>
                        <component @drop.prevent.stop="emit('drop', item, $event)" v-else
                            :is="subButton ? SidebarMenuSubItem : SidebarMenuItem" :class="subButton ? 'pr-3' : 'pr-3'">
                            <ResourceItem :item="item" @drop="emit('drop', item, $event)">
                                <component :is="subButton ? SidebarMenuSubButton : SidebarMenuButton"
                                    @click="toggleSelect" @dblclick="emit('open', item)" :is-active="isActive"
                                    :size="size"
                                    :class="{ [subButton ? 'bg-muted' : 'bg-sidebar-accent/80']: isSelected || isDraggedOver }">
                                    <ResourceIcon :stroke-width="isActive ? 2.5 : 2" />
                                    <ResourceName :class="{ 'pr-5': subButton }" />
                                </component>
                            </ResourceItem>
                        </component>
                    </DraggableItem>
                </TooltipTrigger>
                <TooltipContent side="right">
                    {{ item.name }}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    </SelectableItem>
</template>