<script setup>
import { Zap, Library, Text, LayoutTemplate, Grid2x2 } from 'lucide-vue-next';
import { useRouter, useRoute } from 'vue-router';
import { useRepo, Thread, Sheet, PromptTemplate, SheetTemplate, Project } from '@/models';
import { TemplateDialog } from '@/tmp/services/templates';
import { Shortcut } from '@/tmp/components/shortcuts'
import { LibraryTableDialog, useLibrary } from '@/tmp/services/library'
import { computed } from 'vue';


const router = useRouter()
const route = useRoute()

const projectId = computed(() => route.params?.projectId)
const project = computed(() => useRepo(Project).find(projectId.value))
const { move } = useLibrary(projectId, null)


const threadRepo = useRepo(Thread)
const sheetRepo = useRepo(Sheet)

async function createThread(template = null) {
    const response = await threadRepo.api().post('/threads/', {
        name: template?.name || 'New Thread',
        project_id: route.params?.projectId || null,
        folder_id: route.query?.folderId || null
    })
    const routeConfig = {
        name: response.entities[0].project_id ? 'project-thread' : 'thread',
        params: { threadId: response.entities[0].id, projectId: response.entities[0].project_id },
    }
    if (template) {
        routeConfig.query = { templateId: template.id }
    }
    router.push(routeConfig)
}

async function createSheet(template = null) {
    const response = await sheetRepo.api().post('/sheets/', {
        name: template?.name || 'New Sheet',
        project_id: route.params?.projectId || null,
        folder_id: route.query?.folderId || null,
        prompts: template?.prompts
    })
    const routeConfig = {
        name: response.entities[0].project_id ? 'project-sheet' : 'sheet',
        params: { sheetId: response.entities[0].id, projectId: response.entities[0].project_id },
    }
    router.push(routeConfig)
}

async function applyTemplate(template) {
    if (template instanceof PromptTemplate) {
        await createThread(template)
    } else if (template instanceof SheetTemplate) {
        await createSheet(template)
    }
}
</script>

<template>
    <div class="flex flex-col w-full 2xl:w-1/2 ">
        <div class="flex flex-row items-center space-x-2 pb-2  mb-2">
            <Zap class="text-primary size-5" :stroke-width="2" />
            <span class="text-left text-lg text-primary font-semibold">Actions</span>
        </div>
        <Grid class="grid grid-cols-4 gap-4 2xl:grid-cols-2">
            <Shortcut @click="createThread" name="Start a thread"
                description="Chat with legal documents and get rapid answers to legal questions.">
                <Text class="text-yellow-500" />
            </Shortcut>
            <Shortcut @click="createSheet" name="Start blank sheet"
                description="Analyze multiple documents in tabular format.">
                <Grid2x2 class="text-green-500" />
            </Shortcut>
            <TemplateDialog @apply="applyTemplate">
                <Shortcut name="Start from template" description="Select a template to analyze specific use cases.">
                    <LayoutTemplate fill="currentColor" class="text-primary" />
                </Shortcut>
            </TemplateDialog>
            <LibraryTableDialog @submit="({ selectedItems }) => move(project, selectedItems)" min-selected="1" multiple
                selectable="checkbox" :filters="{ type: 'documents' }">
                <Shortcut name="Add documents" description="Import documents from your shared library.">
                    <Library class="text-primary" />
                </Shortcut>
            </LibraryTableDialog>
        </Grid>
    </div>
</template>