<script setup>
import { Loading } from '@/components';
import { Sidebar } from '@/tmp/components/sidebar';
import { RouterView } from 'vue-router';
import { Toaster } from '@/components/ui/sonner';
import { useAuth } from '@/auth';
import { useData } from '@/composables';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { SidebarProvider, SidebarInset } from '@/components/ui/sidebar';
import { Sidepanel, useSidepanel } from '@/tmp/components/sidepanel';
import { storeToRefs } from 'pinia';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { MaintenancePage } from '@/views';
import { ref, computed } from 'vue';



const auth = useAuth();
const { isLoading } = useData();
const sidepanel = useSidepanel();
const { isOpen: isSidepanelOpen, isFullscreen: isSidepanelFullscreen } = storeToRefs(sidepanel);
const isSidebarOpen = ref(true);

const isUnderMaintenance = computed(() => auth.user?.subscription?.plan === 'free')

</script>

<template>
  <MaintenancePage v-if="isUnderMaintenance" />
  <section v-else-if="auth.isAuthenticated && !auth.isLoading && !isLoading" class="h-screen max-w-screen bg-sidebar">
    <SidebarProvider :default-open="isSidebarOpen" @update:open="isSidebarOpen = $event">
      <Sidebar />
      <SidebarInset>
        <ResizablePanelGroup direction="horizontal" auto-save-id="main-panel-group">
          <ResizablePanel v-show="!isSidepanelFullscreen" :min-size="50" class="bg-transparent">
            <RouterView />
          </ResizablePanel>
          <ResizableHandle v-show="isSidepanelOpen && !isSidepanelFullscreen"
            class="bg-transparent px-[theme(spacing.1)]" with-handle />
          <ResizablePanel v-show="isSidepanelOpen" :min-size="25" class="bg-transparent">
            <Sidepanel />
          </ResizablePanel>
        </ResizablePanelGroup>
      </SidebarInset>
    </SidebarProvider>
    <Toaster />
    <ConfirmDialog />
  </section>
  <Loading v-else />
</template>

<style>
@import 'css/tailwind/tailwind.min.css';
@import 'main.css';

#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: hsl(var(--primary));
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Satoshi', sans-serif;
  color: hsl(var(--primary));
}

p,
span {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
</style>
