import { ProjectService } from '../service';

export function useProject(projectId) {
    const { project, isLoading } = ProjectService.getProject(projectId)

    async function saveProject() {
        await ProjectService.updateProject(project)
    }

    async function deleteProject() {
        await ProjectService.deleteProject(project)
    }

    return { project, isLoading, saveProject, deleteProject }
}
