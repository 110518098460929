import { ProjectService } from '../service';

export function useProjects() {
    const { projects, isLoading } = ProjectService.getProjects()

    return {
        projects,
        isLoading,
        createProject: ProjectService.createProject,
        updateProject: ProjectService.updateProject,
        deleteProject: ProjectService.deleteProject
    }
}