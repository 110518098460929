<script setup>
import { MainLayout, NavbarLayout } from '@/layouts';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage } from '@/components/ui/breadcrumb';
import { Compass, Gavel, Scale, Shapes } from 'lucide-vue-next';
import { ExploreGroup } from '../components';
import { useRouter } from 'vue-router';

const router = useRouter();
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbPage class="flex flex-row space-x-2 items-center">
                                    <Compass />
                                    <span>Explore</span>
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #content>
            <div class="w-full flex flex-col gap-12 px-3">
                <ExploreGroup type="collections" :num-results="4" size="lg"
                    @show-more="router.push({ name: 'collections' })">
                    <Shapes class="w-6 h-6" />
                    <span class="text-lg font-semibold">Collections</span>
                </ExploreGroup>
                <ExploreGroup type="cases" :num-results="4" size="lg"
                    @show-more="router.push({ name: 'type-explore', params: { type: 'cases' } })">
                    <Gavel class="w-6 h-6" />
                    <span class="text-lg font-semibold">Case Law</span>
                </ExploreGroup>
                <ExploreGroup type="regulations" :num-results="4" size="lg"
                    @show-more="router.push({ name: 'type-explore', params: { type: 'regulations' } })">
                    <Scale class="w-6 h-6" />
                    <span class="text-lg font-semibold">Laws & Regulations</span>
                </ExploreGroup>
            </div>
        </template>
    </MainLayout>
</template>