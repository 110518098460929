<script setup>
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage, BreadcrumbLink, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { Clock, User, TextCursorInput, Plus, Shapes, Compass } from 'lucide-vue-next';
import { MainLayout, NavbarLayout, HeaderLayout } from '@/layouts';
import { Button } from '@/components/ui/button';
import { Collection, useRepo } from '@/models';
import { ResourceList } from '@/resource';
import { Loading } from '@/components';
import { useCollections, CreateCollectionDialog } from '..';
import { useRouter } from 'vue-router';

const router = useRouter()

const { isLoading, deleteCollection } = useCollections()
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbLink>
                                    <router-link class="flex flex-row space-x-2 items-center" :to="{ name: 'explore' }">
                                        <Compass />
                                        <span>Explore</span>
                                    </router-link>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbPage class="flex flex-row space-x-2 items-center">
                                    <Shapes />
                                    <span>Collections</span>
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #header>
            <HeaderLayout>
                <template #left-actions>
                    <CreateCollectionDialog>
                        <Button size="sm" variant="default" class="flex flex-row space-x-2 items-center rounded-3xl">
                            <Plus class="w-4 h-4 flex-shrink-0" :stroke-width="2.5" />
                            <span>New Collection</span>
                        </Button>
                    </CreateCollectionDialog>
                </template>
            </HeaderLayout>
        </template>
        <template #content>
            <Loading v-if="isLoading" />
            <ResourceList v-else
                @dblclick="(item) => router.push({ name: 'collection', params: { collectionId: item.id } })"
                @delete="deleteCollection" :columns="[
                    { key: 'name', label: 'Name', icon: TextCursorInput },
                    { key: 'created_at', label: 'Created', icon: Clock },
                    { key: 'owner', label: 'Created by', icon: User },
                    { key: 'updated_at', label: 'Last updated', icon: Clock },
                ]" :order-by="{ key: 'created_at', direction: 'desc' }" :repo="useRepo(Collection)" />
        </template>
    </MainLayout>
</template>