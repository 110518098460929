import { useRepo, Collection, Bookmark, Case } from '@/models';
import { useQuery } from '@tanstack/vue-query';
import { useRouter } from 'vue-router';
import { computed, toValue } from 'vue';


const router = useRouter()


export async function addToCollection({ collection, bookmark, note = null }) {
    collection = toValue(collection)
    bookmark = toValue(bookmark)
    if (typeof collection === 'string') {
        collection = useRepo(Collection).find(collection)
    }
    const prefix = bookmark.constructor.entity.slice(0, -1)
    await useRepo(Bookmark).api().post(`/${prefix}`, {
        collection_id: collection.id,
        [`${prefix}_id`]: bookmark.id,
        note
    })
}

export async function removeFromCollection({ collection, bookmark }) {
    collection = toValue(collection)
    bookmark = toValue(bookmark)
    if (typeof collection === 'string') {
        collection = useRepo(Collection).find(collection)
    }
    let bookmarkId = bookmark
    if (bookmark instanceof Bookmark) {
        bookmarkId = bookmark.id
    }
    await useRepo(Bookmark).api().delete(`/bookmarks/${bookmarkId}`, { delete: bookmarkId })
}

export function getCollectionBookmarks(collectionId) {
    const { isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['collection', collectionId, 'bookmarks'],
        queryFn: async () => {
            const response = await useRepo(Bookmark).api().get(`/collections/${toValue(collectionId)}/bookmarks`)
            return response.entities
        },
    })
    return { bookmarks: computed(() => useRepo(Bookmark).where('collection_id', toValue(collectionId)).get()), isLoading, isFetching, isError, error }
}

export function getCollection(collectionId) {
    const { isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['collection', collectionId],
        queryFn: async () => {
            const response = await useRepo(Collection).api().get(`/collections/${toValue(collectionId)}`)
            return response.entities[0]
        },
    })
    return { collection: computed(() => useRepo(Collection).find(toValue(collectionId))), isLoading, isFetching, isError, error }
}

export async function updateCollection(collection) {
    const response = await useRepo(Collection).api().patch(`/collections/${toValue(collection).id}`, toValue(collection))
    return response.entities[0]
}

export async function deleteCollection(collection) {
    await useRepo(Collection).api().delete(`/collections/${toValue(collection).id}`, { delete: toValue(collection).id })
}

export async function createCollection({ name, description = null, icon = null, color = null }) {
    const response = await useRepo(Collection).api().post('/collections/', { name, description, icon, color })
    return response.entities[0]
}

export function getCollections() {
    const { data, isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['collections'],
        queryFn: async () => {
            const response = await useRepo(Collection).api().get('/collections/')
            return response.entities
        },
    })
    return { collections: data, isLoading, isFetching, isError, error }
}

export function getBookmark(bookmarkId) {
    const { data, isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['bookmark', bookmarkId],
        queryFn: async () => {
            let response = await useRepo(Bookmark).api().get(`/bookmarks/${toValue(bookmarkId)}`)
            const bm = response.entities[0]
            let subjectId
            let subjectType
            let subjectModel
            if (bm?.case_id) {
                subjectId = bm.case_id
                subjectType = 'case'
                subjectModel = Case
            }
            await useRepo(subjectModel).api().get(`/${subjectType}s/${subjectId}`, {
                dataTransformer: (response) => {
                    if (subjectType == 'case') {
                        response.data.id = response.data.uid
                    }
                    return response.data
                }
            })
            return useRepo(Bookmark).withAllRecursive().find(toValue(bookmarkId))
        },
    })
    return { bookmark: data, isLoading, isFetching, isError, error }
}

export function navigateToCollection(collectionId) {
    router.push({ name: 'collection', params: { collectionId } })
}

export const CollectionService = {
    addToCollection,
    removeFromCollection,
    getCollectionBookmarks,
    getCollection,
    updateCollection,
    deleteCollection,
    getCollections,
    getBookmark,
    createCollection,
    navigateToCollection
}