import { useSheetSocket } from './use-sheet-socket';
import { SheetService } from '../service';

export function useSheet(sheetId) {
    const {
        sheet,
        isLoading
    } = SheetService.getSheet({ sheetId, withAll: true })
    const {
        addPrompt,
        addDocument,
        startRun,
        cancelRun,
        updatePrompt,
        updateDocument,
        removePrompt,
        removeDocument,
        applyTemplate
    } = useSheetSocket(sheet)
    return {
        sheet,
        isLoading,
        addPrompt,
        addDocument,
        startRun,
        cancelRun,
        updatePrompt,
        updateDocument,
        removePrompt,
        removeDocument,
        saveSheet: async () => await SheetService.updateSheet(sheet),
        deleteSheet: async () => await SheetService.deleteSheet(sheet),
        exportSheet: (name = null) => SheetService.exportToExcel({ sheet, name }),
        saveAsTemplate: async ({ name = null, template = null }) => await SheetService.saveAsTemplate({ sheet, name, template }),
        applyTemplate
    }
}