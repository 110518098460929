<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { ContextMenuItem, useForwardPropsEmits } from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps({
  disabled: { type: Boolean, required: false },
  textValue: { type: String, required: false },
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  class: { type: null, required: false },
  inset: { type: Boolean, required: false },
});
const emits = defineEmits(["select"]);

const delegatedProps = computed(() => {
  // eslint-disable-next-line no-unused-vars
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <ContextMenuItem v-bind="forwarded" :class="cn(
    'relative flex flex-row cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&_svg]:size-4 [&_svg]:shrink-0 [&_svg]:mr-3',
    inset && 'pl-8',
    props.class,
  )
    ">
    <slot />
  </ContextMenuItem>
</template>
