<script setup>
import { File, FileSpreadsheet, FileUser } from 'lucide-vue-next';
import { SelectFilter } from '@/components/forms';
import { ref, defineExpose, defineModel } from 'vue';

const selectFilter = ref(null)
const selectedType = defineModel({ default: 'all' })
const fileTypes = [
    { value: 'documents', label: 'Documents', icon: File },
    { value: 'sheets', label: 'Sheets', icon: FileSpreadsheet },
    { value: 'threads', label: 'Threads', icon: FileUser },
]

defineExpose({
    displayValue: selectFilter.value?.displayValue
})
</script>

<template>
    <SelectFilter ref="selectFilter" v-model="selectedType" :options="fileTypes" />
</template>