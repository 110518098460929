<script setup>
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { ListFilter } from 'lucide-vue-next'
import { ref, defineModel, watchEffect, computed } from 'vue'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import { Scale, Gavel, Calendar } from 'lucide-vue-next';
import { DateRangeFilter } from  '@/tmp/components/controls';
import { 
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger 
} from '@/components/ui/tooltip'
import { Info } from 'lucide-vue-next'


const sources = defineModel({ type: Object, default: () => { } })
const isOpen = ref(false)
const dateRange = ref({ start: null, end: null })

// Update categories to match areas format
const allAreas = ['Civiel recht', 'Strafrecht', 'Bestuursrecht']
const allCategories = ['Wetgeving', 'Uitspraken', 'Arresten']

// Initialize with all items selected
const areasOfLaw = ref(allAreas)
const categories = ref(allCategories)

const isFiltered = computed(() => {
    return areasOfLaw.value.length != allAreas.length || categories.value.length != allCategories.length || dateRange.value.start != null || dateRange.value.end != null
})

watchEffect(() => {
  sources.value = {
    areas_of_law: areasOfLaw.value.length === allAreas.length ? ['Alles'] : areasOfLaw.value,
    focus: categories.value.length === allCategories.length ? ['Alles'] : categories.value,
    validity_date: {
        start: dateRange.value.start?.toDate() || null,
        end: dateRange.value.end?.toDate() || null
    }
  }
})


</script>

<template>
    <Dialog v-model:open="isOpen" >
        <DialogTrigger asChild>
            <Button variant="ghost" size="icon"
                class="rounded-full hover:bg-background/90 border-none hover:outline-none hover:border-none relative" 
                :class="{ 'bg-background rounded-full shadow-sm': isFiltered || isOpen }">
                <ListFilter />
            </Button>
        </DialogTrigger>

        <DialogContent>
            <DialogHeader class="flex flex-row items-center gap-2">
                <ListFilter />
                <DialogTitle>Sources</DialogTitle>
            </DialogHeader>

            <!-- Add your sources content here -->
            <div class="flex flex-col">
                <div class="flex flex-col w-full space-y-4">
                    <div class="flex flex-col space-y-2">
                        <div class="flex flex-row space-x-2 items-center w-full justify-between">
                            <div class="flex items-center space-x-2">
                                <Scale class="w-4 h-4" />
                                <p class="text-left text-primary text-sm">Areas of law</p>
                            </div>
                            <div class="flex items-center space-x-2">
                                <p class="text-left text-muted-foreground items-center text-xs"> {{ areasOfLaw.length === 3 ? 'All' : areasOfLaw.length }} selected</p>
                                <TooltipProvider :delayDuration="50">
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Info class="w-4 h-4 text-muted-foreground" />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p class="text-sm">Select which areas of law are relevant for your question.</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                            </div>
                        </div>
                        <ToggleGroup 
                            v-model="areasOfLaw"
                            type="multiple" 
                            variant="outline"
                            class="grid grid-cols-1 gap-2">
                            <ToggleGroupItem 
                                key="Civiel recht"
                                value="Civiel recht"
                                aria-label="Toggle civiel recht"
                                class="h-[60px] py-2 rounded-xl">
                                <div class="flex flex-col items-left gap-2 w-full h-full justify-between">
                                    <p class="text-left">Civil law</p>
                                    <p class="text-left text-muted-foreground text-xs">Search through civil law sources</p>
                                </div>
                            </ToggleGroupItem>

                            <ToggleGroupItem 
                                key="Strafrecht"
                                value="Strafrecht"
                                aria-label="Toggle strafrecht"
                                class="h-[60px] py-2 rounded-xl">
                                <div class="flex flex-col items-left gap-2 w-full h-full justify-between">
                                    <p class="text-left">Criminal law</p>
                                    <p class="text-left text-muted-foreground text-xs">Search through criminal law sources</p>
                                </div>
                            </ToggleGroupItem>

                            <ToggleGroupItem 
                                key="Bestuursrecht"
                                value="Bestuursrecht"
                                aria-label="Toggle bestuursrecht"
                                class="h-[60px] py-2 rounded-xl">
                                <div class="flex flex-col items-left gap-2 w-full h-full justify-between">
                                    <p class="text-left">Administrative law</p>
                                    <p class="text-left text-muted-foreground text-xs">Search through administrative law sources</p>
                                </div>
                            </ToggleGroupItem>
                        </ToggleGroup>
                    </div>
                    <div class="flex flex-col space-y-2">
                        <div class="flex flex-row space-x-2 items-center w-full justify-between">
                            <div class="flex items-center space-x-2">
                                <Gavel class="w-4 h-4" />
                                <p class="text-left text-primary text-sm">Source categories</p>
                            </div>
                            <div class="flex items-center space-x-2">
                                <p class="text-left text-muted-foreground items-center text-xs"> {{ categories.length === 3 ? 'All' : categories.length }} selected</p>
                                <TooltipProvider :delayDuration="50">
                                    <Tooltip>
                                        <TooltipTrigger>
                                        <Info class="w-4 h-4 text-muted-foreground" />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p class="text-sm">Select which types of legal sources you want Zeno to search through to find your answer.</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                            </div>

                        </div>
                        <ToggleGroup 
                            v-model="categories"
                            type="multiple" 
                            variant="outline" 
                            class="grid grid-cols-1 gap-2">

                            <ToggleGroupItem 
                                key="Wetgeving"
                                value="Wetgeving"
                                aria-label="Toggle wetgeving"
                                class="h-[60px] py-2 rounded-xl">
                                <div class="flex flex-col items-left gap-2 w-full h-full justify-between">
                                    <p class="text-left">Laws</p>
                                    <p class="text-left text-muted-foreground text-xs">Search through laws and regulations</p>
                                </div>
                            </ToggleGroupItem>

                            <ToggleGroupItem 
                                key="Uitspraken"
                                value="Uitspraken"
                                aria-label="Toggle uitspraken"
                                class="h-[60px] py-2 rounded-xl">
                                <div class="flex flex-col items-left gap-2 w-full h-full justify-between">
                                    <p class="text-left">Cases</p>
                                    <p class="text-left text-muted-foreground text-xs">Search through case law decisions</p>
                                </div>
                            </ToggleGroupItem>

                            <ToggleGroupItem 
                                key="Arresten"
                                value="Arresten"
                                aria-label="Toggle arresten"
                                class="h-[60px] py-2 rounded-xl">
                                <div class="flex flex-col items-left gap-2 w-full h-full justify-between">
                                    <p class="text-left">Supreme Court</p>
                                    <p class="text-left text-muted-foreground text-xs">Search through Supreme Court decisions</p>
                                </div>
                            </ToggleGroupItem>
                        </ToggleGroup>
                    </div>
                    <div class="flex flex-col space-y-3">
                        <div class="flex flex-row space-x-2 items-center w-full justify-between">
                            <div class="flex items-center space-x-2">
                                <Calendar class="w-4 h-4" />
                                <p class="text-left text-primary text-sm">Filter source date</p>
                            </div>
                            <TooltipProvider :delayDuration="50">
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Info class="w-4 h-4 text-muted-foreground" />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p class="text-sm">Select a date range to filter the sources.</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                        <DateRangeFilter v-model="dateRange" label="Anytime" />
                    </div>
                    

                    
                </div>
            </div>
        </DialogContent>
    </Dialog>
</template>