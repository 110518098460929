<script setup>
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { PromptForm } from '@/tmp/components/prompt'
import { computed, defineModel, defineProps, ref } from 'vue'
import { ListCheck, LetterText, Settings2 } from 'lucide-vue-next'

const props = defineProps({
    onSubmit: {
        type: Function,
        required: false
    },
    onDelete: {
        type: Function,
        required: false
    },
    disabled: {
        type: Boolean,
        required: false
    }
})

const prompt = defineModel()
const icon = computed(() => {
    switch (prompt.value.format) {
        case 'text':
            return LetterText
        case 'boolean':
            return ListCheck
        default:
            return null
    }
})

const isOpen = ref(false)

const onSubmit = async (data) => {
    prompt.value = { ...prompt.value, ...data }
    await props.onSubmit?.(prompt.value)
    isOpen.value = false
}

const onDelete = async (data) => {
    await props.onDelete?.(data)
    isOpen.value = false
}
</script>

<template>
    <Popover :modal="true" :open="isOpen">
        <div class="flex items-center justify-between w-full h-full px-2">
            <div class="flex items-center gap-2">
                <component :is="icon" v-if="icon" class="w-4 h-4 flex-shrink-0" />
                <span>{{ prompt.label }}</span>
            </div>
            <PopoverTrigger asChild>
                <Button :disabled="disabled" @click="isOpen = !isOpen" variant="ghost" size="icon" class="h-8 w-8">
                    <Settings2 class="h-4 w-4" />
                </Button>
            </PopoverTrigger>
        </div>
        <PopoverContent class="w-full items-center mx-auto">
            <PromptForm :default-value="prompt" @submit="onSubmit" @cancel="isOpen = false" @delete="onDelete" />
        </PopoverContent>
    </Popover>
</template>
