<script setup>
import { computed, defineProps } from 'vue'
import { ToastTitle } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps({
  class: { type: [String, Object, Array], default: '' }
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  console.log(_)
  return delegated
})
</script>

<template>
  <ToastTitle v-bind="delegatedProps" :class="cn('text-sm font-semibold', props.class)">
    <slot />
  </ToastTitle>
</template>
