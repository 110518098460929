import { createRouter, createWebHistory } from 'vue-router';
import { useAuth } from '@/auth'
import { inject } from 'vue';
import axios from 'axios';
import { Home, Viewer, NotFound, Recents } from '@/tmp/views';
import { Collections, Collection } from '@/tmp/services/collections';
import { Project, Projects } from '@/tmp/services/projects';
import { Results, TypeResults } from '@/tmp/services/search';
import { Explore, TypeExplore } from '@/tmp/services/explore';
import { Library } from '@/tmp/services/library';
import { Thread } from '@/tmp/services/threads';
import { Sheet } from '@/tmp/services/sheets';

const guard = async to => {
  const { authGuard } = useAuth()
  await authGuard(to)
}

const login = (to) => {
  const { loginWithRedirect, organization } = useAuth()
  loginWithRedirect(to.query?.redirectPath || '/', organization.value?.id)
}

const callback = async () => {
  const { user, handleRedirectCallback } = useAuth()
  await handleRedirectCallback()
  const posthog = inject("posthog")
  posthog.identify(user.id, {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name
  });
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: guard
  },
  {
    path: '/activity',
    name: 'activity',
    component: Recents,
    beforeEnter: guard
  },
  {
    path: '/explore',
    beforeEnter: guard,
    children: [
      {
        path: '',
        name: 'explore',
        component: Explore,
      },
      {
        path: 'collections',
        children: [
          {
            path: '',
            name: 'collections',
            component: Collections,
          },
          {
            path: ':collectionId',
            name: 'collection',
            component: Collection,
          }
        ]
      },
      {
        path: ':type',
        children: [
          {
            path: '',
            name: 'type-explore',
            component: TypeExplore,
          },
          {
            path: ':resourceId',
            name: 'type-explore-item',
            component: Viewer,
          }
        ]
      }
    ]
  },
  {
    path: '/projects',
    beforeEnter: guard,
    children: [
      {
        path: '',
        name: 'projects',
        component: Projects,
      },
      {
        path: ':projectId',
        children: [
          {
            path: '',
            name: 'project',
            component: Project,
          },
          {
            path: 'activity',
            name: 'project-activity',
            component: Recents,
          },
          {
            path: 'threads',
            children: [
              {
                path: ':threadId',
                name: 'project-thread',
                component: Thread,
              }
            ]
          },
          {
            path: 'sheets',
            children: [
              {
                path: ':sheetId',
                name: 'project-sheet',
                component: Sheet,
              }
            ]
          },
          {
            path: 'documents',
            children: [
              {
                path: '',
                name: 'project-documents',
                query: {
                  folderId: {
                    type: String || null,
                    required: false,
                    default: null
                  }
                },
                component: Library,
              },
              {
                path: ':resourceId',
                name: 'project-document',
                component: Viewer,
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/results',
    name: 'results',
    component: Results,
    query: {
      q: {
        type: String,
        required: false,
        default: ''
      },
      sourceFilters: {
        type: Array,
        required: false,
        default: ['Alles']
      },
      areaFilters: {
        type: Array,
        required: false,
        default: ['Alles']
      },
      startDate: {
        type: Date,
        required: false,
        default: null
      },
      endDate: {
        type: Date,
        required: false,
        default: null
      }
    },
    beforeEnter: guard
  },
  {
    path: '/results/:type',
    name: 'type-results',
    component: TypeResults,
    query: {
      q: {
        type: String,
        required: false,
        default: ''
      },
      sourceFilters: {
        type: Array,
        required: false,
        default: ['Alles']
      },
      areaFilters: {
        type: Array,
        required: false,
        default: ['Alles']
      },
      startDate: {
        type: Date,
        required: false,
        default: null
      },
      endDate: {
        type: Date,
        required: false,
        default: null
      }
    },
    beforeEnter: guard
  },
  {
    path: '/documents',
    beforeEnter: guard,
    children: [
      {
        path: '',
        name: 'documents',
        query: {
          folderId: {
            type: String,
            required: false,
            default: null
          }
        },
        component: Library,
      },
      {
        path: ':resourceId',
        name: 'document',
        component: Viewer,
      }
    ]
  },
  {
    path: '/threads',
    beforeEnter: guard,
    children: [
      {
        path: ':threadId',
        name: 'thread',
        component: Thread,
      }
    ]
  },
  {
    path: '/sheets',
    beforeEnter: guard,
    children: [
      {
        path: ':sheetId',
        name: 'sheet',
        component: Sheet,
      }
    ]
  },
  { path: '/:catchAll(.*)', component: NotFound },
  { path: '/auth/login', redirect: login },
  { path: '/auth/callback',
    name: 'callback',
    beforeEnter: async (next) => {
      await callback();
      next('/');
    }
  },
  {
    name: 'checkout', path: '/orders/checkout', beforeEnter: async (to, from, next) => {
      try {
        const { useAuth } = require('@/auth');
        const { getAccessToken } = useAuth()
        const url = new URL(`${process.env.VUE_APP_API_URL}/orders/checkout`)
        const token = await getAccessToken();
        const response = await axios.post(url.toString(), to.query, { headers: { Authorization: `Bearer ${token}` } })
        if (response.data && response.data?.id === undefined) window.location.href = response.data;
        next('/');
      } catch (error) {
        console.error(error)
        next('/');
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

export default router;
