<script setup>
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import PreviewCard from './PreviewCard.vue';
import { CaseIcon, RegulationIcon } from '@/components/icons';
import { defineProps, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { useSidepanel } from '@/tmp/components/sidepanel';

const sidepanel = useSidepanel();

const router = useRouter();

const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    type: {
        type: String,
        required: true
    },
    isLoading: {
        type: Boolean,
        required: false,
        default: false
    },
    cols: {
        type: Number,
        required: false,
        default: 4
    },
    size: {
        type: String,
        required: false,
        default: 'sm'
    },
    numResults: {
        type: Number,
        required: false,
        default: 4
    }
})

const emit = defineEmits(['showMore'])

const navigateTo = (item) => {
    router.push({ name: 'type-explore-item', params: { type: props.type, resourceId: item.id } })
}
</script>

<template>
    <div class="w-full flex flex-col gap-1">
        <div v-if="$slots.default" class="w-full flex justify-between items-center px-1">
            <div class="w-full flex justify-start items-center gap-2">
                <slot />
            </div>
            <Button variant="link" size="xs" class="text-muted-foreground" @click="emit('showMore', type)">Show
                more</Button>
        </div>
        <div class="w-full gap-2 grid" :class="`grid-cols-${cols}`">
            <template v-if="isLoading || !items.length">
                <Skeleton v-for="i in numResults" :key="i" :class="{ 'h-32': size === 'sm', 'h-64': size === 'lg' }" />
            </template>
            <template v-else>
                <PreviewCard v-for="item in items" :key="item.id" v-bind="item.preview"
                    :line-clamp="size === 'sm' ? 2 : 5" :size="size" @bookmark="console.log(item)"
                    @open-default="sidepanel.addTab(item.display)" @open-split="sidepanel.addTab(item.display)"
                    @open-full="navigateTo(item)">
                    <template v-if="size !== 'sm' && type === 'regulations'" #source>
                        <RegulationIcon />
                        <span class="text-xs font-medium">Wetten.nl</span>
                    </template>
                    <template v-else-if="size !== 'sm' && type === 'cases'" #source>
                        <CaseIcon />
                        <span class="text-xs font-medium">Rechtspraak.nl</span>
                    </template>
                </PreviewCard>
            </template>
        </div>
    </div>
</template>