import { useRepo, Folder, Document } from '@/models';
import { ref, toValue } from 'vue';
import { toast } from 'vue-sonner';


export function useUpload(projectId, folderId) {
    const folderRepo = useRepo(Folder);
    const documentRepo = useRepo(Document);

    const files = ref([]);
    const isUploading = ref(false);

    async function readEntry(entry, path = '') {
        // Ignore dot files and directories
        if (entry.name.startsWith('.')) {
            return;
        }

        if (entry.isFile) {
            return new Promise((resolve) => {
                entry.file(async (file) => {
                    const fileWithPath = new File([file], file.name, {
                        type: file.type,
                        lastModified: file.lastModified
                    });
                    fileWithPath._path = path + file.name;
                    files.value.push(fileWithPath);
                    resolve();
                });
            });
        } else if (entry.isDirectory) {
            const dirReader = entry.createReader();
            const entries = [];
            
            let batch;
            do {
                batch = await new Promise((resolve, reject) => {
                    dirReader.readEntries(
                        (results) => resolve(results),
                        (error) => reject(error)
                    );
                });
                entries.push(...batch);
            } while (batch.length > 0);

            const folderPath = path + entry.name + '/';
            await Promise.all(
                entries.map((entry) => readEntry(entry, folderPath))
            );
        }
    }

    async function processItems(items) {
        const promises = [];
        for (const item of items) {
            if (item instanceof File) {
                // Ignore dot files
                if (!item.name.startsWith('.')) {
                    files.value.push(item);
                }
            } else {
                const entry = item.webkitGetAsEntry?.() || 
                             item.getAsEntry?.() || 
                             item;
                
                if (entry && !entry.name?.startsWith('.')) {
                    promises.push(readEntry(entry));
                }
            }
        }
        await Promise.all(promises);
    }

    function readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const upload = async (items) => {
        try {
            files.value = [];
            await processItems(items);

            if (files.value.length === 0) return;

            isUploading.value = true;
            toast.info(`Uploading ${files.value.length} files`);

            // Add logging to see the file structure
            console.log('Files to upload:', files.value.map(f => f._path || f.webkitRelativePath));

            // Group files by their directory depth and path
            const filesByLevel = files.value.reduce((acc, file) => {
                const pathParts = (file?._path || file.webkitRelativePath).split('/');
                pathParts.pop(); // Remove filename
                const depth = pathParts.length;
                const path = pathParts.join('/');
                
                // Add logging
                console.log('Processing file:', {
                    file: file.name,
                    path,
                    depth
                });
                
                acc[depth] = acc[depth] || {};
                acc[depth][path] = acc[depth][path] || [];
                acc[depth][path].push(file);
                return acc;
            }, {});

            // Track created folders to avoid duplicates
            const folderCache = new Map();
            folderCache.set('', toValue(folderId));

            console.log('Starting folder creation by level:', filesByLevel);

            // Process each level, starting from root (depth 0)
            const maxDepth = Math.max(...Object.keys(filesByLevel).map(Number));
            for (let depth = 0; depth <= maxDepth; depth++) {
                const levelFiles = filesByLevel[depth] || {};
                console.log(`Processing depth ${depth}:`, levelFiles);

                // Process all paths at current depth
                for (const [path, files] of Object.entries(levelFiles)) {
                    // Get or create folder chain
                    let currentParentId = toValue(folderId);
                    if (path) {
                        const pathParts = path.split('/');
                        for (let i = 0; i < pathParts.length; i++) {
                            const partialPath = pathParts.slice(0, i + 1).join('/');
                            const parentPath = pathParts.slice(0, i).join('/');
                            
                            if (!folderCache.has(partialPath)) {
                                console.log('Creating folder:', {
                                    name: pathParts[i],
                                    parentPath,
                                    parentId: folderCache.get(parentPath || '')
                                });
                                
                                const response = await folderRepo.api().post('/folders/', {
                                    name: pathParts[i],
                                    project_id: toValue(projectId),
                                    parent_id: folderCache.get(parentPath || '')
                                });
                                folderCache.set(partialPath, response.entities[0].id);
                            }
                            currentParentId = folderCache.get(partialPath);
                        }
                    }

                    // Upload all files in current path
                    console.log(`Uploading files for path "${path}" to folder ID:`, currentParentId);
                    const promises = files.map(async (file) => 
                        documentRepo.api().post('/documents/', {
                            title: file.name,
                            project_id: toValue(projectId),
                            folder_id: currentParentId,
                            content: await readFileAsBase64(file),
                            content_type: file.name.split('.').pop()
                        })
                    );
                    await Promise.all(promises);
                }
            }
        } catch (error) {
            console.error('Upload error:', error);
            toast.error('Failed to upload files');
            return;
        }
        
        toast.success('Files uploaded successfully');
        isUploading.value = false;
        files.value = [];
    }

    const handleUpload = async (event) => {
        await upload(event.dataTransfer ? event.dataTransfer.items : event.target.files);
    };

    return { upload, handleUpload, isUploading };
}