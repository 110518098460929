<script setup>
import { useVModel } from "@vueuse/core";
import { cn } from "@/lib/utils";
import { defineProps, defineEmits, ref, defineExpose } from "vue";

const inputRef = ref(null);

const props = defineProps({
  defaultValue: { type: [String, Number], required: false },
  modelValue: { type: [String, Number], required: false },
  class: { type: null, required: false },
});

const emits = defineEmits(["update:modelValue"]);

const modelValue = useVModel(props, "modelValue", emits, {
  passive: true,
  defaultValue: props.defaultValue,
});

// Expose the input ref so parent components can access it
defineExpose({
  focus: () => inputRef.value?.focus(),
});
</script>

<template>
  <input ref="inputRef" v-model="modelValue" :class="cn(
    'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 caret-secondary',
    props.class
  )
    " />
</template>
