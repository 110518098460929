<script setup>
import { defineProps, defineModel, onMounted, watch } from 'vue'
import { ChevronRight, ChevronDown } from 'lucide-vue-next'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { Button } from '@/components/ui/button'
import Shortcuts from './Shortcuts.vue'

const selectedShortcut = defineModel('shortcut', { type: Object, default: null })
const isOpen = defineModel('isOpen', { type: Boolean, default: false })

const props = defineProps({
    types: { type: Array, default: () => ['prompts', 'sheets', 'other'] },
    defaultOpen: { type: Boolean, default: false },
    alwaysOpen: { type: Boolean, default: false },
    defaultShortcut: { type: Object, default: null }
})

onMounted(() => {
    if (props.defaultShortcut) {
        selectedShortcut.value = props.defaultShortcut
    }
    isOpen.value = props.alwaysOpen || props.defaultOpen || selectedShortcut.value !== null
})

watch(isOpen, (newIsOpen) => {
    if (!newIsOpen) {
        selectedShortcut.value = null
    }
})
</script>

<template>
    <Collapsible v-model:open="isOpen">
        <CollapsibleTrigger v-if="!alwaysOpen" as-child>
            <Button variant="link" class="justify-end w-full text-muted-foreground items-center">
                <span>Shortcuts</span>
                <ChevronRight v-if="!isOpen" class="size-3.5" />
                <ChevronDown v-else class="size-3.5" />
            </Button>
        </CollapsibleTrigger>
        <CollapsibleContent class="flex-1 overflow-y-auto bg-background">
            <Shortcuts v-model:shortcut="selectedShortcut" :types="types" />
        </CollapsibleContent>
    </Collapsible>
</template>