import { Folder, Project, Thread, Sheet, Document, useRepo } from '@/models';
import { api } from '@/plugins/api';
import { useUpload } from '@/tmp/composables';
import { toValue } from 'vue';
function setup() {
    const repoMap = {
        'project': useRepo(Project),
        'folder': useRepo(Folder),
        'thread': useRepo(Thread),
        'sheet': useRepo(Sheet),
        'document': useRepo(Document)
    }

    function getType(item) {
        if (item instanceof Folder) return 'folder';
        if (item instanceof Thread) return 'thread';
        if (item instanceof Sheet) return 'sheet';
        if (item instanceof Document) return 'document';
        if (item instanceof Project) return 'project';
        return 'folder';
    }

    return { getType, repoMap }
}

export async function move(target, items) {
    const { repoMap, getType } = setup()

    if (target !== null && !(target instanceof Folder || target instanceof Project)) {
        target = target.parent;
    }

    if (target === undefined || !items || items.includes(target)) return;

    const promises = [];
    let payload = { destination_folder_id: target?.id || null };
    if (target instanceof Project) {
        payload = { destination_project_id: target?.id || null };
    }
    if (target === null) {
        payload = { destination_folder_id: null, destination_project_id: null };
    }
    for (const item of items) {
        const endpoint = `/actions/${item.entity || getType(item)}s/${item.id}/move`;
        promises.push(api.post(endpoint, payload));
    }
    const results = await Promise.all(promises);
    results.forEach((result, index) => {
        const item = items[index];
        repoMap[item.entity || getType(item)].save(result.data);
    });
}

export async function handleDrop(item, event) {
    if (event.dataTransfer.files.length > 0) {
        const { upload } = useUpload(
            item instanceof Project ? item.id : item?.project_id || null,
            item instanceof Folder ? item.id : item?.folder_id || null
        );
        await upload(event.dataTransfer.items);
    } else {
        await move(item, JSON.parse(event.dataTransfer.getData('text/plain')))
    }
}

export async function getChildren(folder) {
    const { repoMap } = setup()
    const response = await api.get(`/actions/folders/${toValue(folder).id}/children`)
    for (const [type, items] of Object.entries(response.data)) {
        repoMap[type.slice(0, -1)].save(items)
    }
    return useRepo(Folder).withAllRecursive().find(toValue(folder).id).children
}

export const LibraryService = {
    move,
    handleDrop,
    getChildren
}