import { computed, toValue } from 'vue'

export function useFilterable(items, filters = {}) {
    const filteredItems = computed(() => {
        if (!toValue(items)?.length) return []
        
        return toValue(items).filter(item => {
            // If no filters defined, return all items
            if (!Object.keys(filters).length) return true
            
            // Check if item passes all active filters
            return Object.entries(filters).every(([key, filterFn]) => {
                // Skip inactive filters
                if (!filterFn) return true
                
                try {
                    return filterFn(item)
                } catch (error) {
                    console.error(`Error in ${key} filter:`, error)
                    return true
                }
            })
        })
    })

    return {
        filteredItems
    }
}
