
import { ref, onMounted } from 'vue';
import { Organization, User, Membership } from '@/models';
import { useAuth } from '@/auth';
import { storeToRefs } from 'pinia';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';

export function useData() {
    const isLoading = ref(true)
    const organizationRepo = useAxiosRepo(Organization).setAxios(api)
    const userRepo = useAxiosRepo(User).setAxios(api)
    const membershipRepo = useAxiosRepo(Membership).setAxios(api)
    onMounted(async () => {
        const auth = useAuth();
        const { isAuthenticated } = storeToRefs(auth);
        if (!isAuthenticated.value) {
            await new Promise(resolve => {
                const checkAuthentication = () => {
                    if (isAuthenticated.value) {
                        resolve();
                    } else {
                        setTimeout(checkAuthentication, 100);
                    }
                };
                checkAuthentication();
            });
        }
        const promises = [
            organizationRepo.api().get('/organizations/'),
            membershipRepo.api().get('/organizations/memberships'),
            userRepo.api().get('/users/')
        ]
        await Promise.all(promises)
        isLoading.value = false;
    })

    return {
        isLoading
    }
}