<script setup>
import { defineProps, toRefs } from 'vue'

const props = defineProps(['run'])
const { run } = toRefs(props)
</script>

<template>
    <div class="rounded-xl px-4 py-2 w-fit max-w-[75%] ml-auto text-left bg-accent items-start">
        <p class="prose" :style="{ 'font-family': 'Inter, sans-serif', 'font-size': '0.98rem !important' }">
            {{ run.query }}
        </p>
    </div>
</template>