<script setup>
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { useSidebar, SidebarMenuButton } from '@/components/ui/sidebar';
import { Plus, Text, Upload, Grid2x2 } from 'lucide-vue-next';
import { useRouter, useRoute } from 'vue-router';
import { useRepo, Thread, Sheet } from '@/models';
import { UploadButton } from '@/tmp/services/library/components/';

const router = useRouter()
const route = useRoute()

const { open } = useSidebar()

const threadRepo = useRepo(Thread)
const sheetRepo = useRepo(Sheet)

async function createThread() {
    const response = await threadRepo.api().post('/threads/', {
        title: 'New Thread',
        project_id: route.params?.projectId || null,
        folder_id: route.query?.folderId || null
    })
    router.push({ name: response.entities[0].project_id ? 'project-thread' : 'thread', params: { threadId: response.entities[0].id, projectId: response.entities[0].project_id } })
}

async function createSheet() {
    const response = await sheetRepo.api().post('/sheets/', {
        title: 'New Sheet',
        project_id: route.params?.projectId || null,
        folder_id: route.query?.folderId || null
    })
    router.push({ name: response.entities[0].project_id ? 'project-sheet' : 'sheet', params: { sheetId: response.entities[0].id, projectId: response.entities[0].project_id } })
}
</script>

<template>
    <DropdownMenu>
        <DropdownMenuTrigger asChild>
            <SidebarMenuButton tooltip="New" variant="outline" size="xl"
                class="rounded-full border-border w-fit items-center justify-center bg-primary text-primary-foreground pr-3 hover:bg-primary/90 hover:text-primary-foreground hover:scale-105 transition-all duration-300 shadow-md">
                <Plus :stroke-width="2.5" :class="{ 'ml-[0.45rem]': !open }" />
                <span class="font-semibold">New</span>
            </SidebarMenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="right" align="start" class="w-fit bg-sidebar-accent rounded-xl">
            <DropdownMenuItem class="flex flex-row items-center rounded-xl cursor-pointer" @click="createThread">
                <Text class="mr-2 h-5 w-5 text-yellow-500" />
                <span class="text-lg">Thread</span>
            </DropdownMenuItem>
            <DropdownMenuItem class="flex flex-row items-center rounded-xl cursor-pointer" @click="createSheet">
                <Grid2x2 class="mr-2 h-5 w-5 text-green-500" />
                <span class="text-lg">Sheet</span>
            </DropdownMenuItem>
            <UploadButton :project-id="route.params?.projectId" :folder-id="route.query?.folderId">
                <DropdownMenuItem class="flex flex-row items-center rounded-xl cursor-pointer w-full">
                    <Upload class="mr-2 w-5 h-5" />
                    <span class="text-lg">Upload</span>
                </DropdownMenuItem>
            </UploadButton>
        </DropdownMenuContent>
    </DropdownMenu>
</template>