<script setup>
import { computed } from 'vue'
import { MainLayout, NavbarLayout, HeaderLayout } from '@/layouts'
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage, BreadcrumbLink, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { Plus, Shapes, Compass, ChevronDown } from 'lucide-vue-next'
import { useRoute } from 'vue-router'
import { Button } from '@/components/ui/button'
import { CollectionBoard, useCollection } from '..'
import { ResourceItem, ResourceIcon, ResourceName, ResourceDescription } from '@/tmp/components/resources'
import { Loading } from '@/components'
import { TemplateDialog } from '@/tmp/services/templates'
const route = useRoute()
const collectionId = computed(() => route.params.collectionId)
const { collection, isLoading, saveCollection } = useCollection(collectionId)
</script>

<template>
    <Loading v-if="isLoading" />
    <MainLayout v-else>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbLink>
                                    <router-link class="flex flex-row space-x-2 items-center" :to="{ name: 'explore' }">
                                        <Compass />
                                        <span>Explore</span>
                                    </router-link>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbLink>
                                    <router-link class="flex flex-row space-x-2 items-center"
                                        :to="{ name: 'collections' }">
                                        <Shapes />
                                        <span>Collections</span>
                                    </router-link>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <ResourceItem :item="collection" action-menu="dropdown">
                                    <BreadcrumbPage class="flex flex-row space-x-2 items-center cursor-pointer">
                                        <ResourceIcon />
                                        <ResourceName />
                                        <ChevronDown class="size-4 shrink-0 text-muted-foreground" />
                                    </BreadcrumbPage>
                                </ResourceItem>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #header>
            <HeaderLayout>
                <template #description>
                    <ResourceDescription class="pl-0.5" v-model="collection.description" @save="saveCollection" />
                </template>
                <template #left-actions>
                    <TemplateDialog>
                        <Button size="sm" variant="default" class="flex flex-row space-x-2 items-center rounded-3xl">
                            <Plus class="w-4 h-4 flex-shrink-0" :stroke-width="2.5" />
                            <span>New Collection</span>
                        </Button>
                    </TemplateDialog>
                </template>
            </HeaderLayout>
        </template>
        <template #content>
            <CollectionBoard :collection-id="collectionId" />
        </template>
    </MainLayout>
</template>