<script setup>
import { cn } from "@/lib/utils";
import { defineProps } from 'vue';

const props = defineProps({
  class: { type: null, required: false },
});
</script>

<template>
  <span role="link" aria-disabled="true" aria-current="page" :class="cn('font-normal text-primary', props.class)">
    <slot />
  </span>
</template>
