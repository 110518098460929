<script setup>
import { defineModel, computed, defineProps } from 'vue';
import { PencilIcon, TrashIcon, Share2, CheckIcon, XIcon, ArrowLeft, CopyIcon, SaveAllIcon } from 'lucide-vue-next';
import { EditableArea, EditableCancelTrigger, EditableEditTrigger, EditableInput, EditablePreview, EditableRoot, EditableSubmitTrigger } from 'radix-vue'
import { Button } from '@/components/ui/button';
import { useTemplate } from '../composables';
import { DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '@/components/ui/dialog';
import { TemplateService } from '../service';
import { SheetTemplateForm } from '@/tmp/services/sheets';
import { PromptTemplateForm } from '@/tmp/services/threads';
import { useAuth } from '@/auth'

const { organization } = useAuth()
const selectedTemplate = defineModel();
defineProps({
    onSaveAsTemplate: {
        type: Function,
        required: false
    }
})

const type = computed(() => TemplateService.getType(selectedTemplate.value));
const templateId = computed(() => selectedTemplate.value.id);

const {
    template,
    saveTemplate,
    deleteTemplate
} = useTemplate(type, templateId);

async function duplicateTemplate() {
    const newTemplate = await TemplateService.createTemplate({
        type: type.value,
        ...template.value,
        name: template.value.name + ' (copy)'
    });
    selectedTemplate.value = newTemplate;
}
</script>

<template>

    <DialogHeader>
        <DialogTitle>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <button class="text-sm text-primary" @click="selectedTemplate = null">
                        <ArrowLeft class="mr-2 size-5" />
                    </button>
                    <EditableRoot :disabled="template.default && template.organization_id !== organization.id"
                        v-slot="{ isEditing }" v-model="template.name" @submit="saveTemplate"
                        class="flex items-center space-x-2" submit-mode="both" auto-resize
                        :activation-mode="template.default && template.organization_id !== organization.id ? 'none' : 'focus'">
                        <EditableArea class="items-center">
                            <EditablePreview class=" text-md font-bold" />
                            <EditableInput class="w-full text-md font-bold" />
                        </EditableArea>
                        <template v-if="!(template.default && template.organization_id !== organization.id)">
                            <EditableEditTrigger v-if="!isEditing" as-child>
                                <PencilIcon class="size-4 hidden cursor-pointer items-center" />
                            </EditableEditTrigger>
                            <div v-else class="flex flex-row space-x-2 items-center ">
                                <EditableSubmitTrigger as-child>
                                    <CheckIcon class="size-4 cursor-pointer hover:text-secondary" />
                                </EditableSubmitTrigger>
                                <EditableCancelTrigger as-child>
                                    <XIcon class="size-4 cursor-pointer hover:text-muted-foreground" />
                                </EditableCancelTrigger>
                            </div>
                        </template>
                    </EditableRoot>
                </div>
            </div>
        </DialogTitle>
        <DialogDescription>
            <EditableRoot :disabled="template.default && template.organization_id !== organization.id"
                v-model="template.description" @submit="saveTemplate"
                placeholder="Describe the function of this template to increase usability."
                class="group relative flex flex-col" submit-mode="both" auto-resize
                :activation-mode="template.default && template.organization_id !== organization.id ? 'none' : 'focus'">
                <div class="flex items-start gap-2">
                    <div v-if="!(template.default && template.organization_id !== organization.id)"
                        class="flex flex-row gap-2 pt-1 items-center">
                        <EditableEditTrigger v-if="!isEditing">
                            <PencilIcon class="size-3 group-hover:opacity-100 transition-opacity cursor-pointer" />
                        </EditableEditTrigger>
                        <template v-else>
                            <EditableSubmitTrigger>
                                <CheckIcon class="size-4 cursor-pointer hover:text-secondary" />
                            </EditableSubmitTrigger>
                            <EditableCancelTrigger>
                                <XIcon class="size-4 cursor-pointer hover:text-muted-foreground" />
                            </EditableCancelTrigger>
                        </template>
                    </div>
                    <EditableArea class="flex-1 w-full h-full">
                        <EditablePreview
                            class="flex flex-col w-full h-full text-sm text-muted-foreground whitespace-pre-wrap break-words">
                        </EditablePreview>
                        <EditableInput class="w-full text-xs text-muted-foreground min-h-full break-all"
                            as="textarea" />
                    </EditableArea>
                </div>
            </EditableRoot>
        </DialogDescription>
    </DialogHeader>
    <SheetTemplateForm v-if="type === 'sheets'" v-model="template" @save="saveTemplate" />
    <PromptTemplateForm v-else-if="type === 'prompts'" v-model="template" @save="saveTemplate" />
    <DialogFooter class="flex flex-row justify-between w-full items-center pt-4 mt-auto border-t">
        <div class="flex flex-row items-center space-x-2">
            <Button v-if="!template.default" @click="deleteTemplate(); selectedTemplate = null" variant="outline"
                size="sm">
                <TrashIcon class="size-3 mr-2" />
                <span class="text-sm">Delete</span>
            </Button>
            <Button v-if="onSaveAsTemplate" @click="onSaveAsTemplate({ template })" variant="outline" size="sm">
                <SaveAllIcon class="size-3 mr-2" />
                <span class="text-sm">Update from current sheet</span>
            </Button>
            <Button @click="duplicateTemplate" variant="outline" size="sm">
                <CopyIcon class="size-3 mr-2" />
                <span class="text-sm">Duplicate</span>
            </Button>
            <Button v-if="false" variant="outline" size="sm">
                <Share2 class="size-3 mr-1" />
                <span class="text-sm">Share</span>
            </Button>
        </div>
        <Button @click="$emit('apply', template)" variant="default" size="sm">
            <span class="text-sm">Use Template</span>
        </Button>
    </DialogFooter>
</template>
