<script setup>
import { defineProps, defineEmits, ref, defineExpose } from 'vue'
import { FileUp } from 'lucide-vue-next'

const emit = defineEmits(['drop'])
defineProps({
    text: { type: String, default: 'Drop to add documents' }
})

// Add new refs for drag and drop state
const isDraggingOver = ref(false)
const draggedItemType = ref(null)
const isInternalDrag = ref(false)
const dropzone = ref(null)

// Add debounce timer ref
const dragDebounceTimer = ref(null)
const previousStateChange = ref(false)

// Add debounced state change function
const setDraggingState = (value) => {
    if (previousStateChange.value !== value) {
        clearTimeout(dragDebounceTimer.value)
        previousStateChange.value = value
        dragDebounceTimer.value = setTimeout(() => {
            isDraggingOver.value = value
            if (!value) draggedItemType.value = null
        }, 1) // 50ms debounce delay
    }
}

// Add handlers for drag and drop
const handleDragEnter = (event) => {
    event.preventDefault()

    // Ignore if this is an internal drag operation
    if (isInternalDrag.value) return

    // Check if this is likely an external document drag
    const hasTextPlainType = Array.from(event.dataTransfer?.types || []).includes('text/plain')

    if (hasTextPlainType) {
        setDraggingState(true)
        draggedItemType.value = 'unknown'
    }
}

const handleDragOver = (event) => handleDragEnter(event)

const handleDragLeave = (event) => {
    event.preventDefault()

    // Only hide overlay if we're leaving the container
    // Check if the related target is outside the container or null
    const related = event.relatedTarget
    if (!related || (!event.currentTarget.contains(related) && related !== event.currentTarget)) {
        setDraggingState(false)
    }
}

const handleDrop = async (event) => {
    event.preventDefault()
    if (isInternalDrag.value) return
    clearTimeout(dragDebounceTimer.value) // Clear any pending debounce
    isDraggingOver.value = false
    draggedItemType.value = null

    const droppedData = JSON.parse(event.dataTransfer?.getData('text/plain'))

    // Process folders recursively to extract all documents
    const processFolder = (folder) => {
        const folderDocs = []
        for (const child of folder.children) {
            if (!child?.children) {
                folderDocs.push(child)
            } else {
                folderDocs.push(...processFolder(child))
            }
        }
        return folderDocs
    }

    // Get documents from folders
    let documents
    if (droppedData.sourceType === 'folder') {
        documents = droppedData.items.flatMap(item => processFolder(item))
    } else {
        documents = droppedData.items
    }

    emit('drop', documents)
}

defineExpose({
    isDraggingOver,
    handleDragEnter,
    handleDragOver,
    handleDragLeave,
    handleDrop
})
</script>

<template>
    <div ref="dropzone" :class="[$attrs.class, 'relative']" @dragenter="handleDragEnter" @dragleave="handleDragLeave"
        @dragover="handleDragOver" @drop="handleDrop">

        <!-- Drag overlay -->
        <Transition enter-active-class="transition-opacity duration-300"
            leave-active-class="transition-opacity duration-300" enter-from-class="opacity-0"
            leave-to-class="opacity-0">
            <div v-if="isDraggingOver"
                class="absolute inset-0 bg-background/10 rounded-3xl backdrop-blur-sm z-50 flex items-center justify-center pointer-events-none">
                <div
                    class="w-[99.9%] h-[99.8%] flex items-center justify-center text-xl font-medium text-primary border-2 rounded-3xl border-dotted border-primary/10">
                    <FileUp class="w-6 h-6 mr-2" />
                    <span>{{ text }}</span>
                </div>
            </div>
        </Transition>
        <slot />
    </div>
</template>

<style scoped>
.backdrop-blur-sm {
    backdrop-filter: blur(8px);
}
</style>