<script setup>
import { Button } from '@/components/ui/button'
import { LayoutTemplate, WrapText, AlignJustify, Download, Plus, FileUp, Loader2 } from 'lucide-vue-next'
import { Cell, PromptHeader, DocumentHeader, DocumentCell, NewPromptHeader, AddDocumentsFooter, RowOverview } from '../components'
import { useSheet } from '../composables'
import { ref, h, computed, watch, provide, toValue } from 'vue'
import { valueUpdater } from '@/lib/utils'
import { MainLayout, HeaderLayout, NavbarLayout } from '@/layouts'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import {
    FlexRender,
    getCoreRowModel,
    useVueTable
} from '@tanstack/vue-table'
import {
    Table,
    TableHeader,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    TableCell,
} from '@/components/ui/table'
import {
    Progress,
} from '@/components/ui/progress'
import { Loading } from '@/components'
import { ResourceBreadcrumb } from '@/tmp/components/resources'
import { LibraryTableDialog } from '@/tmp/services/library'
import { Document, Folder, useRepo } from '@/models'
import { TemplateDialog } from '@/tmp/services/templates'
import { useRoute } from 'vue-router'
import { LibraryService } from '@/tmp/services/library'
import { toast } from 'vue-sonner'

const route = useRoute()
const sheetId = computed(() => route.params?.sheetId)

//const sheetIcon = ref('SheetIcon')
const isTextWrapped = ref(false)
const {
    sheet,
    addDocument,
    removeDocument,
    updatePrompt,
    removePrompt,
    isLoading,
    addPrompt,
    updateDocument,
    exportSheet,
    applyTemplate,
    saveAsTemplate
} = useSheet(sheetId)

const isDocumentDialogOpen = ref(false)
const isRowOverviewOpen = ref(false)
const selectedRow = ref(null)


const flattenDocuments = async (items) => {
    const flattened = []
    for (const item of items) {
        if (item.entity === 'document' || item instanceof Document) {
            flattened.push(item)
        } else if (item.entity === 'folder' || item instanceof Folder) {
            const folder = useRepo(Folder).find(item.id)
            const children = await LibraryService.getChildren(folder)
            flattened.push(...await flattenDocuments(children))
        }
    }
    return flattened
}

const confirmSelection = async (selectedItems) => {
    try {
        toast.info('Adding documents to sheet...');

        const flattenedDocuments = await flattenDocuments(selectedItems)
        const newDocuments = [];
        let removedCount = 0;

        // Add new documents
        for (const item of flattenedDocuments) {
            if (!toValue(sheet)?.documents.find(d => d.id === item.id)) {
                await addDocument({ documentId: item.id, index: toValue(sheet)?.documents.length });
                newDocuments.push(item);
            }
        }

        // Remove documents not in selection
        const removedDocuments = toValue(sheet)?.documents.filter(d => !flattenedDocuments.find(item => item.id === d.id));
        for (const document of removedDocuments) {
            await removeDocument({ documentId: document.id });
            removedCount++;
        }

        // Show appropriate success message
        if (newDocuments.length > 0 || removedCount > 0) {
            const addedMsg = newDocuments.length > 0 ? `added ${newDocuments.length} document${newDocuments.length === 1 ? '' : 's'}` : '';
            const removedMsg = removedCount > 0 ? `removed ${removedCount} document${removedCount === 1 ? '' : 's'}` : '';
            const msg = [addedMsg, removedMsg].filter(Boolean).join(' and ');
            toast.success(`Successfully ${msg}`);
        } else {
            toast.info('No changes made to sheet');
        }
    } catch (error) {
        console.error(error);
        toast.error('Failed to update sheet documents');
    }
}

const rowSelection = ref({})
const columnOrder = ref([])
const draggedColumn = ref(null)
const dragTarget = ref(null)
const originalOrder = ref(null)

const columns = computed(() => [
    {
        id: 'document',
        header: () => h(DocumentHeader),
        accessorFn: row => row.document,
        cell: info => h(DocumentCell, {
            modelValue: info.getValue()
        }),
        footer: () => h(AddDocumentsFooter, {
            onClick: () => isDocumentDialogOpen.value = true,
            disabled: toValue(sheet)?.isRunning
        }),
    },
    ...(toValue(sheet)?.prompts?.map(prompt => ({
        id: prompt.id,
        accessorKey: prompt.id,
        header: () => h(PromptHeader, {
            modelValue: prompt,
            onSubmit: async (data) => await updatePrompt({ prompt: data }),
            onDelete: async (promptId) => await removePrompt({ promptId }),
            disabled: toValue(sheet)?.isRunning
        }),
        cell: info => h(Cell, {
            cell: info.getValue(),
            isTextWrapped: isTextWrapped.value
        }),
    })) || []),
    {
        id: 'new',
        header: () => h(NewPromptHeader, {
            onSubmit: async (data) => await addPrompt({ prompt: data }),
            disabled: toValue(sheet)?.isRunning
        }),
    }
])



watch(columns, (newColumns) => {
    if (!draggedColumn.value) {
        if (!originalOrder.value) columnOrder.value = newColumns.map(col => col.id)
    }
}, { immediate: true })


const data = computed(() =>
    toValue(sheet)?.documents?.map(document => ({
        document,
        ...Object.fromEntries(toValue(sheet)?.prompts?.map(prompt => [
            prompt.id,
            toValue(sheet)?.cell(prompt, document)
        ]))
    }))
)

const isLastColumn = (column) => {
    const columnId = column?.column?.id || column?.id;
    const headers = table.getHeaderGroups()[0].headers;
    return headers[headers.length - 1].id === columnId;
}

const table = useVueTable({
    get data() {
        return data.value
    },
    get columns() {
        return columns.value
    },
    state: {
        get columnOrder() {
            return columnOrder.value
        },
        rowSelection: rowSelection.value
    },
    onColumnOrderChange: updater => {
        columnOrder.value = updater(columnOrder.value)
    },
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: updaterOrValue => valueUpdater(updaterOrValue, rowSelection),
})

const handleDragStart = (column) => {
    isInternalDrag.value = true
    draggedColumn.value = column
    originalOrder.value = [...columnOrder.value]
}

const handleDragOver = (event, column) => {
    event.preventDefault()
    if (!draggedColumn.value || draggedColumn.value === column) return

    if (draggedColumn.value.id === 'document' || column.id === 'document' ||
        draggedColumn.value.id === 'new' || column.id === 'new') return

    const newOrder = [...columnOrder.value]
    const draggedIdx = newOrder.indexOf(draggedColumn.value.id)
    const targetIdx = newOrder.indexOf(column.id)

    newOrder.splice(draggedIdx, 1)
    newOrder.splice(targetIdx, 0, draggedColumn.value.id)

    columnOrder.value = newOrder
}

const handleDragEnter = (column) => {
    dragTarget.value = column
}

const handleDragLeave = () => {
    dragTarget.value = null
}

const handleDragEnd = () => {
    isInternalDrag.value = false
    if (originalOrder.value && draggedColumn.value) {
        columnOrder.value = originalOrder.value
    }
    draggedColumn.value = null
    dragTarget.value = null
    originalOrder.value = null
}

const handleDrop = async (event, targetColumn) => {
    event.preventDefault()
    if (!draggedColumn.value) return

    if (draggedColumn.value.id === 'document' || targetColumn.id === 'document' ||
        draggedColumn.value.id === 'new' || targetColumn.id === 'new') {
        columnOrder.value = originalOrder.value
        return
    }

    try {
        const draggedPrompt = toValue(sheet)?.prompts.find(p => p.id === draggedColumn.value.id)
        const currentIndex = toValue(sheet)?.prompts.findIndex(p => p.id === draggedColumn.value.id)
        const newIndex = columnOrder.value.indexOf(draggedColumn.value.id) - 1

        if (draggedPrompt && currentIndex !== newIndex && currentIndex !== -1 && newIndex !== -1) {
            // Optimistically update the prompts array
            const newPrompts = [...toValue(sheet)?.prompts]
            const [removed] = newPrompts.splice(currentIndex, 1)
            newPrompts.splice(newIndex, 0, removed)
            toValue(sheet).prompts = newPrompts

            // Then perform the actual update
            await updatePrompt(draggedPrompt, newIndex)
        }
    } catch (error) {
        console.error('Failed to reorder columns:', error)
        if (originalOrder.value) {
            columnOrder.value = originalOrder.value
            const originalPrompts = originalOrder.value
                .filter(id => id !== 'document' && id !== 'new')
                .map(id => toValue(sheet)?.prompts.find(p => p.id === id))
                .filter(Boolean)
            toValue(sheet).prompts = originalPrompts
        }
    } finally {
        draggedColumn.value = null
        dragTarget.value = null
        originalOrder.value = null
    }
}

const handleRowClick = (row) => {
    selectedRow.value = row.original.document
    isRowOverviewOpen.value = true
}

// Add new refs for row dragging
const draggedRow = ref(null)
const dragTargetRow = ref(null)
const originalRowOrder = ref(null)

// Add new methods for row handling
const handleRowDragStart = (row, index) => {
    isInternalDrag.value = true
    draggedRow.value = { row, index }
    originalRowOrder.value = [...data.value]
}

const handleRowDragOver = (event, targetRow, targetIndex) => {
    event.preventDefault()

    const targetElement = event.currentTarget
    const rect = targetElement.getBoundingClientRect()
    const mouseY = event.clientY
    const threshold = rect.top + (rect.height / 2)

    const effectiveTargetIndex = mouseY < threshold ? targetIndex : targetIndex + 1


    if (!draggedRow.value) return

    const newData = [...data.value]
    const [draggedItem] = newData.splice(draggedRow.value.index, 1)
    newData.splice(effectiveTargetIndex, 0, draggedItem)
    toValue(sheet).documents = newData.map(item => item.document)

    draggedRow.value.index = effectiveTargetIndex
}

const handleRowDragEnter = (row, index, event) => {
    const targetElement = event.currentTarget
    const rect = targetElement.getBoundingClientRect()
    const mouseY = event.clientY
    const threshold = rect.top + (rect.height / 2)

    dragTargetRow.value = {
        row,
        index,
        position: mouseY < threshold ? 'before' : 'after'
    }
}

const handleRowDragLeave = () => {
    dragTargetRow.value = null
}

const handleRowDragEnd = () => {
    isInternalDrag.value = false
    if (originalRowOrder.value && draggedRow.value) {
        toValue(sheet).documents = [...originalRowOrder.value.map(item => item.document)]
    }
    draggedRow.value = null
    dragTargetRow.value = null
    originalRowOrder.value = null
}

const handleRowDrop = async (event, targetRow, targetIndex) => {
    event.preventDefault()
    if (!draggedRow.value) return

    // Optimistically update the data and sheet.documents
    const newData = [...data.value]
    const [draggedItem] = newData.splice(draggedRow.value.index, 1)
    newData.splice(targetIndex, 0, draggedItem)
    toValue(sheet).documents = newData.map(item => item.document)

    try {
        await updateDocument(draggedRow.value.row.document.id, targetIndex)
    } catch (error) {
        console.error('Failed to reorder rows:', error)
        if (originalRowOrder.value) {
            toValue(sheet).documents = [...originalRowOrder.value.map(item => item.document)]
        }
    } finally {
        draggedRow.value = null
        dragTargetRow.value = null
    }
}

const handleDocumentDrop = async (event) => {
    if (isInternalDrag.value) return
    event.preventDefault();
    event.stopPropagation();
    isDraggingOver.value = false
    draggedItemType.value = null

    try {
        const droppedData = JSON.parse(event.dataTransfer?.getData('text/plain'));
        toast.info('Adding documents to sheet...');

        const documents = await flattenDocuments(droppedData)
        const promises = [];
        const length = toValue(sheet)?.documents?.length;
        const newDocuments = documents.filter(doc => !toValue(sheet)?.documents?.some(d => d.id === doc.id))

        for (const [index, doc] of newDocuments.entries()) {
            promises.push(addDocument({ documentId: doc.id, index: length + index }));
        }

        await Promise.all(promises);

        if (newDocuments.length > 0) {
            toast.success(`Successfully added ${newDocuments.length} document${newDocuments.length === 1 ? '' : 's'}`);
        } else {
            toast.info('No new documents to add');
        }
    } catch (error) {
        console.error(error);
        toast.error('Failed to add documents to sheet');
    }
}

// Add these new refs near the top with other refs
const isDraggingOver = ref(false)
const draggedItemType = ref(null)

// Add this ref to track internal drags
const isInternalDrag = ref(false)

// Modified content drag enter handler
const handleContentDragEnter = (event) => {
    event.preventDefault()

    // Ignore if this is an internal drag operation
    if (isInternalDrag.value) return

    // Check if this is likely an external document drag
    const hasTextPlainType = Array.from(event.dataTransfer?.types || []).includes('text/plain')

    if (hasTextPlainType) {
        isDraggingOver.value = true
        draggedItemType.value = 'unknown' // Will be set correctly on drop
    }
}

const handleContentDragLeave = (event) => {
    event.preventDefault()
    // Only hide overlay if we're leaving the ResourceContent
    if (!event.currentTarget.contains(event.relatedTarget)) {
        isDraggingOver.value = false
        draggedItemType.value = null
    }
}

const container = ref(null)
provide('container', container)
</script>

<template>
    <MainLayout ref="container">
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <ResourceBreadcrumb :folder-id="sheet?.folder_id" :project-id="sheet?.project_id"
                        :resource="sheet" />
                </template>
            </NavbarLayout>
        </template>
        <template #header>
            <HeaderLayout>
                <template #left-actions>
                    <Button :disabled="sheet?.isRunning" size="xs"
                        class="flex flex-row space-x-2 rounded-full items-center" @click="isDocumentDialogOpen = true">
                        <Plus class="w-4 h-4 flex-shrink-0" />
                        <span>Add Documents</span>
                    </Button>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <TemplateDialog :types="['sheets']" @apply="applyTemplate"
                                    @save-as-template="saveAsTemplate">
                                    <Button :disabled="sheet?.isRunning" size="xs" variant="outline"
                                        class="flex flex-row space-x-2 rounded-full items-center">
                                        <LayoutTemplate class="w-4 h-4 flex-shrink-0" />
                                        <span>Templates</span>
                                    </Button>
                                </TemplateDialog>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Use a template to add columns to your sheet</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <div class="flex flex-row space-x-2 items-center text-muted-foreground text-sm pl-2"
                        v-if="sheet?.isRunning">
                        <Loader2 class="w-4 h-4 flex-shrink-0 animate-spin" />
                        <span>Running</span>
                    </div>
                </template>
                <template #right-actions>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button size="xs" variant="ghost"
                                    class="flex flex-row space-x-2 rounded-lg items-center"
                                    @click="isTextWrapped = !isTextWrapped">
                                    <WrapText v-if="!isTextWrapped" class="w-4 h-4 flex-shrink-0" />
                                    <AlignJustify v-else class="w-4 h-4 flex-shrink-0" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Toggle text wrapping in cells</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button size="xs" variant="ghost"
                                    class="flex flex-row space-x-2 rounded-lg items-center" @click="exportSheet">
                                    <Download class="w-4 h-4 flex-shrink-0" />
                                    <span>Export</span>
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Export sheet to Excel</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </template>
            </HeaderLayout>
            <Progress v-if="sheet?.isRunning" v-model="sheet.progress"
                class="w-[106%] rounded-none h-0.5 transition-opacity duration-300 -mx-3 mt-2.5 -mb-3" :class="{
                    'opacity-100': sheet.progress !== 100,
                    'opacity-0': sheet.progress === 100
                }" />
        </template>
        <template #content>
            <div class="h-full relative rounded-b-xl" v-if="!isLoading" @dragover.prevent
                @dragenter="handleContentDragEnter($event)" @dragleave="handleContentDragLeave($event)"
                @drop="handleDocumentDrop($event)">
                <Transition enter-active-class="transition-opacity duration-300"
                    leave-active-class="transition-opacity duration-300" enter-from-class="opacity-0"
                    leave-to-class="opacity-0">
                    <div v-if="isDraggingOver" class="absolute inset-0 bg-background/10 rounded-b-xl backdrop-blur-sm z-50 
                        flex items-center justify-center">
                        <div
                            class="w-[99.9%] h-[99.8%] flex items-center justify-center text-xl font-medium text-primary border-2 rounded-b-xl border-dotted border-primary/10">
                            <FileUp class="w-6 h-6 mr-2" />
                            <span>Drop to add to sheet</span>
                        </div>
                    </div>
                </Transition>
                <Table class="w-full" borderClass="h-full overflow-auto pb-1 border-t">
                    <TableHeader class="sticky top-0 z-40 bg-background">
                        <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id"
                            class="table-header-row">
                            <TableHead class="text-primary relative table-cell-transition" :class="{
                                'column-drag': draggedColumn?.id === header.column.id,
                                'border-r': !isLastColumn(header.column),
                                'column-drop-target show-indicator': dragTarget?.id === header.column.id && draggedColumn?.id !== header.column.id,
                                'bg-muted/50': draggedColumn?.id === header.column.id,
                                'sticky left-0 z-30 border-b bg-background': header.column.id === 'document'
                            }" v-for="header in headerGroup.headers" :key="header.id"
                                :draggable="!['document', 'new'].includes(header.column.id)" :style="{
                                    cursor: ['document', 'new'].includes(header.column.id) ? 'default' : 'move',
                                    width: header.column.id === 'document' ? '80px' :
                                        header.column.id === 'new' ? 'auto' : '120px',
                                    maxWidth: header.column.id === 'document' ? '100px' :
                                        header.column.id === 'new' ? 'none' : '300px',
                                    minWidth: header.column.id === 'new' ? '150px' : 'auto',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }" @dragstart="handleDragStart(header.column)"
                                @dragover="handleDragOver($event, header.column)"
                                @dragenter="handleDragEnter(header.column)" @dragleave="handleDragLeave"
                                @drop="handleDrop($event, header.column)" @dragend="handleDragEnd">
                                <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow v-for="(row, index) in table.getRowModel().rows" :key="row.id" :draggable="true"
                            class="hover:bg-muted/50 transition-colors hover:cursor-move table-body-row table-row-transition"
                            :class="{
                                'dragged-row': draggedRow?.index === index,
                                'row-drop-target show-indicator': dragTargetRow?.index === index && draggedRow?.index !== index,
                                'bg-muted/50': draggedRow?.index === index
                            }"
                            :data-drop-position="dragTargetRow?.index === index ? dragTargetRow.position : undefined"
                            :data-state="row.getIsSelected() ? 'selected' : undefined"
                            @dragstart="handleRowDragStart(row.original, index)"
                            @dragover="handleRowDragOver($event, row.original, index)"
                            @dragenter="(e) => handleRowDragEnter(row.original, index, e)"
                            @dragleave="handleRowDragLeave" @drop="handleRowDrop($event, row.original, index)"
                            @dragend="handleRowDragEnd" @click="handleRowClick(row)">
                            <TableCell class="border-b border-border text-xs font-light table-cell-transition" :class="{
                                'bg-muted/50': draggedColumn?.id === cell.column.id || draggedRow?.index === index,
                                'border-r': !isLastColumn(cell.column),
                                'sticky left-0 z-20 bg-background text-primary': cell.column.id === 'document'
                            }" v-for="cell in row.getVisibleCells()" :key="cell.id">
                                <div class="items-center" :style="{
                                    overflow: 'hidden',
                                    textOverflow: isTextWrapped ? 'clip' : 'ellipsis',
                                    whiteSpace: isTextWrapped ? 'normal' : 'nowrap',
                                    maxWidth: cell.column.id === 'new' ? 'auto' : '500px',
                                    height: 'auto',
                                    transition: 'max-height 0.2s ease-out, padding 0.2s ease-out, max-width 0.2s ease-out, background-color 0.2s ease-out',
                                    maxHeight: isTextWrapped ? '500px' : '24px',

                                }">
                                    <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter class="bg-background">
                        <TableRow>
                            <TableCell class="h-auto" v-for="(footer, index) in table.getFooterGroups()[0].headers"
                                :key="footer.id" :class="{
                                    'sticky left-0 z-20 bg-background text-primary shadow-[2px_0_4px_-2px_rgba(0,0,0,0.1)] rounded-bl-xl': index === 0,
                                    'border-r-none rounded-br-xl': index === table.getFooterGroups()[0].headers.length - 1
                                }">
                                <FlexRender class="h-[1rem]" v-if="footer.column.columnDef.footer"
                                    :render="footer.column.columnDef.footer" :props="footer.getContext()" />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
            <Loading v-else />
            <LibraryTableDialog :default-project-id="sheet?.project_id" :default-folder-id="sheet?.folder_id"
                v-model:open="isDocumentDialogOpen" @submit="({ selectedItems }) => confirmSelection(selectedItems)"
                :default-selected="sheet?.documents" :filters="{ type: 'documents' }" selectable multiple />
            <RowOverview v-if="container !== null" :document="selectedRow" :sheet="sheet" :prompts="sheet?.prompts"
                v-model="isRowOverviewOpen" @close="selectedRow = null" />
        </template>
    </MainLayout>
</template>

<style scoped>
.column-transition {
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.column-drag {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.column-drop-target {
    transition: all 0.2s ease;
}

.column-drop-target::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: hsl(var(--primary));
    transform: scaleY(0);
    transition: transform 0.15s ease;
}

.column-drop-target.show-indicator::before {
    transform: scaleY(1);
}

.table-cell-transition {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        background-color 0.2s ease,
        width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
}

.table-header-row,
.table-body-row {
    position: relative;
}

.row-drag {
    position: relative;
    z-index: 10;
    transform: translateX(-2px);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
}

.row-drop-target::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: hsl(var(--primary));
    transform: scaleX(0);
    transition: transform 0.15s ease;
}

.row-drop-target[data-drop-position="before"]::before {
    top: 0;
}

.row-drop-target[data-drop-position="after"]::before {
    bottom: 0;
}

.row-drop-target.show-indicator::before {
    transform: scaleX(1);
}

.dragged-row {
    background-color: hsl(var(--background));
    border: 1px solid hsl(var(--border));
    position: relative;
    z-index: 10;
    transform: translateX(-2px);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
}

.table-row-transition {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        background-color 0.2s ease,
        box-shadow 0.2s ease;
    will-change: transform;
}

.backdrop-blur-sm {
    backdrop-filter: blur(8px);
}
</style>
