<script setup>
import { MainLayout, NavbarLayout } from '@/layouts';
import { useRoute } from 'vue-router';
import { RecentsTable } from '@/tmp/components/recents';
import { ResourceBreadcrumb } from '@/tmp/components/resources';
import { Rss } from 'lucide-vue-next';

const route = useRoute()
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <ResourceBreadcrumb :icon="Rss" name="Activity" :project-id="route.params?.projectId" embedded />
                </template>
            </NavbarLayout>
        </template>
        <template #content>
            <RecentsTable :project-id="route.params?.projectId" num-items="100" />
        </template>
    </MainLayout>
</template>
