<script setup>
import { defineProps, ref, computed, defineModel } from 'vue'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/components/ui/table'
import { TextCursorInput, UserIcon, Clock, FolderOpen, Users, Loader2, MoreHorizontal } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar'
import { Checkbox } from '@/components/ui/checkbox'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components/ui/select'
import { useLibrary } from '@/tmp/services/library/composables/use-library'
import { CollapsibleSearch } from '@/tmp/components/controls'
import { useSortable, SortTrigger } from '@/tmp/components/behaviour/sortable';
import { Selectable, SelectableItem } from '@/tmp/components/behaviour/selectable';
import { Draggable, DraggableItem, DragTarget } from '@/tmp/components/behaviour/draggable';
import { useFilterable } from '@/tmp/composables';
import { ResourceItem, ResourceIcon, ResourceName } from '.';
import { ActionMenu } from '@/tmp/components/action-menu';
import { Folder, Project, useRepo } from '@/models';

const projectRepo = useRepo(Project);

const props = defineProps({
    selectable: {
        type: [Boolean, String],
        required: false,
        default: false
    },
    multiple: {
        type: Boolean,
        required: false,
        default: false
    },
    keepSelection: {
        type: Boolean,
        required: false,
        default: false
    },
    filters: {
        type: Object,
        required: false,
        default: () => ({})
    },
    embedded: {
        type: Boolean,
        required: false,
        default: false
    }
})

const query = ref('')
const projectId = defineModel('projectId', {
    type: String,
    required: false,
    default: null
})
const folderId = defineModel('folderId', {
    type: String,
    required: false,
    default: null
})
const { open, create, upload, folder, parent, children, isLoading, handleDrop } = useLibrary(projectId, folderId)
const project = computed(() => projectRepo.find(projectId.value))

const width = {
    checkbox: 'w-10',
    name: 'w-[60%]',
    owner: 'w-[20%]',
    time: 'w-[20%]'
}

const timeColumnOptions = [
    { key: 'created_at', label: 'Created' },
    { key: 'updated_at', label: 'Last updated' }
]
const timeColumn = ref(timeColumnOptions[0].label)
const timeColumnKey = computed(() => timeColumnOptions.find(option => option.label === timeColumn.value).key)

const { filteredItems } = useFilterable(children, {
    name: (item) => item.name.toLowerCase().includes(query.value.toLowerCase()),
    owner: (item) => props.filters?.members?.includes(item.owner) || !props.filters?.members?.length,
    type: (item) => [props.filters?.type, 'folders'].includes(item.constructor.entity) || props.filters?.type === 'all' || !props.filters?.type,
    dateRange: (item) => {
        const { start, end } = props.filters?.dateRange || { start: null, end: null }
        return (!start || item[timeColumnKey.value] >= start.toDate()) && (!end || item[timeColumnKey.value] <= end.toDate())
    }
})
const { sortedItems } = useSortable(filteredItems, {
    defaults: {
        by: 'time',
        dir: 'desc'
    },
    metrics: {
        name: (a, b) => a.name.localeCompare(b.name),
        time: (a, b) => a[timeColumnKey.value].getTime() - b[timeColumnKey.value].getTime()
    }
})

const selectedItems = defineModel({
    type: Array,
    required: false,
    default: () => []
})

function updateLocation(pId, fId) {
    projectId.value = pId;
    folderId.value = fId;
}

const folderIcon = computed(() => folder?.icon || 'Library')
const folderName = computed(() => folder?.name || 'Library')
</script>

<template>
    <Selectable v-model="selectedItems" v-slot="{ toggleSelectAll, isAllSelected }" :items="sortedItems"
        :select-mode="selectable === 'checkbox' ? 'toggle' : 'replace'" :multiple="multiple">
        <Draggable v-slot="{ isDragging }" v-model="selectedItems" :draggable="!embedded">
            <ActionMenu v-model:icon="folderIcon" v-model:name="folderName" @create="create" @upload="upload"
                variant="context" :disabled="embedded">
                <DragTarget :item="folder || project">
                    <Table :borderClass="cn([$attrs.class, '!overflow-visible h-full w-full'])"
                        @drop.prevent.stop="handleDrop(folder || project, $event)">
                        <TableHeader class="sticky top-0 z-10 bg-background">
                            <TableRow>
                                <TableHead v-if="selectable === 'checkbox'" @click.stop="toggleSelectAll"
                                    role="checkbox" class="w-15 items-center">
                                    <Checkbox :checked="isAllSelected" class="border-muted-foreground" />
                                </TableHead>
                                <TableHead :class="width.name">
                                    <div class="flex items-center gap-2">
                                        <div class="flex items-center gap-2">
                                            <TextCursorInput />
                                            <span>Name</span>
                                            <SortTrigger field="name" />
                                        </div>
                                        <CollapsibleSearch v-model="query" />
                                    </div>
                                </TableHead>
                                <TableHead :class="width.owner">
                                    <div class="flex items-center gap-2">
                                        <UserIcon />
                                        <span>Owner</span>
                                    </div>
                                </TableHead>
                                <TableHead :class="width.time">
                                    <div class="flex items-center">
                                        <Select v-model="timeColumn">
                                            <SelectTrigger
                                                class="w-fit h-fit px-1 py-2 gap-2 border-none bg-transparent hover:bg-accent rounded-md focus-visible:ring-0 focus:ring-offset-0 focus:ring-0 focus-visible:outline-transparent focus-visible:border-none">
                                                <Clock />
                                                <SelectValue :placeholder="timeColumn" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem v-for="column in timeColumnOptions" :key="column.key"
                                                    :value="column.label">
                                                    {{ column.label }}
                                                </SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <SortTrigger field="time" />
                                    </div>
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <DragTarget v-if="parent !== undefined" v-slot="{ isDraggedOver }" :item="parent"
                                @long-dragover="open(parent)">
                                <TableRow @drop.prevent.stop="handleDrop(parent, $event)"
                                    @dblclick="embedded ? updateLocation(parent?.project_id || projectId, parent?.id || null) : open(parent)"
                                    class="cursor-pointer group rounded hover:bg-accent"
                                    :class="{ 'bg-muted': isDraggedOver }">
                                    <TableCell v-if="selectable === 'checkbox'" role="checkbox" />
                                    <TableCell colspan="3">
                                        <div class="flex items-center gap-2 h-6">
                                            <FolderOpen />
                                            <MoreHorizontal class="-mb-2" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </DragTarget>
                            <template v-if="!isLoading">
                                <ResourceItem :key="item.id" v-for="item in sortedItems" :item="item"
                                    action-menu="context" :action-menu-disabled="selectedItems.length > 1 || embedded">
                                    <SelectableItem v-slot="{ isSelected }" :item="item">
                                        <DraggableItem v-slot="{ isDraggedOver }" :item="item"
                                            @long-dragover="item instanceof Folder ? open(item) : null">
                                            <TableRow @drop.prevent.stop="handleDrop(item, $event)"
                                                @dblclick="embedded ? item instanceof Folder ? updateLocation(item.project_id, item.id) : null : open(item)"
                                                class="cursor-pointer group rounded"
                                                :class="{ 'bg-muted': isSelected || isDraggedOver, 'hover:bg-accent': !isDragging }">
                                                <TableCell v-if="selectable === 'checkbox'" role="checkbox"
                                                    :class="width.checkbox">
                                                    <Checkbox :checked="isSelected" />
                                                </TableCell>
                                                <TableCell :class="width.name">
                                                    <div class="flex items-center gap-2 select-none">
                                                        <ResourceIcon />
                                                        <ResourceName />
                                                        <Users class="!size-3 text-muted-foreground" />
                                                    </div>
                                                </TableCell>
                                                <TableCell :class="width.owner">
                                                    <div class="flex items-center gap-2 select-none">
                                                        <Avatar class="size-6">
                                                            <AvatarImage :src="item?.owner?.profile_picture_url" />
                                                            <AvatarFallback>{{
                                                                item?.owner?.first_name?.charAt(0) +
                                                                item?.owner?.last_name?.charAt(0)
                                                                }}</AvatarFallback>
                                                        </Avatar>
                                                        <span>{{ item?.owner?.name }}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell :class="width.time" class="select-none">
                                                    {{ item[timeColumnKey].toLocaleDateString('nl-NL', {
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    }) }}
                                                </TableCell>
                                            </TableRow>
                                        </DraggableItem>
                                    </SelectableItem>
                                </ResourceItem>
                            </template>

                            <TableRow v-if="isLoading || !sortedItems?.length">
                                <TableCell colspan="4">
                                    <div v-if="isLoading" class="flex items-center justify-center gap-2 py-12">
                                        <Loader2 class="animate-spin" />
                                        <span>Loading...</span>
                                    </div>
                                    <div v-else
                                        class="flex flex-col items-center justify-center gap-3 py-12 text-muted-foreground">
                                        <FolderOpen class="size-12" />
                                        <span v-if="folderId">This folder is empty</span>
                                        <span v-else>Nothing here yet...</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DragTarget>
            </ActionMenu>
        </Draggable>
    </Selectable>
</template>
