<script setup>
import { defineProps } from 'vue';
import { cn } from '@/lib/utils'

const props = defineProps({
  class: { type: String, default: '' },
})
</script>

<template>
  <ul data-sidebar="menu-badge" :class="cn(
    'flex min-w-0 translate-x-px flex-col gap-1 border-l border-sidebar-border ml-4 pr-2 py-0.5 w-full',
    'group-data-[collapsible=icon]:hidden',
    props.class,
  )">
    <slot />
  </ul>
</template>
