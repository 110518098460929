<script setup>
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { defineProps, defineModel } from 'vue';
import * as icons from 'lucide-vue-next';

const config = defineModel({
    default: {
        color: 'primary',
        icon: 'Box'
    }
});
const colorOptions = ['primary', 'stone-500', 'slate-400', 'blue-900', 'indigo-600', 'emerald-600', 'amber-600', 'yellow-500', 'red-400']
const curatedIcons = [
    "Box", "Cuboid", "Cone", "Pyramid", "Cylinder", "Shapes", "Square", "SquareDot", "SquareMinus", "SquareSlash",
    "SquareRadical", "SquareActivity", "SquareEqual", "InspectionPanel", "Circle", "CircleDot", "CircleMinus",
    "CircleSlash", "CircleDashed", "CircleDotDashed", "Blend", "Diamond", "DiamondMinus", "Triangle", "Hexagon",
    "Bolt", "Octagon", "Torus", "Spade", "Club", "Cloud", "Sparkle", "Zap", "Droplet", "Flame", "Waves", "Wind",
    "Tornado", "Snowflake", "Hash", "Flower", "Leaf", "LeafyGreen", "Sprout", "LandPlot", "TreeDeciduous",
    "TreePalm", "TreePine", "Tent", "TentTree"
]
const filteredIcons = Object.fromEntries(Object.entries(icons).filter(([key]) => curatedIcons.includes(key)));

defineProps({
    size: {
        type: Number,
        required: false,
        default: 24
    },
    asButton: {
        type: Boolean,
        required: false,
        default: false
    }
});
</script>

<template>
    <DropdownMenu>
        <DropdownMenuTrigger>
            <Button v-if="asButton" variant="ghost" size="icon" class="rounded-xl">
                <component :is="icons[config.icon]" :class="`text-${config.color}`" />
            </Button>
            <component v-else :is="icons[config.icon]" :size="size" :class="`text-${config.color}`"
                class="cursor-pointer hover:bg-accent rounded-xl" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" :alignOffset="-10">
            <DropdownMenuGroup class="flex items-center justify-between px-2 py-1.5">
                <component :is="icons[config.icon]" :size="18" :class="`text-${config.color}`" />
                <div @select.prevent v-for="colorOption in colorOptions" :key="colorOption" :class="[
                    `bg-${colorOption}`,
                    'size-4 rounded-full cursor-pointer transition-none hover:bg-none !hover:bg-transparent !hover:text-foreground'
                ]" @click="config.color = colorOption">
                </div>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuGroup class="overflow-y-auto max-h-[300px] grid grid-cols-10 items-center">
                <DropdownMenuItem @select.prevent v-for="(Icon, name) in filteredIcons" :key="name"
                    class="cursor-pointer" @click="config.icon = name">
                    <component :is="Icon" :size="16" />
                </DropdownMenuItem>
            </DropdownMenuGroup>
        </DropdownMenuContent>
    </DropdownMenu>
</template>
