import { toValue, ref } from 'vue';

export function useDraggable(selectedItems, dragImage = null) {
    const hoveredItem = ref(undefined);
    const draggedItems = ref([]);
    const isDragging = ref(false);

    const onDragstart = (event, item) => {
        if (!toValue(selectedItems).some(i => toValue(i).id === toValue(item).id)) {
            draggedItems.value = [item];
        } else {
            draggedItems.value = toValue(selectedItems);
        }
        if (dragImage) {
            event.dataTransfer.setDragImage(dragImage.value.el, 10, 10);
        }
        event.dataTransfer.setData('text/plain', JSON.stringify(draggedItems.value.map(item => ({
            ...toValue(item),
            entity: item.constructor.entity.slice(0, -1)
        }))));
        event.dataTransfer.effectAllowed = 'move';
        requestAnimationFrame(() => {
            isDragging.value = true;
            selectedItems.value = [];
        });
    };

    const onDragend = (event) => {
        event.dataTransfer.clearData('text/plain');
        draggedItems.value = [];
        hoveredItem.value = undefined;
        requestAnimationFrame(() => {
            isDragging.value = false;
        });
    };
    
    const onDragover = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onDragenter = (event, item) => {
        event.preventDefault();
        if (item !== undefined) {
            hoveredItem.value = item;
        }
    };

    const onDragleave = (event, item) => {
        event.preventDefault();
        if (item !== undefined && hoveredItem.value === item && !event.target.contains(event.relatedTarget)) {
            hoveredItem.value = undefined;
        }
    };

    const isItemDraggedOver = (item) => {
        if (hoveredItem.value === undefined) return false;
        if (hoveredItem.value === null) return item === null;
        return hoveredItem.value?.id === item?.id;
    };

    const isItemDragged = (item) => {
        return draggedItems.value.some(i => toValue(i).id === toValue(item).id) && isDragging.value;
    };

    return {
        onDragstart,
        onDragover,
        onDragend,
        onDragenter,
        onDragleave,
        isItemDraggedOver,
        isItemDragged,
        draggedItems,
        isDragging
    }
}