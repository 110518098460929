<script setup>
import { MainLayout, NavbarLayout } from '@/layouts';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage } from '@/components/ui/breadcrumb';
import { Search, Gavel, Scale } from 'lucide-vue-next';
import { ResultGroup } from '../components';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbPage class="flex flex-row space-x-2 items-center">
                                    <Search />
                                    <span>Search</span>
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #content>
            <div class="w-full flex flex-col gap-4 px-3">
                <ResultGroup type="cases" :num-results="8" :query="route.query.q" size="lg"
                    @show-more="router.push({ name: 'type-results', params: { type: 'cases' }, query: { q: route.query.q } })">
                    <Gavel class="w-6 h-6" />
                    <span class="text-lg font-semibold">Case Law</span>
                </ResultGroup>
                <ResultGroup type="consolidations" :num-results="8" :query="route.query.q" size="lg"
                    @show-more="router.push({ name: 'type-results', params: { type: 'regulations' }, query: { q: route.query.q } })">
                    <Scale class="w-6 h-6" />
                    <span class="text-lg font-semibold">Laws & Regulations</span>
                </ResultGroup>
            </div>
        </template>
    </MainLayout>
</template>