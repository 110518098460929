<script setup>
import { Tabs } from '@/components/ui/tabs';
import { useSidepanel } from './use-sidepanel';
import { storeToRefs } from 'pinia';

const sidepanel = useSidepanel();
const { currentTab } = storeToRefs(sidepanel);
</script>

<template>
    <Tabs v-model="currentTab">
        <slot v-bind="sidepanel" />
    </Tabs>
</template>