import { Thread, ModelRun } from '@/models'
import { useAxiosRepo } from '@pinia-orm/axios'
import { useSocket } from '@/composables'
import { useResource } from '@/resource'
import { onMounted, watch, computed, ref } from 'vue'
import { debounce } from 'lodash-es'
import { api } from '@/plugins/api'

export function useThread(withEffects = false) {
    const socket = useSocket('threads');
    const threadRepo = useAxiosRepo(Thread).setAxios(api)
    const runRepo = useAxiosRepo(ModelRun).setAxios(api)
    let pendingRuns = new Map();

    const processPendingRuns = debounce(() => {
        const runsToSave = Array.from(pendingRuns.values());
        if (runsToSave.length) {
            runRepo.save(runsToSave);
            pendingRuns.clear();
            requestAnimationFrame(() => {
                thread.runs = runRepo.where('thread_id', thread.id).get();
            });
        }
    }, 10);

    const socketIsLoading = ref(false)

    const preFetch = withEffects ? async (newThread) => {
        socketIsLoading.value = true
        await Promise.all([
            threadRepo.api().get(`/threads/${newThread.id}`),
            !newThread.runs?.length ? runRepo.api().get(`/threads/${newThread.id}/runs`) : Promise.resolve()
        ])
        await socket.emit('enter_thread', { thread_id: newThread.id }, () => {
            socketIsLoading.value = false
        })
    } : null

    const onUnmountedEffect = withEffects ? async (oldThreadId) => {
        await socket.emit('leave_thread', { thread_id: oldThreadId })
    } : null

    

    const {
        resource: thread,
        refreshResource: refreshThread,
        deleteResource: deleteThread,
        saveResource: saveThread,
        isLoading: resourceIsLoading
    } = useResource(
        Thread,
        'threadId',
        preFetch,
        onUnmountedEffect
    )

    onMounted(async() => {
        if (!withEffects) return
        await Promise.all([
            socket.on('thread', (data) => {
                console.log(data)
                threadRepo.save(data)
                refreshThread()
            }),
            socket.on('run', (data) => {
                console.log(data)
                if (data.data) {
                    pendingRuns.set(data.data.id, data.data);
                    processPendingRuns();
                }
                if (data.error) {
                    throw new Error(data.error)
                }
            })
        ])
    })

    watch(thread?.id, async (newId, oldId) => {
        if (!withEffects) return
        if (newId !== oldId) {
            await socket.emit('leave_thread', { thread_id: oldId })
            socketIsLoading.value = true
            await socket.emit('enter_thread', { thread_id: newId }, () => {
                console.log('socketIsLoading', socketIsLoading.value)
                socketIsLoading.value = false
            })
        }
    })

    const isLoading = computed(() => resourceIsLoading.value || socketIsLoading.value)
    const isRunning = computed(() => thread.runs.some(run => run.status === 'running'))

    async function startRun(data) {
        if (isRunning.value || !data.text.trim()) return
        await socket.emit('run', { thread_id: thread.id, query: data.text, documents: data.attachments?.map(attachment => attachment.id), focus: data.sources?.focus, areas_of_law: data.sources?.areas_of_law, validity_date: data.sources?.validity_date })
    }

    return {
        thread,
        preFetch,
        isLoading,
        isRunning,
        startRun,
        refreshThread,
        deleteThread,
        saveThread
    }
}
