<script setup>
import { computed, defineProps } from "vue";
import { TagsInputItemText, useForwardProps } from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps({
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  class: { type: null, required: false },
});

const delegatedProps = computed(() => {
  const { ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <TagsInputItemText v-if="!$slots.default" v-bind="forwardedProps"
    :class="cn('py-1 px-2 text-sm rounded bg-transparent', props.class)" />
  <div v-else :class="cn('py-1 px-2 text-sm rounded bg-transparent', props.class)">
    <slot />
  </div>
</template>
