<script setup>
import { defineProps, inject, computed, toRefs, defineEmits, watchEffect, onUnmounted } from 'vue';
import { Primitive } from 'radix-vue';

const props = defineProps({
    item: {
        type: Object,
        required: true
    }
})
const { item } = toRefs(props);

const hooks = inject('draggableHooks');
const helpers = inject('draggableHelpers');

const isDraggedOver = computed(() => helpers?.isItemDraggedOver(item.value));

const emit = defineEmits(['long-dragover', 'drop']);
let dragoverTimeout = null;

const startLongDragoverTimer = () => {
    cancelLongDragover();
    dragoverTimeout = setTimeout(() => {
        emit('long-dragover');
    }, 2000);
};

const cancelLongDragover = () => {
    if (dragoverTimeout) {
        clearTimeout(dragoverTimeout);
        dragoverTimeout = null;
    }
};

watchEffect(() => {
    if (isDraggedOver.value) {
        startLongDragoverTimer();
    } else {
        cancelLongDragover();
    }
});

onUnmounted(() => {
    cancelLongDragover();
});
</script>

<template>
    <Primitive @dragover.stop="hooks?.onDragover" @dragenter.stop="hooks?.onDragenter?.($event, item)"
        @dragleave.stop="hooks?.onDragleave?.($event, item)" @drop.prevent.stop="emit('drop', item, $event)" as-child>
        <slot :is-dragged-over="isDraggedOver" />
    </Primitive>
</template>
