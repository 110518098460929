import { ref, watch, onMounted, onUnmounted, computed, toValue } from 'vue';
import { Case, Consolidation } from '@/models';
import { useRoute } from 'vue-router';
import { debounce } from 'lodash-es';
import { api } from '@/plugins/api';

export function useSearch({ query, type, limit = 100 }) {
    const modelMap = {
        'cases': Case,
        'consolidations': Consolidation
    }
    type = toValue(type);
    const route = useRoute();
    const focus = ref(route.query.sourceFilters);
    const areasOfLaw = ref(route.query.areaFilters);
    const startDate = ref(route.query.startDate);
    const endDate = ref(route.query.endDate);
    const offset = ref(0);
    const results = ref([]);
    const isLoading = ref(false);
    const currentController = ref(null);

    async function fetch() {
        let newResults = [];
        isLoading.value = true;
        if (currentController.value) currentController.value.abort();
        currentController.value = new AbortController();
        try {
            await api.get(`/search/${type}`, {
                params: {
                    query: query.value.trim(),
                    focus: focus.value,
                    areas_of_law: areasOfLaw.value,
                    start_date: startDate.value,
                    end_date: endDate.value,
                    limit: limit,
                    offset: offset.value
                },
                responseType: 'text',
                signal: currentController.value.signal,
                onDownloadProgress: (progressEvent) => {
                    const text = progressEvent.event.target.responseText;
                    try {
                        const jsonStrings = text.split('}{').map((str, i, arr) => {
                            if (i === 0) return str + '}';
                            if (i === arr.length - 1) return '{' + str;
                            return '{' + str + '}';
                        });
                        newResults = [...results.value, ...jsonStrings.map(
                            jsonStr => JSON.parse(jsonStr)
                        )];
                        results.value = newResults.filter(
                            (result1, index) => newResults.findIndex(
                                result2 => result2.id === result1.id
                            ) === index
                        );
                        offset.value = results.value.length;
                    } catch (e) {
                        console.error(e);
                    }
                }
            });
        } catch (err) {
            if (err.name !== 'CanceledError') throw err;
        } finally {
            isLoading.value = false;
        }
    }

    watch(
        [query, route.query, focus, areasOfLaw, startDate, endDate],
        async ([newQuery, newRouteQuery], [oldQuery, oldRouteQuery]) => {
            if (newRouteQuery !== oldRouteQuery) {
                query.value = newRouteQuery.q;
                focus.value = newRouteQuery.sourceFilters;
                areasOfLaw.value = newRouteQuery.areaFilters;
                startDate.value = newRouteQuery.startDate;
                endDate.value = newRouteQuery.endDate;
                return;
            }
            offset.value = 0;
            results.value = [];
            if (!newQuery?.trim()) return;
            if (results.value.length === 0 || newQuery === oldQuery) {
                await fetch();
            } else {
                debounce(fetch, 300);
            }
            
        }
    );

    onMounted(async () => { if (query.value?.trim()) await fetch() });
    onUnmounted(() => { if (currentController.value) currentController.value.abort() });

    return { query, focus, areasOfLaw, startDate, endDate, fetch, results: computed(() => results.value.map(item => new modelMap[type](item))), isLoading }
}
