<script setup>
import { defineProps, reactive, computed, h, ref } from 'vue'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table'
import { MoreHorizontal, ChevronUp, ChevronDown } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { User } from '@/models'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Checkbox } from '@/components/ui/checkbox'

const props = defineProps({
    repo: {
        type: Function,
        required: true
    },
    columns: {
        type: Array,
        required: true
    },
    orderBy: {
        type: Object,
        required: false,
        default: null
    },
    searchBy: {
        type: String,
        required: false,
        default: null
    },
    search: {
        type: String,
        required: false,
        default: ''
    },
    onDblclick: {
        type: Function,
        required: false,
        default: null
    },
    filters: {
        type: Array,
        required: false,
        default: null
    }
})

const orderBy = reactive(props.orderBy)
const selected = ref([])
const lastSelected = ref(null)

function formatCellValue(row, key) {
    const value = row[key]
    if (value instanceof Date) {
        return h('span', {}, value.toLocaleDateString('nl-NL', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }))
    }
    if (value instanceof User) {
        return h('div', { class: 'flex flex-row gap-2 items-center' }, [
            h(Avatar, { class: 'size-6' }, h(AvatarImage, { src: value.profile_picture_url })),
            h('span', {}, value.first_name + ' ' + value.last_name)
        ])
    }
    if (key === 'project') {
        return h('span', {}, value?.name || 'No project')
    }
    return h('span', {}, value)
}

function reverseDirection(direction) {
    return direction === 'asc' ? 'desc' : 'asc'
}

function toggleSort(column) {
    if (orderBy.key === column.key) {
        orderBy.direction = reverseDirection(orderBy.direction)
    } else {
        orderBy.key = column.key
        orderBy.direction = 'asc'
    }
}

function toggle(row, event) {
    const isCtrlPressed = event.metaKey || event.ctrlKey
    const isShiftPressed = event.shiftKey

    if (isShiftPressed && lastSelected.value) {
        const currentIndex = data.value.findIndex(item => item.id === row.id)
        const lastIndex = data.value.findIndex(item => item.id === lastSelected.value)
        const [start, end] = [Math.min(currentIndex, lastIndex), Math.max(currentIndex, lastIndex)]

        const rangeIds = data.value.slice(start, end + 1).map(item => item.id)
        selected.value = [...new Set([...selected.value, ...rangeIds])]
        lastSelected.value = row.id
    } else if (isCtrlPressed) {
        if (selected.value.includes(row.id)) {
            selected.value = selected.value.filter(id => id !== row.id)
        } else {
            selected.value = [...selected.value, row.id]
        }
        lastSelected.value = row.id
    } else if (selected.value.length === 0) {
        selected.value = [row.id]
        lastSelected.value = row.id
    } else {
        selected.value = []
        lastSelected.value = null
    }


}

function toggleAll() {
    if (selected.value.length === data.value.length) {
        selected.value = []
    } else {
        selected.value = data.value.map(row => row.id)
    }
}

const checkboxState = computed(() => {
    if (data.value.length === 0) {
        return false
    }
    return selected.value.length === data.value.length ? true : selected.value.length > 0 ? 'indeterminate' : false
})

const data = computed(() => {
    const query = props.repo.withAll()
    if (props.searchBy && props.search) {
        query.where(props.searchBy, value => value?.toLowerCase().includes(props.search?.toLowerCase()))
    }
    props.filters?.forEach(filter => {
        query.where(filter.key, filter.filter)
    })
    orderBy && query.orderBy(item => {
        const value = item[orderBy.key]
        if (value instanceof User) {
            return value.first_name + ' ' + value.last_name
        }
        if (value?.name) {
            return value.name
        }
        if (value instanceof Date) {
            return value.getTime()
        }
        return value
    }, orderBy.direction)
    return query.get()
})
</script>

<template>
    <Table :borderClass="cn([$attrs.class, '!overflow-visible'])">
        <TableHeader class="sticky top-0 z-10 bg-background">
            <TableRow>
                <TableHead @click.stop="toggleAll" role="checkbox" class="w-10 text-left">
                    <Checkbox :checked="checkboxState" />
                </TableHead>
                <TableHead class="w-[100px]" @click="toggleSort(column)" v-for="column in columns" :key="column.key">
                    <div class="flex flex-row gap-2 items-center cursor-pointer w-full">
                        <component :is="column.icon" class="w-4 h-4" />
                        <span class="truncate">{{ column.label }}</span>
                        <div class="flex flex-col">
                            <ChevronUp class="w-4 h-4"
                                :class="{ 'opacity-50': orderBy?.key !== column.key || orderBy?.direction !== 'asc' }" />
                            <ChevronDown class="w-4 h-4 -mt-2"
                                :class="{ 'opacity-50': orderBy?.key !== column.key || orderBy?.direction !== 'desc' }" />
                        </div>
                    </div>
                </TableHead>
                <TableHead class="w-10" />
            </TableRow>
        </TableHeader>
        <TableBody>
            <TableRow v-for="row in data" :key="row.id" @click="(event) => toggle(row, event)"
                @dblclick="onDblclick?.(row)" class="cursor-pointer hover:bg-accent"
                :class="{ 'bg-muted': selected.includes(row.id) }">
                <TableCell @click.stop="(event) => toggle(row, event)" class="w-10 text-left">
                    <Checkbox :checked="selected.includes(row.id)" />
                </TableCell>
                <TableCell v-for="column in columns" :key="column.key" class="text-left">
                    <component :is="formatCellValue(row, column.key)" />
                </TableCell>
                <TableCell class="w-10">
                    <Button @click.stop size="icon" variant="ghost" class="size-5">
                        <MoreHorizontal />
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow v-if="data.length === 0" class="h-full">
                <TableCell colspan="100%" class="text-center h-full">
                    <span class="text-muted-foreground">No results</span>
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
</template>
