import { useAxiosRepo } from '@pinia-orm/axios';
import { Folder, Document } from '@/models';
import { api } from '@/plugins/api';
import { ref, toValue } from 'vue';
import { toast } from 'vue-sonner';

const folderRepo = useAxiosRepo(Folder).setAxios(api);
const documentRepo = useAxiosRepo(Document).setAxios(api);

export function useUpload(projectId, folderId) {
    const files = ref([]);
    const isUploading = ref(false);

    async function readEntry(entry, path = '') {
        if (entry.isFile) {
            return new Promise((resolve) => {
                entry.file(async (file) => {
                    const fileWithPath = new File([file], file.name, {
                        type: file.type,
                        lastModified: file.lastModified
                    });
                    fileWithPath._path = path + file.name;
                    console.log(fileWithPath);
                    files.value.push(fileWithPath);
                    resolve();
                });
            });
        } else if (entry.isDirectory) {
            const dirReader = entry.createReader();
            const entries = await new Promise((resolve) => {
                const results = [];
                const readEntries = () => {
                    dirReader.readEntries(async (entries) => {
                        if (entries.length === 0) {
                            resolve(results);
                        } else {
                            results.push(...entries);
                            readEntries();
                        }
                    });
                };
                readEntries();
            });

            const folderPath = path + entry.name + '/';
            await Promise.all(
                entries.map((entry) => 
                    readEntry(entry, folderPath)
                )
            );
        }
    }

    async function processItems(items) {
        for (const item of items) {
            if (item instanceof File) {
                files.value.push(item);
            } else if (item.webkitGetAsEntry) {
                const entry = item.webkitGetAsEntry();
                if (entry) {
                    await readEntry(entry);
                }
            } else if (item.getAsEntry) {
                const entry = item.getAsEntry();
                if (entry) {
                    await readEntry(entry);
                }
            }
        }
    }

    function readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const upload = async (items) => {
        try {
            files.value = [];
            await processItems(items);

            if (files.value.length === 0) return;

            isUploading.value = true;
            toast.info(`Uploading ${files.value.length} files`);

            // Group files by their directory depth and path
            const filesByLevel = files.value.reduce((acc, file) => {
                const pathParts = (file?._path || file.webkitRelativePath).split('/');
                pathParts.pop();
                const depth = pathParts.length;
                const path = pathParts.join('/');
                
                acc[depth] = acc[depth] || {};
                acc[depth][path] = acc[depth][path] || [];
                acc[depth][path].push(file);
                return acc;
            }, {});

            // Track created folders to avoid duplicates
            const folderCache = new Map();
            folderCache.set('', toValue(folderId));

            // Process each level, starting from root (depth 0)
            const maxDepth = Math.max(...Object.keys(filesByLevel).map(Number));
            for (let depth = 0; depth <= maxDepth; depth++) {
                const levelFiles = filesByLevel[depth] || {};

                // Process all paths at current depth
                for (const [path, files] of Object.entries(levelFiles)) {
                    // Get or create folder chain
                    let currentParentId = toValue(folderId);
                    if (path) {
                        const pathParts = path.split('/');
                        for (let i = 0; i < pathParts.length; i++) {
                            const partialPath = pathParts.slice(0, i + 1).join('/');
                            if (!folderCache.has(partialPath)) {
                                const response = await folderRepo.api().post('/folders/', {
                                    name: pathParts[i],
                                    project_id: toValue(projectId),
                                    parent_id: folderCache.get(pathParts.slice(0, i).join('/') || '')
                                });
                                folderCache.set(partialPath, response.entities[0].id);
                            }
                        }
                        currentParentId = folderCache.get(path);
                    }

                    // Upload all files in current path
                    const promises = [];
                    for (const file of files) {
                        promises.push(documentRepo.api().post('/documents/', {
                            title: file.name,
                            project_id: toValue(projectId),
                            folder_id: currentParentId,
                            content: await readFileAsBase64(file),
                            content_type: file.name.split('.').pop()
                        }));
                    }
                    await Promise.all(promises);
                }
            }
        } catch (error) {
            toast.error('Failed to upload files');
        } finally {
            toast.success('Files uploaded successfully');
            isUploading.value = false;
            files.value = [];
        }
    }

    const handleUpload = async (event) => {
        await upload(event.dataTransfer ? event.dataTransfer.items : event.target.files);
    };

    return { upload, handleUpload, isUploading };
}