import { Folder, Thread, Sheet, Document, Project, useRepo } from '@/models';
import { api } from '@/plugins/api';
import { toast } from 'vue-sonner';
import { useUpload } from '@/tmp/composables/use-upload';
import { useRouter, useRoute } from 'vue-router';
import { computed, toValue, toRef } from 'vue';
import { useQuery } from '@tanstack/vue-query';

export function useLibrary(projectId, folderId, { embedded = false } = {}) {

    const folderRepo = useRepo(Folder)
    const threadRepo = useRepo(Thread)
    const sheetRepo = useRepo(Sheet)
    const documentRepo = useRepo(Document)
    const projectRepo = useRepo(Project)
    const repoMap = {
        'project': projectRepo,
        'folder': folderRepo,
        'thread': threadRepo,
        'sheet': sheetRepo,
        'document': documentRepo
    }

    function getType(item) {
        if (item instanceof Folder) return 'folder';
        if (item instanceof Thread) return 'thread';
        if (item instanceof Sheet) return 'sheet';
        if (item instanceof Document) return 'document';
        if (item instanceof Project) return 'project';
        return 'folder';
    }

    projectId = toRef(projectId);
    folderId = toRef(folderId);

    const folder = computed(() => {
        return folderRepo.with('parent').find(toValue(folderId));
    });
    const parent = computed(() => {
        return folder.value?.parent;
    });

    let { handleUpload: upload } = useUpload(projectId, folderId);
    const router = useRouter();
    const route = useRoute();
    async function create(type, { name = 'New ' + type, autoOpen = true } = {}) {
        try {
            toast.info('Creating ' + type);
            const response = await repoMap[type].api().post('/' + type + 's/', {
                name: name,
                project_id: toValue(projectId),
                [type === 'folder' ? 'parent_id' : 'folder_id']: toValue(folderId)
            });
            toast.success('Successfully created ' + type);
            if (autoOpen) {
                open(response.entities[0]);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to create ' + type);
        }
    }

    async function move(target, items) {
        let targetProject = null;
        if (target !== null && !(target instanceof Folder || target instanceof Project)) {
            targetProject = target?.project_id || null;
            target = target?.parent || null;
        }

        if (target === undefined || !items || items.includes(target)) return;

        const promises = [];
        let payload = { destination_folder_id: target?.id || null, destination_project_id: targetProject || null };
        if (target instanceof Project) {
            payload = { destination_project_id: target?.id || null };
        }
        for (const item of items) {
            const endpoint = `/actions/${item.entity || getType(item)}s/${item.id}/move`;
            promises.push(api.post(endpoint, payload));
        }
        const results = await Promise.all(promises);
        results.forEach((result, index) => {
            const item = items[index];
            repoMap[item.entity || getType(item)].save(result.data);
        });
    }

    async function save(target) {
        try {
            const response = await repoMap[getType(target)]
                .api()
                .patch(`/${getType(target)}s/${target.id}`, target);
            toast.success(`Successfully updated '${response.entities[0].name}'`);
        } catch (error) {
            console.error(error);
            toast.error(`Failed to update '${target.name}'`);
        }
    }

    async function destroy(target) {
        try {
            if (route.fullPath.includes(target.id)) router.push({ name: 'home' });
            await repoMap[getType(target)]
                .api()
                .delete(`/${getType(target)}s/${target.id}`, { delete: target.id });
            toast.success(`Successfully deleted '${target.name}'`);
        } catch (error) {
            console.error(error);
            toast.error(`Failed to delete '${target.name}'`);
        }
    }

    function open(target) {
        if (!embedded) {
            const type = getType(target);
            const routeType = type === 'folder' ? 'documents' : type;
            const routeName = toValue(projectId) ? 'project-' + routeType : routeType;
            const route = { name: routeName, params: { projectId: toValue(projectId) } };
            if (type === 'folder') {
                if (target?.id) route.query = { folderId: target?.id };
            } else if (type === 'document') {
                route.params.resourceId = target.id;
            } else {
                route.params[type + 'Id'] = target.id;
            }
            router.push(route);
        } else {
            if (target instanceof Folder) {
                projectId.value = target.project_id;
                folderId.value = target.id;
            } else if (target === null) {
                folderId.value = null;
            }
        }
    }

    async function fetchChildren(pId, fId = "*") {
        const promises = [];
        for (const [type, repo] of Object.entries(repoMap)) {
            if (type === 'project') continue;
            promises.push(repo
                .api()
                .get('/' + type + 's/', {
                    params: {
                        project_id: toValue(pId),
                        [type === 'folder' ? 'parent_id' : 'folder_id']: toValue(fId)
                    }
                }));
        }
        await Promise.all(promises)
        return getChildren(pId, fId);
    }

    function getChildren(pId, fId = undefined) {
        const items = [];
        for (const [type, repo] of Object.entries(repoMap)) {
            items.push(...repo
                .withAllRecursive()
                .where('project_id', toValue(pId))
                .where(type === 'folder' ? 'parent_id' : 'folder_id', (value) => toValue(fId) === undefined || value === toValue(fId))
                .get());
        }
        return items;
    }

    const children = computed(() => getChildren(toValue(projectId), toValue(folderId)));

    const { isLoading } = useQuery({
        queryKey: ['children', projectId, folderId],
        queryFn: () => fetchChildren(projectId, folderId),
        staleTime: 60000
    });

    async function handleDrop(item, event) {
        try {
            if (event.dataTransfer.files.length > 0) {
                toast.info('Uploading files...');
                const { upload } = useUpload(
                    item instanceof Project ? item.id : item?.project_id || null, 
                    item instanceof Folder ? item?.id : item?.folder_id || null
                );
                await upload(event.dataTransfer.items);
                toast.success('Files uploaded successfully');
            } else {
                const items = JSON.parse(event.dataTransfer.getData('text/plain'));
                toast.info('Moving items...');
                await move(item, items);
                toast.success(`Successfully moved ${items.length} item${items.length === 1 ? '' : 's'}`);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to process dropped items');
        }
    }

    return {
        folder,
        parent,
        open,
        move,
        create,
        save,
        destroy,
        upload,
        children,
        isLoading,
        getChildren,
        handleDrop,
        fetchChildren
    };
}