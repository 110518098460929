<script setup>
import { computed, defineProps, watch, defineEmits, ref, onMounted, nextTick, toRefs } from 'vue'
import { useElementSize } from '@vueuse/core';
import AIMessage from './AIMessage.vue'
import HumanMessage from './HumanMessage.vue'

const props = defineProps(['i', 'run', 'threadId', 'isLast'])
const { i, run, threadId, isLast } = toRefs(props)
const emits = defineEmits(['changing', 'sizing', 'stream']);
const el = ref(null);
const { height } = useElementSize(el);

const scrollContainer = ref(null);
const scrollIntoView = () => {
  nextTick(() => {
    if (scrollContainer.value && isLast.value) {
      scrollContainer.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  });
};

const progress = computed(() => {
  if (run.value?.suggested_follow_ups?.length > 0) return 3;
  if (run.value?.answer) return 2;
  if (run.value?.sources?.cases?.length > 0 || run.value.sources?.consolidations?.length > 0) return 1;
  return 0;
})

onMounted(() => {
  emits('sizing', i, height.value)
  if (isLast.value) {
    scrollIntoView();
  }
})

watch([run, height], () => {
  emits('stream')
  emits('sizing', i, height.value)

  if (isLast.value && run.value?.status === 'running') {
    scrollIntoView();
  }
}, { immediate: true })

// const cls = computed(() => {
//   let c = 'relative'
//   if (isLast.value) c += ' bg-background '
//   return c
// })

watch(progress, (newProgress, oldProgress) => {
  if (isLast.value && newProgress === 3 && oldProgress !== 3) {
    scrollIntoView();
  }
})
</script>

<template>
  <div ref="scrollContainer" class="relative space-y-4 bg-transparent">
    <HumanMessage :run="run" />
    <AIMessage :run="run" :threadId="threadId" :isLast="isLast" />
  </div>
</template>