<script setup>
import { ComboboxRoot, ComboboxAnchor, ComboboxInput, ComboboxContent, ComboboxPortal } from 'radix-vue';
import { CommandList, CommandEmpty, CommandGroup, CommandItem } from '@/components/ui/command';
import { TagsInput, TagsInputItem, TagsInputItemText, TagsInputItemDelete, TagsInputInput } from '@/components/ui/tags-input';
import { ref, defineModel, computed, defineProps } from 'vue';
import { useAxiosRepo } from '@pinia-orm/axios';
import { useQuery } from '@tanstack/vue-query';
import { api } from '@/plugins/api';
import { useAuth } from '@/auth';
import { User } from '@/models';

const { user, organization } = useAuth();
const userRepo = useAxiosRepo(User).setAxios(api);

useQuery({
    queryKey: ['users', organization.id],
    queryFn: async () => await userRepo.api().get('/users/')
});

const open = ref(false);
const searchTerm = ref('');

const props = defineProps({
    excludeSelf: { type: Boolean, default: false }
})

const selectedUsers = defineModel({ type: Array, required: false, default: () => [] })
const filteredUsers = computed(() => {
    const exclude = [...selectedUsers.value.map(m => m.id)]
    if (props.excludeSelf) {
        exclude.push(user.id)
    }
    return userRepo.whereNotIn('id', exclude).get()
});
</script>

<template>
    <TagsInput class="px-0 gap-0" :model-value="selectedUsers">
        <div class="flex gap-2 flex-wrap items-center px-3">
            <TagsInputItem v-for="item in selectedUsers" :key="item.id" :value="item">
                <TagsInputItemText>{{ item.name }}</TagsInputItemText>
                <TagsInputItemDelete />
            </TagsInputItem>
        </div>
        <ComboboxRoot :filter-function="(_val, term) => {
            return filteredUsers.filter(m => m.name.toLowerCase().includes(term?.toLowerCase() || ''))
        }" v-model="selectedUsers" v-model:open="open" v-model:search-term="searchTerm" class="w-full z-50">
            <ComboboxAnchor as-child>
                <ComboboxInput placeholder="Search members..." as-child>
                    <TagsInputInput class="w-full px-3" :class="selectedUsers.length > 0 ? 'mt-2' : ''"
                        @keydown.enter.prevent />
                </ComboboxInput>
            </ComboboxAnchor>
            <ComboboxPortal>
                <ComboboxContent :portal-container="portalContainer" class="z-[9999] relative" :side="'bottom'"
                    :align="'start'" :sticky="'partial'">
                    <CommandList position="popper"
                        class="w-[--radix-popper-anchor-width] rounded-md mt-2 border bg-popover text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
                        <CommandEmpty>No members found</CommandEmpty>
                        <CommandGroup>
                            <CommandItem v-for="member in filteredUsers" :key="member.id" :value="member"
                                @select.prevent="(ev) => {
                                    if (ev.detail.value instanceof User) {
                                        searchTerm = '';
                                        selectedUsers.push(ev.detail.value);
                                    }
                                    if (filteredUsers.length === 0) {
                                        open = false;
                                    }
                                }">
                                {{ member.name }}
                            </CommandItem>
                        </CommandGroup>
                    </CommandList>
                </ComboboxContent>
            </ComboboxPortal>
        </ComboboxRoot>
    </TagsInput>
</template>