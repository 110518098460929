<script setup>
import { defineProps } from 'vue';
import { cn } from '@/lib/utils'
import { useSidebar } from '.'

const props = defineProps({
  class: { type: String, default: '' },
})
const { open } = useSidebar()
</script>

<template>
  <main :class="cn(
    'relative flex min-h-svh flex-1 flex-col bg-sidebar transition-all ease-linear duration-200',
    'peer-data-[variant=inset]:max-h-[calc(100svh-theme(spacing.4))] peer-data-[variant=inset]:min-h-[calc(100svh-theme(spacing.4))] md:peer-data-[variant=inset]:m-2 md:peer-data-[state=collapsed]:peer-data-[variant=inset]:ml-2 md:peer-data-[variant=inset]:ml-0 md:peer-data-[variant=inset]:rounded-2xl md:peer-data-[variant=inset]:shadow-none',
    open && 'peer-data-[variant=inset]:max-w-[calc(100svw-theme(spacing.2)-var(--sidebar-width))]',
    !open && 'peer-data-[variant=inset]:max-w-[calc(100svw-theme(spacing.8)-var(--sidebar-width-icon))]',
    props.class,
  )">
    <slot />
  </main>
</template>
