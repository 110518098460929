<script setup>
import { MainLayout, NavbarLayout, HeaderLayout } from '@/layouts';
import { Compass, Gavel, Scale } from 'lucide-vue-next';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage, BreadcrumbLink, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { Case, Consolidation, useRepo } from '@/models';
import { useMin } from 'pinia-orm/helpers'
import { onMounted, ref, computed, watch, nextTick } from 'vue';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { debounce } from 'lodash-es';
import { Skeleton } from '@/components/ui/skeleton'
import { useRoute } from 'vue-router';
import { PreviewWall } from '@/tmp/components/preview';

const route = useRoute();
const consolidationRepo = useRepo(Consolidation);
const caseRepo = useRepo(Case);

const path = computed(() => {
    if (route.params.type === 'regulations') {
        return '/regulations/consolidations/recent';
    }
    return `/${route.params.type}/`;
});

const repo = computed(() => {
    if (route.params.type === 'regulations') {
        return consolidationRepo;
    }
    return caseRepo;
});

const timeKey = computed(() => {
    if (route.params.type === 'regulations') {
        return 'modified';
    }
    return 'decision_date';
});

const selectedTab = ref('all');

const page = ref(0);
const pageSize = 50;
const before = ref(new Date());
const prefetchQueue = ref([]); // Track prefetched pages
const isLoading = ref(false);
const isPrefetching = ref(false);
const prefetchThreshold = 3; // Number of pages to prefetch

// Add a new Map to cache filtered results
const filteredCaseCache = ref(new Map());

// Add these refs for pagination
const visibleItems = ref(pageSize);  // Initial number of items to show
const loadingMore = ref(false);
const hasMoreItems = computed(() => {
    return visibleItems.value < filteredCaseCache.value.get(selectedTab.value).length;
});

async function fetchCases(prefetch = false, pageNumber = null) {
    const currentPage = pageNumber ?? (prefetch ? Math.max(...prefetchQueue.value, page.value) + 1 : page.value);

    const response = await repo.value.api().get(path.value, {
        params: {
            before: before.value,
            limit: pageSize,
            offset: currentPage * pageSize,
        },
        dataTransformer: (response) => {
            return response.data.map(item => {
                item.id = item.uid;
                return item;
            });
        }
    });

    if (response.entities.length < pageSize) {
        const minDecisionDate = useMin(repo.value.all(), timeKey.value);
        before.value = minDecisionDate !== 0 ? minDecisionDate : new Date() - 30 * 24 * 60 * 60 * 1000;
        page.value = 0;
        return false;
    } else {
        if (!prefetch) {
            page.value++;
        } else {
            prefetchQueue.value.push(currentPage);
        }
        // Clear the filtered cache after new data is fetched
        filteredCaseCache.value.clear();
        hasMoreItems.value = true;
        return true;
    }
}

async function prefetchNextPages() {
    if (isPrefetching.value) return;

    isPrefetching.value = true;
    try {
        // Create an array of promises for parallel prefetching
        const prefetchPromises = Array.from({ length: prefetchThreshold }, (_, i) => {
            const nextPage = Math.max(...prefetchQueue.value, page.value) + i + 1;
            return fetchCases(true, nextPage);
        });

        // Execute all prefetch requests in parallel
        await Promise.all(prefetchPromises);
    } finally {
        isPrefetching.value = false;
    }
}

const debouncedHandleScroll = debounce(async () => {
    if (loadingMore.value) return;
    if (hasMoreItems.value) {
        await loadMoreItems();
    } else if (!isLoading.value) {
        isLoading.value = true;
        try {
            await Promise.all([
                fetchCases(),
                prefetchNextPages()
            ]);
        } finally {
            isLoading.value = false;
        }
    }
}, 150);

onMounted(async () => {
    await Promise.all([
        fetchCases(),
        prefetchNextPages()
    ]);
});

const memoizedAreasOfLaw = computed(() => {
    const cases = repo.value.all();
    if (!cases.length) return {};

    return Object.entries(
        cases.reduce((counts, caseItem) => {
            caseItem.areas_of_law?.forEach(area => {
                counts[area.name] = (counts[area.name] || 0) + 1;
            });
            return counts;
        }, {})
    )
        .sort(([, a], [, b]) => b - a)
        .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
        }, {});
});

// Optimize filteredCases computed property
const filteredCases = computed(() => {
    const currentTab = selectedTab.value;

    // Return cached result if available
    const cacheKey = currentTab;
    if (!filteredCaseCache.value.has(cacheKey)) {
        const cases = repo.value.useCache().orderBy(timeKey.value, 'desc').get();
        const result = currentTab === 'all'
            ? cases
            : cases.filter(item =>
                item.areas_of_law.some(area => area.name === currentTab)
            );
        filteredCaseCache.value.set(cacheKey, result);
    }

    // Get the full filtered result
    const allItems = filteredCaseCache.value.get(cacheKey);

    // Return only the visible portion
    return allItems.slice(0, visibleItems.value);
});

// Add function to load more items
const loadMoreItems = async () => {
    if (loadingMore.value || !hasMoreItems.value) return;

    loadingMore.value = true;
    try {
        await nextTick();
        visibleItems.value += pageSize;  // Load 20 more items
    } finally {
        loadingMore.value = false;
    }
};

// Watch for tab changes to pre-compute filters
watch(selectedTab, (newTab) => {
    // Pre-compute next filter in next tick to avoid blocking UI
    nextTick(() => {
        if (!filteredCaseCache.value.has(newTab)) {
            const cases = repo.value.useCache().orderBy(timeKey.value, 'desc').get();
            const result = newTab === 'all'
                ? cases
                : cases.filter(item =>
                    item.areas_of_law.some(area => area.name === newTab)
                );
            filteredCaseCache.value.set(newTab, result);
        }
    });
});

// Reset visible items when changing tabs
watch(selectedTab, () => {
    visibleItems.value = pageSize;
    hasMoreItems.value = true;
});

const isLoadingData = computed(() => {
    return repo.value.all().length === 0;
});
</script>

<template>
    <Tabs v-model="selectedTab">
        <MainLayout>
            <template #navbar>
                <NavbarLayout>
                    <template #content>
                        <Breadcrumb>
                            <BreadcrumbList>
                                <BreadcrumbItem>
                                    <BreadcrumbLink>
                                        <router-link class="flex flex-row space-x-2 items-center"
                                            :to="{ name: 'explore' }">
                                            <Compass />
                                            <span>Explore</span>
                                        </router-link>
                                    </BreadcrumbLink>
                                </BreadcrumbItem>
                                <BreadcrumbSeparator />
                                <BreadcrumbItem>
                                    <BreadcrumbPage class="flex flex-row space-x-2 items-center">
                                        <template v-if="route.params.type === 'cases'">
                                            <Gavel />
                                            <span>Case Law</span>
                                        </template>
                                        <template v-else-if="route.params.type === 'regulations'">
                                            <Scale />
                                            <span>Laws & Regulations</span>
                                        </template>
                                    </BreadcrumbPage>
                                </BreadcrumbItem>
                            </BreadcrumbList>
                        </Breadcrumb>
                    </template>
                </NavbarLayout>
            </template>
            <template #header>
                <HeaderLayout>
                    <template #left-actions>
                        <TabsList class="bg-transparent border-none space-x-1">
                            <TabsTrigger
                                class="text-muted-foreground bg-muted rounded-3xl text-sm font-semibold px-2 data-[state=active]:shadow-none data-[state=active]:border-none data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                                value="all">
                                <span>All</span>
                            </TabsTrigger>
                            <template v-if="isLoadingData">
                                <TabsTrigger v-for="n in 7" :key="n"
                                    class="text-muted-foreground bg-muted rounded-3xl text-sm font-semibold px-2 data-[state=active]:shadow-none data-[state=active]:border-none data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                                    disabled as-child>
                                    <Skeleton class="w-12 h-full" />
                                </TabsTrigger>
                            </template>
                            <TabsTrigger v-for="area in Object.keys(memoizedAreasOfLaw)" :key="area"
                                class="text-muted-foreground bg-muted rounded-3xl text-sm font-semibold px-2 data-[state=active]:shadow-none data-[state=active]:border-none data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                                :value="area">
                                <span>{{ area }}</span>
                            </TabsTrigger>
                        </TabsList>
                    </template>
                </HeaderLayout>
            </template>
            <template #content>
                <PreviewWall :items="filteredCases" :is-loading="isLoading" :type="route.params.type"
                    @scroll-end="debouncedHandleScroll" />
            </template>
        </MainLayout>
    </Tabs>
</template>