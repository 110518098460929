<script setup>
import { Run } from '../components'
import { CommandInput, Shortcuts, ShortcutsButton } from '@/tmp/components/command-input'
import { Loading } from '@/components';
import { useThread } from '../composables'
import { useTemplate } from '@/tmp/services/templates'
import { onMounted, nextTick, computed, ref } from 'vue'
import { debounce } from 'lodash'
import { MainLayout, NavbarLayout } from '@/layouts'
import { ResourceBreadcrumb } from '@/tmp/components/resources'
import { useRoute } from 'vue-router'

const route = useRoute()

const templateId = computed(() => route.query?.templateId || null)
const { thread, isLoading, isRunning, startRun } = useThread(true)
const { template } = useTemplate('prompts', templateId)
const shortcut = ref(null)
const shortcutOpen = ref(false)
const scrollToBottom = debounce(() => {
    nextTick(() => {
        const container = document.getElementById('container')
        if (!container) return

        const isAtBottom = container.scrollHeight - container.scrollTop <= 1

        // Only scroll if not already at bottom
        if (!isAtBottom) {
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth'
            })

            // Backup scroll with small delay to handle dynamic content
            setTimeout(() => {
                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: 'smooth'
                })
            }, 100)
        }
    })
}, 150)

onMounted(() => {
    scrollToBottom()
})
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <ResourceBreadcrumb :folder-id="thread?.folder_id" :project-id="thread?.project_id"
                        :resource="thread" />
                </template>
            </NavbarLayout>
        </template>
        <template #content>
            <Loading class="h-full" v-if="isLoading" />
            <div v-else-if="thread?.runs.length > 0" class="flex flex-col h-full">
                <div class="flex flex-col flex-grow space-y-4 bg-transparent w-[40svw] mx-auto"
                    :class="{ 'overflow-hidden 2xl:overflow-visible': shortcutOpen && !shortcut }">
                    <Run v-for="(run, i) in thread?.runs" :key="run.id" :run="run" :i="i" :thread-id="thread.id"
                        :is-last="i == thread?.runs.length - 1" @stream="scrollToBottom" @submit="startRun" />
                </div>
                <ShortcutsButton v-if="!isLoading && thread?.runs.length > 0" v-model:shortcut="shortcut"
                    v-model:isOpen="shortcutOpen" class="sticky bottom-0 justify-end w-[40svw] mx-auto"
                    :types="['prompts']" />
            </div>
            <div v-else class="flex flex-col h-full">
                <div class="flex-grow flex-col">
                    <Shortcuts v-model:shortcut="shortcut" :default-shortcut="template"
                        class="mx-auto max-w-[40svw] flex-grow justify-end" :types="['prompts']" />

                </div>
            </div>
        </template>
        <template #footer>
            <div class="bg-background">
                <div class="mx-auto">
                    <div v-if="false" class="relative overflow-y-auto">
                        <ShortcutsButton v-if="!isLoading && thread?.runs.length > 0" v-model:shortcut="shortcut"
                            v-model:isOpen="shortcutOpen" class="max-h-[50vh] max-w-[40svw] mx-auto "
                            :types="['prompts']" />
                    </div>
                    <div class="pb-2 max-w-[40svw] mx-auto">
                        <CommandInput v-if="!isLoading && thread" v-model:shortcut="shortcut"
                            v-model:isOpen="shortcutOpen" :is-running="isRunning" disable-shortcuts
                            @submit="(e) => { startRun(e); shortcutOpen = false }" />
                    </div>
                </div>
            </div>
        </template>
    </MainLayout>
</template>