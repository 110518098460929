<script setup>
import { useTemplate } from '../composables'
import { defineProps, toRefs, defineEmits } from 'vue'
import { Shortcut } from '@/tmp/components/shortcuts'
import { Grid2x2, File, Text } from 'lucide-vue-next'
import { useSheetTemplate } from '@/tmp/services/sheets/composables'

const props = defineProps({
    type: {
        type: String,
        required: true
    },
    templateId: {
        type: String,
        required: true
    }
})

const emit = defineEmits(['apply'])
const { type, templateId } = toRefs(props)
const { template, isLoading } = useTemplate(type, templateId)
if (type.value === 'sheets') {
    useSheetTemplate(template)
}
</script>

<template>
    <Shortcut v-if="!isLoading" @click="emit('apply', template)" :name="template.name"
        :description="template.description?.split('.')[0]">
        <Grid2x2 v-if="type === 'sheets'" class="text-green-500" />
        <template v-else-if="type === 'prompts'">
            <File v-if="template.min_documents > 0" class="text-blue-500" />
            <Text v-else class="text-yellow-500" />
        </template>
    </Shortcut>
</template>