<script setup>
import { computed, defineProps } from "vue";
import { TagsInputItemDelete, useForwardProps } from "radix-vue";
import { X } from "lucide-vue-next";
import { cn } from "@/lib/utils";

const props = defineProps({
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  class: { type: null, required: false },
});

const delegatedProps = computed(() => {
  const { ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <TagsInputItemDelete
    v-bind="forwardedProps"
    :class="cn('flex rounded bg-backgroundSecondary mr-1 mt-1', props.class)"
  >
    <slot>
      <X class="text-muted-foreground hover:text-foreground items-center rounded-lg w-4 h-4" />
    </slot>
  </TagsInputItemDelete>
</template>
