<script setup>
import {
    EditableRoot,
    EditableEditTrigger,
    Primitive
} from 'radix-vue';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuSeparator,
    DropdownMenuGroup,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
    DropdownMenuPortal,
    DropdownMenuLabel
} from '@/components/ui/dropdown-menu';
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
    ContextMenuSeparator,
    ContextMenuGroup,
    ContextMenuSub,
    ContextMenuSubTrigger,
    ContextMenuSubContent,
    ContextMenuLabel
} from '@/components/ui/context-menu';
import {
    FolderPlus,
    PenLine,
    Copy,
    FolderOutput,
    Trash2,
    UserPlus,
    Save,
    Download,
    Text,
    Layout,
    Plus,
    CopyPlus,
    FileUp,
    FolderUp
} from 'lucide-vue-next';
import {
    ref,
    defineModel,
    defineProps,
    toValue,
    watch,
    defineEmits
} from 'vue';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { LibraryTableDialog } from '@/tmp/services/library';
import { IconPicker } from '@/tmp/components/controls';
import * as icons from 'lucide-vue-next';
import { debounce } from 'lodash';
import { useRouter } from 'vue-router';
import { useRepo, Thread, Sheet, PromptTemplate, SheetTemplate } from '@/models';
import { TemplateDialog } from '@/tmp/services/templates';

const router = useRouter()

const props = defineProps({
    variant: {
        type: String,
        default: 'dropdown'
    },
    editableIcon: {
        type: Boolean,
        default: false
    },
    editableName: {
        type: Boolean,
        default: false
    },
    onCopy: {
        type: Function,
        default: null
    },
    onMove: {
        type: Function,
        default: null
    },
    onDelete: {
        type: Function,
        required: false,
        default: null
    },
    onShare: {
        type: Function,
        default: null
    },
    onSaveAsTemplate: {
        type: Function,
        default: null
    },
    onDownload: {
        type: Function,
        default: null
    },
    onCreate: {
        type: Function,
        default: null
    },
    onUpload: {
        type: Function,
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    },
    item: {
        type: Object,
        required: false,
        default: null
    },
    projectId: {
        type: String,
        required: false,
        default: null
    },
    folderId: {
        type: String,
        required: false,
        default: null
    }
});

if (!['dropdown', 'context'].includes(props.variant)) {
    throw new Error('Invalid variant');
}

const name = defineModel('name');
const icon = defineModel('icon');
const color = defineModel('color', { default: 'primary' });
const iconConfig = ref({ icon: toValue(icon), color: toValue(color) });

const fileInput = ref(null);
const folderInput = ref(null);


const emitChangeIconDebounced = debounce(() => {
    emit('change-icon');
}, 2000);

watch(iconConfig, () => {
    icon.value = iconConfig.value.icon;
    color.value = iconConfig.value.color;
    emitChangeIconDebounced.cancel();
    emitChangeIconDebounced();
}, { deep: true });

const emit = defineEmits(['rename', 'change-icon']);



const threadRepo = useRepo(Thread)
const sheetRepo = useRepo(Sheet)

async function createThread(template = null) {
    const response = await threadRepo.api().post('/threads/', {
        name: template?.name || 'New Thread',
        project_id: props.projectId || null,
        folder_id: props.folderId || null
    })
    const routeConfig = {
        name: response.entities[0].project_id ? 'project-thread' : 'thread',
        params: { threadId: response.entities[0].id, projectId: response.entities[0].project_id },
    }
    if (template) {
        routeConfig.query = { templateId: template.id }
    }
    router.push(routeConfig)
}

async function createSheet(template = null) {
    const response = await sheetRepo.api().post('/sheets/', {
        title: 'New Sheet',
        project_id: props.projectId || null,
        folder_id: props.folderId || null,
        prompts: template?.prompts || null
    })
    const routeConfig = {
        name: response.entities[0].project_id ? 'project-sheet' : 'sheet',
        params: { sheetId: response.entities[0].id, projectId: response.entities[0].project_id },
    }
    router.push(routeConfig)
}

async function applyTemplate(template) {
    if (template instanceof PromptTemplate) {
        await createThread(template)
    } else if (template instanceof SheetTemplate) {
        await createSheet(template)
    }
}
</script>


<template>
    <component :is="props.variant === 'dropdown' ? DropdownMenu : ContextMenu">
        <component :is="editableName ? EditableRoot : Primitive" @submit="emit('rename')" activation-mode="none"
            submit-mode="enter" v-model="name" as-child>
            <component :is="props.variant === 'dropdown' ? DropdownMenuTrigger : ContextMenuTrigger"
                :disabled="disabled" as-child>
                <slot />
            </component>
            <component :is="props.variant === 'dropdown' ? DropdownMenuContent : ContextMenuContent">
                <template v-if="editableName || editableIcon">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuLabel : ContextMenuLabel">
                        <IconPicker v-if="editableIcon" v-model="iconConfig" />
                        <component v-else :is="icons[icon]" :class="`text-${color}`" class="h-full" />
                        <span>{{ name }}</span>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onCreate">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component @click="onCreate('folder')"
                            :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                            <FolderPlus />
                            <span>New folder</span>
                        </component>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="editableName || onCopy || onMove">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <EditableEditTrigger as-child v-if="editableName">
                            <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                <PenLine />
                                <span>Rename</span>
                            </component>
                        </EditableEditTrigger>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onCopy">
                            <Copy />
                            <span>Duplicate</span>
                        </component>
                        <LibraryTableDialog v-if="onMove" @submit="onMove" :default-project-id="projectId"
                            :default-folder-id="folderId">
                            <component @click.prevent.stop @select.prevent
                                :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                <FolderOutput />
                                <span>Move to</span>
                            </component>
                            <template #footer>
                                <div class="flex items-center gap-1">
                                    <span class="text-sm text-muted-foreground">Select a destination for:</span>
                                    <component :is="icons[icon]" :class="`text-${color}`" class="h-4" />
                                    <span class="text-sm">{{ name }}</span>
                                </div>
                            </template>
                        </LibraryTableDialog>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onUpload">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            @click="fileInput.click()">
                            <FileUp />
                            <span>Upload files</span>
                        </component>
                        <input type="file" ref="fileInput" @change="onUpload" class="hidden"
                            accept="pdf,docx,doc,txt,md" multiple />
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            @click="folderInput.click()">
                            <FolderUp />
                            <span>Upload folder</span>
                        </component>
                        <input type="file" ref="folderInput" @change="onUpload" class="hidden"
                            accept="pdf,docx,doc,txt,md" webkitdirectory directory multiple />
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onShare || onSaveAsTemplate || onDownload">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onShare">
                            <UserPlus />
                            <span>Share</span>
                        </component>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onSaveAsTemplate">
                            <Save />
                            <span>Save as template</span>
                        </component>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onDownload">
                            <Download />
                            <span>Download</span>
                        </component>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onCreate">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component :is="props.variant === 'dropdown' ? DropdownMenuSub : ContextMenuSub">
                            <component @click="onCreate('thread')"
                                :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem" as-child>
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubTrigger : ContextMenuSubTrigger">
                                    <Text />
                                    <span>Threads</span>
                                </component>
                            </component>
                            <component :is="props.variant === 'dropdown' ? DropdownMenuPortal : 'div'">
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubContent : ContextMenuSubContent">
                                    <component @click="onCreate('thread')"
                                        :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                        <Plus />
                                        <span>Empty thread</span>
                                    </component>
                                    <TemplateDialog @apply="applyTemplate" :types="['prompts']">
                                        <component @select.prevent
                                            :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                            <CopyPlus />
                                            <span>From template</span>
                                        </component>
                                    </TemplateDialog>
                                </component>
                            </component>
                        </component>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuSub : ContextMenuSub">
                            <component @click="onCreate('sheet')"
                                :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem" as-child>
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubTrigger : ContextMenuSubTrigger">
                                    <Layout />
                                    <span>Sheets</span>
                                </component>
                            </component>
                            <component :is="props.variant === 'dropdown' ? DropdownMenuPortal : 'div'">
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubContent : ContextMenuSubContent">
                                    <component @click="onCreate('sheet')"
                                        :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                        <Plus />
                                        <span>Empty sheet</span>
                                    </component>
                                    <TemplateDialog @apply="applyTemplate" :types="['sheets']">
                                        <component @select.prevent
                                            :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                            <CopyPlus />
                                            <span>From template</span>
                                        </component>
                                    </TemplateDialog>
                                </component>
                            </component>
                        </component>
                    </component>
                    <component v-if="onDelete"
                        :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup" v-if="onDelete">
                    <AlertDialog>
                        <AlertDialogTrigger as-child>
                            <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                                class="text-destructive" @select.prevent>
                                <Trash2 />
                                <span>Delete</span>
                            </component>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This action cannot be undone. This will permanently delete the item.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction @click="onDelete">Delete</AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </component>
            </component>
        </component>
    </component>
</template>
