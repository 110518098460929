import { Model } from 'pinia-orm'
import { Document } from '@/models/Document'
import { Prompt } from '@/models/Prompt'
import { ModelRun } from '@/models/Run'
import { DateCast } from '@/models/utils'
import { Project } from '@/models/Project'
import { User } from '@/models/User'
import { Folder } from '@/models/Folder'
import { Activity } from '@/models/Activity'

export class SheetDocument extends Model {
    static entity = 'sheet_documents'

    static fields() {
        return {
            id: this.attr(null),
            sheet_id: this.attr(null),
            index: this.number(0),
            document_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
        }
    }
}

export class SheetPrompt extends Model {
    static entity = 'sheet_prompts'

    static fields() {
        return {
            id: this.attr(null),
            sheet_id: this.attr(null),
            index: this.number(0),
            prompt_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
        }
    }
}

export class SheetTemplatePrompt extends Model {
    static entity = 'sheet_template_prompts'

    static fields() {
        return {
            id: this.attr(null),
            sheet_template_id: this.attr(null),
            index: this.number(0),
            prompt_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
        }
    }
}

export class SheetTemplate extends Model {
    static entity = 'sheet_templates'

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            description: this.string(''),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            default: this.boolean(false),
            stub: this.string(null),

            // Relationships
            rawPrompts: this.belongsToMany(Prompt, SheetTemplatePrompt, 'sheet_template_id', 'prompt_id'),
            owner: this.belongsTo(User, 'user_id'),
        }
    }

    get prompts() {
        return this.rawPrompts?.sort((a, b) => a.pivot.index - b.pivot.index) || []
    }

    set prompts(prompts) {
        this.rawPrompts = prompts
    }

    get background() {
        function uuidToSeed(uuid) {
            return uuid.split('-')
                .map(part => parseInt(part.substring(0, 8), 16))
                .reduce((acc, val) => acc + val, 0);
        }
        
        // Simple PRNG using a seed
        function seededRandom(seed) {
            let x = Math.sin(seed) * 10000;
            return x - Math.floor(x);
        }
        
        // Function to generate a smooth pastel color using a seed
        function getSeededPastelColor(seed) {
            // Expanded range for vibrant yet harmonious pastels
            const hue = (seededRandom(seed) * 180 + 15) % 360; // Range between 15-195 for wider color spectrum
            const saturation = (seededRandom(seed + 1) * 30 + 40); // 40-70% saturation for punchier colors
            const lightness = (seededRandom(seed + 2) * 20 + 75); // 75-95% lightness for more dynamic pastels
            const alpha = (seededRandom(seed + 3) * 0.25 + 0.35); // 0.35-0.6 alpha for bolder transparency variation
        
            return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
        }

        const seed = uuidToSeed(this.id); // Convert UUID to numeric seed
        const topLeft = getSeededPastelColor(seed);
        const topRight = getSeededPastelColor(seed + 3);
        const bottomRight = getSeededPastelColor(seed + 6);
        const bottomLeft = getSeededPastelColor(seed + 9);

        // Generate random background gradient with pastel colors using template ID as seed
        const backgroundGradient = `
            radial-gradient(at 0 0, ${topLeft}, transparent 50%),
            radial-gradient(at 100% 0, ${topRight}, transparent 50%),
            radial-gradient(at 100% 100%, ${bottomRight}, transparent 50%),
            radial-gradient(at 0 100%, ${bottomLeft}, transparent 50%)
        `;
        
        return {
            background: `${backgroundGradient},
                url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.99' numOctaves='1' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)' opacity='0.3'/%3E%3C/svg%3E")`,
            backgroundBlendMode: 'overlay, soft-light',
            opacity: '0.9'
        };
    }
}

export class Sheet extends Model {
    static entity = 'sheets'

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            description: this.string(''),
            folder_id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            settings: this.attr({}),
            project_id: this.attr(null),
            icon: this.string('Grid2x2'),
            color: this.string('emerald-500'),

            // Relationships
            rawDocuments: this.belongsToMany(Document, SheetDocument, 'sheet_id', 'document_id'),
            rawPrompts: this.belongsToMany(Prompt, SheetPrompt, 'sheet_id', 'prompt_id'),
            runs: this.hasMany(ModelRun, 'sheet_id'),
            project: this.belongsTo(Project, 'project_id'),
            owner: this.belongsTo(User, 'user_id'),
            parent: this.belongsTo(Folder, 'folder_id'),
            activity: this.hasMany(Activity, 'resource_id')
        }
    }

    get isRunning() {
        return this.prompts?.length > 0 && this.documents?.length > 0 && this.progress !== 100
    }

    get progress() {
        if (!this?.documents?.length || !this?.prompts?.length) return 0
        const totalCells = this.documents.length * this.prompts.length
        const completedCells = this.documents.flatMap(doc =>
            this.prompts.map(prompt => this.cell(prompt, doc))
        ).filter(cell => cell?.status === 'success').length
        return Math.round((completedCells / totalCells) * 100)
    }

    get activity_at() {
        return new Date(Math.max(...this.activity.map(activity => activity.created_at), this.created_at))
    }

    get documents() {
        return this.rawDocuments?.sort((a, b) => a.pivot.index - b.pivot.index) || []
    }

    get prompts() {
        return this.rawPrompts?.sort((a, b) => a.pivot.index - b.pivot.index) || []
    }

    set documents(documents) {
        this.rawDocuments = documents
    }

    set prompts(prompts) {
        this.rawPrompts = prompts
    }

    get parent() {
        return this.folder;
    }

    set parent(parent) {
        this.folder = parent;
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }

    cell(prompt, document) {
        return this.runs.find(run => run.prompt_id === prompt.id && run.document_ids.includes(document.id))
    }
}
