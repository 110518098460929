import { CollectionService } from '../service';
import { computed } from 'vue';

export function useCollection(collectionId) {
    const {
        collection,
        isLoading: isCollectionLoading,
        isFetching: isCollectionFetching,
        isError: isCollectionError,
        error: collectionError
    } = CollectionService.getCollection(collectionId)
    const {
        bookmarks,
        isLoading: isBookmarksLoading,
        isFetching: isBookmarksFetching,
        isError: isBookmarksError,
        error: bookmarksError
    } = CollectionService.getCollectionBookmarks(collectionId)

    const isLoading = computed(() => isCollectionLoading.value || isBookmarksLoading.value)
    const isFetching = computed(() => isCollectionFetching.value || isBookmarksFetching.value)
    const isError = computed(() => isCollectionError.value || isBookmarksError.value)
    const error = computed(() => collectionError.value || bookmarksError.value)

    async function addToCollection(bookmark) {
        await CollectionService.addToCollection({ collection, bookmark })
    }

    async function removeFromCollection(bookmark) {
        await CollectionService.removeFromCollection({ collection, bookmark })
    }

    async function deleteCollection() {
        await CollectionService.deleteCollection(collection)
    }

    async function saveCollection() {
        await CollectionService.updateCollection(collection)
    }

    return {
        collection,
        bookmarks,
        isLoading,
        isFetching,
        isError,
        error,
        addToCollection,
        removeFromCollection,
        deleteCollection,
        saveCollection
    }
}
