<script setup>
import { PromptPopover } from '@/tmp/components/prompt'
import { Button } from '@/components/ui/button'
import { PlusIcon, ListCheck, LetterText } from 'lucide-vue-next'
import { defineModel } from 'vue'
import { Loading } from '@/components'
import { useAuth } from '@/auth'
import { useSheetTemplate } from '../composables'
import SheetTemplateInfo from './SheetTemplateInfo.vue'
const { organization } = useAuth()
const template = defineModel()

const { isLoading, createPrompt, updatePrompt, deletePrompt } = useSheetTemplate(template)
</script>

<template>
    <Loading v-if="isLoading" />
    <div v-else class="flex flex-col gap-2 h-full overflow-y-auto">
        <div class="flex items-center justify-between space-x-2">
            <SheetTemplateInfo :template="template" />
            <PromptPopover v-if="!(template.default && template.organization_id !== organization.id)"
                @submit="createPrompt">
                <Button variant="outline" size="sm" class="flex flex-row items-center gap-2">
                    <PlusIcon class="size-3" :stroke-width="2.5" />
                    <p class="text-sm">Add columns</p>
                </Button>
            </PromptPopover>
        </div>
        <PromptPopover v-for="prompt in template.prompts" :prompt="prompt" @submit="updatePrompt" @delete="deletePrompt"
            :key="prompt.id" :disabled="template.default && template.organization_id !== organization.id">
            <div class="flex flex-col space-y-1 bg-muted hover:bg-accent transition-colors items-start p-2 rounded-lg">
                <div class="flex items-center justify-between space-x-2">
                    <LetterText v-if="prompt.format === 'text'" class="size-3 text-primary" />
                    <ListCheck v-else-if="prompt.format === 'boolean'" class="size-3 text-primary" />
                    <p class="text-sm text-primary">
                        {{ prompt.label }}
                    </p>
                </div>
                <p class="text-xs text-muted-foreground">
                    {{ prompt.content }}
                </p>
            </div>
        </PromptPopover>
    </div>
</template>