import { Model } from 'pinia-orm';
import { DateCast } from '@/models/utils';
import { User } from '@/models/User';
import { Project } from '@/models/Project';
import { Collection } from '@/models/Collection';
import { Document } from '@/models/Document';
import { Thread } from '@/models/Thread';
import { Sheet } from '@/models/Sheet';

export class Activity extends Model {
    static entity = 'activities'
    static fields() {
        return {
            id: this.attr(null),
            organization_id: this.attr(null),
            actor_id: this.attr(null),
            actor_type: this.string(null),
            action: this.string(null),
            project_id: this.attr(null),
            resource_type: this.string(null),
            resource_id: this.attr(null),
            details: this.attr({}),
            system_metadata: this.attr({}),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            project: this.belongsTo(Project, 'project_id'),
            user: this.belongsTo(User, 'actor_id'),
            resource_project: this.belongsTo(Project, 'resource_id'),
            resource_collection: this.belongsTo(Collection, 'resource_id'),
            resource_document: this.belongsTo(Document, 'resource_id'),
            resource_thread: this.belongsTo(Thread, 'resource_id'),
            resource_sheet: this.belongsTo(Sheet, 'resource_id')
        }
    }

    get resource() {
        return (
            this.resource_project ||
            this.resource_collection ||
            this.resource_document ||
            this.resource_thread ||
            this.resource_sheet
        )
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}