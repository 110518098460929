<script setup>
import { ref, nextTick, defineModel } from 'vue';
import { Input } from '@/components/ui/input';
import { Search } from 'lucide-vue-next';

const query = defineModel()

const isVisible = ref(false)
const inputRef = ref(null)

const showAndFocus = () => {
    if (isVisible.value) return
    isVisible.value = true

    nextTick(() => {
        if (inputRef.value) {
            inputRef.value.focus()
        }
    })
}

const hideIfEmpty = () => {
    if (!query.value) {
        isVisible.value = false
    }
}
</script>

<template>
    <div class="flex items-center gap-2">
        <div class="flex items-center gap-2">
            <div class="relative flex items-center">
                <Search @click="showAndFocus"
                    class="absolute left-2 z-10 h-4 w-4 text-muted-foreground transition-colors duration-200"
                    :class="{ 'cursor-pointer hover:text-primary': !isVisible }" />
                <transition enter-active-class="transition-all duration-200 ease-linear"
                    leave-active-class="transition-all duration-200 ease-linear" enter-from-class="opacity-0 w-0"
                    enter-to-class="opacity-100 w-[10rem]" leave-from-class="opacity-100 w-[10rem]"
                    leave-to-class="opacity-0 w-0">
                    <Input v-if="isVisible" ref="inputRef" type="search" v-model="query" placeholder="Search..."
                        class="h-7 pl-8 rounded-xl" @blur="hideIfEmpty" />
                </transition>
            </div>
        </div>
    </div>
</template>