<script setup>
import { cn } from "@/lib/utils";
import { defineProps } from "vue";
const props = defineProps({
  class: { type: null, required: false },
});
</script>

<template>
  <td :class="cn('p-4 text-left align-middle text-sm [&:has([role=checkbox])]:pr-0 [&_svg]:size-4', props.class)">
    <slot />
  </td>
</template>