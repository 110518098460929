<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import { ToastRoot, useForwardPropsEmits } from 'radix-vue'
import { toastVariants } from '.'
import { cn } from '@/lib/utils'

const props = defineProps({
  variant: {
    type: String,
    default: 'default'
  },
  class: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['update:open'])

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  console.log(_)
  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <ToastRoot v-bind="forwarded" :class="cn(toastVariants({ variant }), props.class)" @update:open="onOpenChange">
    <slot />
  </ToastRoot>
</template>
