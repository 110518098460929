import { useRepo, Project } from '@/models';
import { useQuery } from '@tanstack/vue-query';
import { computed, toValue } from 'vue';

export function getProject(projectId) {
    const { isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['project', projectId],
        queryFn: async () => {
            const response = await useRepo(Project).api().get(`/projects/${toValue(projectId)}`);
            return response.entities[0];
        },
    });
    return { 
        project: computed(() => useRepo(Project).withAllRecursive().find(toValue(projectId))),
        isLoading,
        isFetching,
        isError,
        error
    };
}

export async function updateProject(project) {
    await useRepo(Project).api().patch(
        `/projects/${toValue(project).id}`,
        toValue(project)
    );
    return useRepo(Project).withAllRecursive().find(toValue(project).id);
}

export async function deleteProject(project) {
    await useRepo(Project).api().delete(
        `/projects/${toValue(project).id}`,
        { delete: toValue(project).id }
    );
}

export async function createProject({ name, description = null, icon = null, color = null, members = [] }) {
    const response = await useRepo(Project).api().post('/projects/', {
        name,
        description,
        icon,
        color,
        acl: {
            write: members?.map(m => m.id) || [],
        },
    });
    return useRepo(Project).withAllRecursive().find(response.entities[0].id);
}

export function getProjects() {
    const { isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['projects'],
        queryFn: async () => {
            const response = await useRepo(Project).api().get('/projects/');
            return response.entities;
        },
    });
    return { projects: computed(() => useRepo(Project).withAllRecursive().get()), isLoading, isFetching, isError, error };
}

export const ProjectService = {
    getProject,
    updateProject,
    deleteProject,
    getProjects,
    createProject
};