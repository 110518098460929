import { getBookmark, addToCollection as addToCollectionService, removeFromCollection as removeFromCollectionService } from '../service';

export function useBookmark(bookmarkId) {
    const {
        bookmark,
        isLoading,
        isFetching,
        isError,
        error
    } = getBookmark(bookmarkId)

    async function addToCollection(collection) {
        await addToCollectionService({ collection, bookmark })
    }

    async function removeFromCollection() {
        await removeFromCollectionService({ collection: bookmark.collection, bookmark })
    }

    return {
        bookmark,
        isLoading,
        isFetching,
        isError,
        error,
        addToCollection,
        removeFromCollection
    }
}