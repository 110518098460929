<script setup>
import { computed, defineProps } from "vue";
import { DropdownMenuLabel, useForwardProps } from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps({
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  class: { type: null, required: false },
  inset: { type: Boolean, required: false },
});

const delegatedProps = computed(() => {
  // eslint-disable-next-line no-unused-vars
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <DropdownMenuLabel v-bind="forwardedProps" :class="cn('px-2 py-1.5 text-sm font-semibold flex flex-row items-center [&_svg]:size-4 [&_svg]:shrink-0 [&_svg]:mr-3 [&_svg]:stroke-[2.5]', inset && 'pl-8', props.class)
    ">
    <slot />
  </DropdownMenuLabel>
</template>
