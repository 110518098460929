<script setup>
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage } from '@/components/ui/breadcrumb';
import { MainLayout, NavbarLayout, HeaderLayout } from '@/layouts';
import { CommandInput } from '@/tmp/components/command-input';
import { HomeIcon } from 'lucide-vue-next';
import { useRepo, Thread } from '@/models';
import { useAuth } from '@/auth';
import { useRouter } from 'vue-router'
import { useSocket } from '@/composables'
import { VideoPreview } from '@/tmp/components/preview';
import { AspectRatio } from '@/components/ui/aspect-ratio';
import { Shortcuts } from '@/tmp/components/command-input';
import { ref } from 'vue'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"

const router = useRouter()
const { user } = useAuth();
const threadRepo = useRepo(Thread)
const socket = useSocket('threads')
const shortcut = ref(null)

const videoDialogOpen = ref(false)

async function handleSubmit(data) {
    const response = await threadRepo.api().post('/threads/', {
        name: data?.name || 'New Thread'
    })
    const threadId = response.entities[0].id
    while (!socket.isConnected) await new Promise(resolve => setTimeout(resolve, 100));
    let inRoom = false
    await socket.emit('enter_thread', { thread_id: threadId }, () => inRoom = true)
    while (!inRoom) await new Promise(resolve => setTimeout(resolve, 100));
    const runResponsePromise = new Promise(resolve => {
        const handleRunResponse = (response) => {
            socket.off('run', handleRunResponse);
            resolve(response);
        };
        socket.on('run', handleRunResponse);
    });
    await socket.emit('run', {
        thread_id: threadId,
        query: data.text,
        documents: data.attachments.map(attachment => attachment.id),
        focus: data.sources?.focus || null,
        areas_of_law: data.sources?.areas_of_law || null,
        validity_date: data.sources?.validity_date || null
    })
    await runResponsePromise
    router.push({ name: 'thread', params: { threadId } })
}
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbPage class="flex flex-row space-x-2 items-center hidden">
                                    <HomeIcon />
                                    <span>Home</span>
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #header>
            <HeaderLayout class="mx-auto max-w-[40svw] ">
                <template #name>
                    <div class="flex flex-col">
                        <h1 class="pl-1 py-2 text-4xl font-black ">
                            <span class="text-primary/90 font-primary">
                                {{ new Date().getHours() < 12 ? 'Good morning' : new Date().getHours() < 18
                                    ? 'Good afternoon' : 'Good evening' }} </span>, {{ user.first_name }}<span
                                        class="text-primary">.</span>
                        </h1>
                        <p class="text-lg pl-1 text-center text-muted-foreground font-semibold">How can I assist you
                            <strong>today</strong>?
                        </p>
                    </div>
                </template>
            </HeaderLayout>
        </template>
        <template #content>
            <div class="mx-auto max-w-[40svw] py-2 h-full flex flex-col">
                <div class="flex-col flex space-y-2">
                    <div class="flex flex-row gap-2">
                        <p class="text-base text-sm px-2 font-semibold">Getting started</p>
                    </div>
                    <div class="flex flex-row pb-4 gap-2">
                        <VideoPreview title="How to use the thread" description="Learn how to use the thread"
                            video-id="DfF7SJ9XEfI">
                            <template #preview>
                                <div class="w-full">
                                    <AspectRatio :ratio="16 / 9">
                                        <img src="@/assets/images/thread_tutorial_placeholder.jpeg"
                                            alt="Video thumbnail" class="object-cover w-full h-full rounded-lg" />
                                    </AspectRatio>
                                </div>
                            </template>
                        </VideoPreview>
                        <VideoPreview title="How to use the sheet review"
                            description="Learn how to bulk review in tabular format" video-id="ZyliN5psraU">
                            <template #preview>
                                <div class="w-full">
                                    <AspectRatio :ratio="16 / 9">
                                        <img src="@/assets/images/sheet_tutorial_placeholder.jpeg" alt="Video thumbnail"
                                            class="object-cover w-full h-full rounded-lg" />
                                    </AspectRatio>
                                </div>
                            </template>
                        </VideoPreview>
                    </div>
                </div>
                <div class="flex-grow">
                    <Shortcuts v-model:shortcut="shortcut" class="justify-end" />
                </div>
            </div>


            <Dialog v-model:open="videoDialogOpen">
                <DialogContent class="sm:max-w-[800px]">
                    <DialogHeader>
                        <DialogTitle>Zeno Basics</DialogTitle>
                    </DialogHeader>
                    <div class="aspect-video w-full">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/fxDAyUiXphg"
                            title="Zeno Basics" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </DialogContent>
            </Dialog>
        </template>
        <template #footer>
            <div class="mx-auto max-w-[40svw] pb-2">
                <CommandInput v-model:shortcut="shortcut" disable-shortcuts @submit="handleSubmit" />
            </div>
        </template>
    </MainLayout>
</template>