import { ref, computed } from 'vue'

export function useSelectable(items) {
    const selectedItems = ref([])
    const lastSelected = ref(null)

    function toggleSelect(item, event) {
        const isCtrlPressed = event.metaKey || event.ctrlKey
        const isShiftPressed = event.shiftKey
    
        if (isShiftPressed && lastSelected.value) {
            const currentIndex = items.value.findIndex(i => i.id === item.id)
            const lastIndex = items.value.findIndex(i => i.id === lastSelected.value.id)
            const [start, end] = [Math.min(currentIndex, lastIndex), Math.max(currentIndex, lastIndex)]
            
            // Get the range of items and combine with existing selection
            const rangeItems = items.value.slice(start, end + 1)
            selectedItems.value = [...new Set([...selectedItems.value, ...rangeItems])]
            lastSelected.value = item
        } else if (isCtrlPressed) {
            const isItemSelected = selectedItems.value.some(i => i.id === item.id)
            if (isItemSelected) {
                selectedItems.value = selectedItems.value.filter(i => i.id !== item.id)
            } else {
                selectedItems.value = [...selectedItems.value, item]
            }
            lastSelected.value = item
        } else if (item !== lastSelected.value) {
            selectedItems.value = [item]
            lastSelected.value = item
        } else {
            selectedItems.value = []
            lastSelected.value = null
        }
    }

    function toggleSelectAll() {
        if (isAllSelected.value === true) {
            selectedItems.value = []
        } else {
            selectedItems.value = items.value
        }
    }

    function isSelected(item) {
        return selectedItems.value.includes(item)
    }

    const isAllSelected = computed(() => {
        if (items.value.length === 0) {
            return false
        }
        if (items.value.every(item => isSelected(item))) {
            return true
        }
        if (selectedItems.value.length > 0) {
            return 'indeterminate'
        }
        return false
    })

    return {
        selectedItems,
        toggleSelect,
        toggleSelectAll,
        isSelected,
        isAllSelected
    }
}