<script setup>
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import TemplateDetail from './TemplateDetail.vue';
import TemplateList from './TemplateList.vue';
import { ref, defineProps, defineEmits } from "vue";

const selectedTemplate = ref(null);
const open = ref(false);
const emit = defineEmits(['apply'])

defineProps({
    types: {
        type: Array,
        required: false,
        default: () => ['prompts', 'sheets']
    },
    onSaveAsTemplate: {
        type: Function,
        required: false
    }
})

const handleApply = (template) => {
    emit('apply', template)
    open.value = false
    selectedTemplate.value = null
}
</script>

<template>
    <Dialog v-model:open="open">
        <DialogTrigger as-child>
            <slot />
        </DialogTrigger>
        <DialogContent
            class="w-[95vw] max-w-[1200px] h-[90vh] max-h-[700px] min-h-[500px] overflow-y-auto flex flex-col">
            <TemplateList v-if="!selectedTemplate" v-model="selectedTemplate" :types="types" @apply="handleApply"
                @save-as-template="onSaveAsTemplate" />
            <TemplateDetail v-else v-model="selectedTemplate" @apply="handleApply"
                @save-as-template="onSaveAsTemplate" />
        </DialogContent>
    </Dialog>
</template>
