<script setup>
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { ChevronDown } from 'lucide-vue-next'
import { RangeCalendar } from '@/components/ui/range-calendar'
import { defineModel, defineProps, ref, defineExpose, computed } from 'vue'
import { getLocalTimeZone, today } from '@internationalized/date'

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: 'Updated'
    }
})

const todayDate = today(getLocalTimeZone())
const last7DaysDate = todayDate.subtract({ days: 7 })
const last30DaysDate = todayDate.subtract({ days: 30 })
const thisWeekDate = todayDate.subtract({ days: todayDate.dayOfWeek - 1 })
const thisMonthDate = todayDate.set({ day: 1 })

const dateRange = defineModel({ default: { start: null, end: null } })
const selectedDateFilter = ref('Anytime')

const displayValue = computed(() => {
    if (selectedDateFilter.value === 'Anytime') return props.label
    if (selectedDateFilter.value === 'Custom') {
        if (dateRange.value.end) {
            return `${dateRange.value.start} - ${dateRange.value.end}`
        } else {
            return `${dateRange.value.start} - ${todayDate}`
        }
    }
    return selectedDateFilter.value
})

const options = [
    { label: 'Anytime', value: { start: null, end: null } },
    { label: 'Today', value: { start: todayDate, end: null } },
    { label: 'Last 7 Days', value: { start: last7DaysDate, end: null } },
    { label: 'Last 30 Days', value: { start: last30DaysDate, end: null } },
    { label: 'This Week', value: { start: thisWeekDate, end: null } },
    { label: 'This Month', value: { start: thisMonthDate, end: null } },
    { label: 'Custom' }
]

defineExpose({
    displayValue
})
</script>

<template>
    <Popover>
        <PopoverTrigger asChild>
            <slot>
                <Button variant="outline" size="sm" class="flex items-center gap-2">
                    <span>{{ displayValue }}</span>
                    <ChevronDown class="size-4 shrink-0 text-muted-foreground" />
                </Button>
            </slot>
        </PopoverTrigger>
        <PopoverContent class="p-0">
            <div class="p-2 space-y-1">
                <Button v-for="option in options" :key="option.label" variant="ghost" size="sm"
                    class="w-full justify-start" :class="{ 'bg-accent': selectedDateFilter === option.label }" @click="() => {
                        selectedDateFilter = option.label
                        if (option?.value) {
                            dateRange = option.value
                        }
                    }">
                    {{ option.label.charAt(0).toUpperCase() + option.label.slice(1) }}
                </Button>
            </div>
            <RangeCalendar v-if="selectedDateFilter === 'Custom'" v-model="dateRange" class="rounded-t-none border-t" />
        </PopoverContent>
    </Popover>
</template>