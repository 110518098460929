<script setup>
import { Stepper, StepperItem, StepperSeparator, StepperTitle, StepperIndicator } from '@/components/ui/stepper'
import { defineProps, toRefs, computed } from 'vue'

const props = defineProps(['plan'])
const { plan } = toRefs(props)

const currentIndex = computed(() => {
    return steps.value.findIndex(step => step.status === 'active') + 1 || steps.value.length + 1
})

const steps = computed(() => {
    if (!plan.value?.steps?.length) {
        return [
            {
                step: 1,
                title: 'Thinking',
                status: 'active'
            }
        ]
    }
    return plan.value.steps.map((step, index) => ({
        step: index + 1,
        title: step.step,
        status: {
            'running': 'active',
            'pending': 'pending',
            'success': 'completed',
            'error': 'error'
        }[step.status]
    }))
})
</script>

<template>
    <Stepper :default-value="currentIndex" orientation="vertical"
        class="justify-start items-center flex max-w-full flex-col">
        <StepperItem v-for="step in steps" :key="step.step" class="relative flex w-full items-start" :step="step.step">
            <StepperSeparator v-if="step.step !== steps[steps.length - 1].step"
                class="absolute left-[5px] top-[17px] block h-[90%] w-0.5 shrink-0 rounded-full bg-muted group-data-[state=completed]:bg-primary/90" />
            <StepperIndicator
                class="w-3 h-3 mt-1.5 group-data-[state=active]:animate-pulse group-data-[state=active]:bg-primary/90 group-data-[state=completed]:bg-primary/90 bg-muted-foreground">
                <div />
            </StepperIndicator>
            <StepperTitle
                class="max-w-[95%] text-left font-medium prose whitespace-normal break-words group-data-[state=active]:text-primary group-data-[state=active]:animate-pulse group-data-[state=completed]:text-primary text-muted-foreground">
                {{ step.title }}
            </StepperTitle>
        </StepperItem>
    </Stepper>
</template>
