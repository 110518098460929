<script setup>
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import { defineModel, defineProps, defineEmits, computed, watch } from 'vue'
import { ArrowRight, Square, Loader } from 'lucide-vue-next';
import Attachments from './Attachments.vue'
import Sources from './Sources.vue'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import ShortcutsButton from './ShortcutsButton.vue'
import { Dropzone } from '@/components/dropzone'
import { LibraryService } from '@/tmp/services/library'
import { Document, Folder, useRepo } from '@/models'
import { toast } from 'vue-sonner'

const emit = defineEmits(['submit', 'cancel'])
const props = defineProps({
    disableShortcuts: { type: Boolean, default: false },
    shortcutTypes: { type: Array, default: () => ['prompts', 'sheets', 'other'] },
    shortcutsDefaultOpen: { type: Boolean, default: false },
    defaultShortcut: { type: Object, default: null },
    isRunning: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Ask me anything' },
    shortcutsAlwaysOpen: { type: Boolean, default: false },
})

const text = defineModel('text', { type: String, default: '' })
const attachments = defineModel('attachments', { type: Array, default: () => [] })
const sources = defineModel('sources', { type: Object, default: () => { } })
const shortcut = defineModel('shortcut', { type: Object, default: null })
const shortcutOpen = defineModel('shortcutOpen', { type: Boolean, default: false })

function submit(event) {
    if (props.isRunning || !text.value.trim() || (event.shiftKey && event.key === 'Enter')) return
    emit('submit', {
        text: text.value,
        attachments: attachments.value,
        sources: sources.value,
        name: shortcut?.value?.name || null
    })
    shortcutOpen.value = false
    text.value = ''
    attachments.value = []
    sources.value = {}
    shortcut.value = null
}

const flattenDocuments = async (items) => {
    const flattened = []
    for (const item of items) {
        if (item.entity === 'document' || item instanceof Document) {
            flattened.push(item)
        } else if (item.entity === 'folder' || item instanceof Folder) {
            const folder = useRepo(Folder).find(item.id)
            const children = await LibraryService.getChildren(folder)
            flattened.push(...await flattenDocuments(children))
        }
    }
    return flattened
}

async function handleDrop(event) {
    if (!event) return
    try {
        toast.info('Adding attachments...')
        const items = JSON.parse(event.dataTransfer?.getData('text/plain'))
        // Process folders recursively to extract all documents
        const documents = await flattenDocuments(items)
        if (documents.length > 0) {
            if (!attachments.value) attachments.value = []
            const nonDuplicateItems = documents.filter(item => !attachments.value.some(i => i.id === item.id))
            const newCount = nonDuplicateItems.length

            if (newCount > 0) {
                attachments.value = [...new Set([...attachments.value, ...nonDuplicateItems])]
                toast.success(`Successfully added ${newCount} attachment${newCount === 1 ? '' : 's'}`)
            } else {
                toast.info('No new attachments to add')
            }
        } else {
            toast.info('No attachments found')
        }
    } catch (error) {
        console.error(error)
        toast.error('Failed to add attachments')
    }
}

const disableSend = computed(() => {
    if (shortcut?.value?.max_documents !== null && shortcut?.value?.max_documents !== undefined) {
        if (attachments.value.length > shortcut.value.max_documents) return true
        if (shortcut?.value?.min_documents && attachments.value.length < shortcut.value.min_documents) return true
    } else if (shortcut?.value?.min_documents && attachments.value.length < shortcut.value.min_documents) {
        return true
    }
    return false
})

watch(shortcut, (newShortcut) => {
    if (newShortcut) {
        text.value = newShortcut.content
    } else {
        text.value = ''
    }
})
</script>

<template>
    <div :class="cn(['bg-background', $attrs.class])">
        <ShortcutsButton v-if="!disableShortcuts" v-model:isOpen="shortcutOpen" v-model:shortcut="shortcut"
            :default-shortcut="defaultShortcut" :types="shortcutTypes" :always-open="shortcutsAlwaysOpen"
            :default-open="shortcutsDefaultOpen" />
        <Dropzone v-slot="{ handleDragEnter, handleDragOver }" @drop="handleDrop"
            class="flex flex-col w-full p-2 bg-accent rounded-3xl h-fit">
            <Textarea @dragstart="handleDragEnter" @drop="handleDrop" @dragover="handleDragOver"
                class="min-h-[50px] h-fit w-full bg-accent text-primary my-2 outline-none shadow-none rounded-lg px-3.5 text-md resize-none"
                id="textarea" v-model="text" @keydown.enter="submit($event)" :placeholder="placeholder" />
            <div class="flex justify-between items-center">
                <div class="flex items-center space-x-1">
                    <Attachments v-model="attachments" :max_documents="shortcut?.max_documents"
                        :min_documents="shortcut?.min_documents" />
                    <Sources v-model="sources" />
                </div>
                <TooltipProvider :delayDuration="0">
                    <Tooltip>
                        <TooltipTrigger>
                            <Button v-if="!isRunning" variant="ghost" size="icon"
                                class="rounded-full hover:bg-background/90 border-none hover:outline-none hover:border-none"
                                @click.prevent="submit($event)" :disabled="disableSend">
                                <ArrowRight :stroke-width="2.3" />
                            </Button>
                            <Button v-else size="icon"
                                class="rounded-full h-8 w-8 mr-1 hover:bg-black border-none hover:outline-none hover:border-none"
                                @click.prevent="$emit('cancel', $event)">
                                <Square v-if="false" class="h-3.5 w-3.5" :stroke-width="0" fill="white" />
                                <Loader v-else class="h-3.5 w-3.5 animate-spin text-white" :stroke-width="4" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent v-if="disableSend">
                            <p v-if="attachments?.length < shortcut?.min_documents">
                                Minimum {{ shortcut?.min_documents }} document(s) required
                            </p>
                            <p v-else>
                                Please ensure you meet the required conditions for this use case
                            </p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </div>
        </Dropzone>
    </div>
</template>