<script setup>
import { Button } from '@/components/ui/button'
import { SaveIcon } from 'lucide-vue-next'
import { ref, defineModel, defineEmits } from 'vue'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import { useAuth } from '@/auth'

const { organization } = useAuth()
const template = defineModel()

const canSave = ref(false)

const editor = useEditor({
    content: template.value?.content || '',
    extensions: [
        StarterKit,
        Placeholder.configure({
            placeholder: 'Write your instructions for Zeno here...',
        }),
    ],
    editable: !(template.value.default && template.value.organization_id !== organization.id),
    editorProps: {
        attributes: {
            class: 'prose prose-sm focus:outline-none max-w-none',
        },
    },
    onUpdate: ({ editor }) => {
        canSave.value = editor.getText() !== template.value.content
    },
})

const emit = defineEmits(['save'])

const save = () => {
    template.value.content = editor.value.getText()
    emit('save', template)
    canSave.value = false
}
</script>

<template>
    <div class="flex flex-col gap-4">
        <div class="border rounded-lg p-4">
            <editor-content :editor="editor" class="min-h-[200px]" />
        </div>

        <div v-if="!(template.default && template.organization_id !== organization.id)" class="flex items-center gap-2">
            <Button @click="save" :disabled="!canSave" variant="outline" size="sm">
                <SaveIcon class="w-4 h-4 mr-2" />
                Save
            </Button>
            <!-- <Button variant="outline" size="sm">
                <PlusIcon class="w-4 h-4 mr-2" />
                Add Variable
            </Button> -->
        </div>
    </div>
</template>

<style>
.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
}

.ProseMirror {
    >*+* {
        margin-top: 0.75em;
    }
}
</style>