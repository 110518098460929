<script setup>
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Play } from 'lucide-vue-next';
import { defineProps, ref } from 'vue';
import { cn } from '@/lib/utils';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"

const dialogOpen = ref(false)

defineProps({
    title: {
        type: String,
        required: false
    },
    description: {
        type: String,
        required: false
    },
    size: {
        type: String,
        required: false,
        default: 'full'
    },
    videoId: {
        type: String,
        required: false
    }
})
</script>

<template>
    <Card @click="dialogOpen = true"
        :class="cn('group relative w-full border-gray-50/50 hover:bg-accent cursor-pointer transition-all duration-200 bg-card rounded-3xl', $attrs.class, { 'h-36': size === 'sm', 'h-64': size === 'lg', 'h-full': size === 'full' })">
        <div class="absolute inset-0 hidden group-hover:flex items-center justify-center z-10">
            <div class="flex flex-col gap-2">
              
                <Button variant="ghost" size="icon" class="hover:bg-transparent bg-transparent border-none">
                    <Play fill="currentColor" class="text-primary" />
                </Button>
            </div>
        </div>

        <div class="group-hover:blur-sm transition-all duration-200 justify-between h-full">
            <CardContent v-if="$slots.preview" class="rounded-t-3xl pt-4 pb-0 -mb-2">
                <slot name="preview"></slot>
            </CardContent>
            <CardHeader class="pt-4 pb-4">
                <CardTitle class="line-clamp-1 text-left text-sm font-semibold hover:text-primary cursor-pointer">
                    {{ title }}
                </CardTitle>
                <CardDescription class="line-clamp-2 text-left text-xs text-muted-foreground/80">
                    {{ description }}
                </CardDescription>
            </CardHeader>
        </div>
    </Card>

    <Dialog v-model:open="dialogOpen">
        <DialogContent class="sm:max-w-[800px]">
            <DialogHeader>
                <DialogTitle>{{ title }}</DialogTitle>
            </DialogHeader>
            <div class="aspect-video w-full">
                <iframe
                    v-if="videoId"
                    width="100%"
                    height="100%"
                    :src="`https://www.youtube.com/embed/${videoId}`"
                    :title="title"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </DialogContent>
    </Dialog>
</template>