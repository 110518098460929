<script setup>
import { MainLayout, NavbarLayout } from '@/layouts';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage, BreadcrumbLink, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { Search, Gavel, Scale } from 'lucide-vue-next';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useSearch } from '../composables';
import { PreviewWall } from '@/tmp/components/preview';


const route = useRoute();
const query = computed(() => route.query.q);
const type = computed(() => route.params.type === 'regulations' ? 'consolidations' : route.params.type);
const { results, fetch, isLoading } = useSearch({ query, type });

async function getNextPage() {
    if (route.query.q.trim()) {
        await fetch();
    }
}

</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbLink class="flex flex-row space-x-2 items-center" as-child>
                                    <RouterLink :to="{ name: 'results', query: route.query }">
                                        <Search />
                                        <span>Search</span>
                                    </RouterLink>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbPage class="flex flex-row space-x-2 items-center">
                                    <template v-if="route.params.type === 'cases'">
                                        <Gavel />
                                        <span>Cases</span>
                                    </template>
                                    <template v-else-if="route.params.type === 'regulations'">
                                        <Scale />
                                        <span>Laws & Regulations</span>
                                    </template>
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #content>
            <PreviewWall :items="results" :type="route.params.type" :is-loading="isLoading" @scroll-end="getNextPage" />
        </template>
    </MainLayout>
</template>