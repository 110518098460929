import { getTemplates, createTemplate as createTemplateService, updateTemplate, deleteTemplate } from '../service'

export function useTemplates(type) {
    const { templates, isLoading, isFetching, isError, error } = getTemplates(type)
    return {
        templates,
        isLoading,
        isFetching,
        isError,
        error,
        createTemplate: ({ name = "New template", description = null } = {}) => createTemplateService({ type, name, description }),
        updateTemplate,
        deleteTemplate
    }
}