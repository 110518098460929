import { useAxiosRepo } from '@pinia-orm/axios';
import { Thread, Review, Sheet, Project, Collection, ProjectCollection } from '@/models';
import { api } from '@/plugins/api';
import { useResource } from '@/resource';

export function useProject(withEffects = false) {
    // Repos
    const threadRepo = useAxiosRepo(Thread).setAxios(api);
    const reviewRepo = useAxiosRepo(Review).setAxios(api);
    const sheetRepo = useAxiosRepo(Sheet).setAxios(api);
    const collectionRepo = useAxiosRepo(Collection).setAxios(api);
    const projectCollectionRepo = useAxiosRepo(ProjectCollection).setAxios(api);

    // Pre-fetch handler for loading related data
    const preFetch = withEffects ? async (newProject) => {
        await Promise.all([
            threadRepo.api().get(`/projects/${newProject.id}/threads`),
            reviewRepo.api().get(`/projects/${newProject.id}/reviews`),
            sheetRepo.api().get(`/projects/${newProject.id}/sheets`),
            collectionRepo.api().get(`/projects/${newProject.id}/attached-collections`),
            projectCollectionRepo.api().get(`/projects/${newProject.id}/project-collections`)
        ]);
    } : null;

    const {
        resource: project,
        refreshResource: refreshProject,
        deleteResource: deleteProject,
        saveResource: saveProject,
        isLoading
    } = useResource(
        Project,
        'projectId',
        preFetch
    );

    const getActivities = () => {
        if (!project) {
            console.log('No project found');
            return [];
        }
        
        const threads = threadRepo.query().withAllRecursive().where('project_id', project.id).get();
        const reviews = reviewRepo.query().withAllRecursive().where('project_id', project.id).get();
        const sheets = sheetRepo.query().withAllRecursive().where('project_id', project.id).get();
    
        const filtered = [...threads, ...reviews, ...sheets]
        
        return filtered.map(item => ({
            ...item,
            type: item instanceof Thread ? 'thread' : item instanceof Review ? 'review' : 'sheet'
        }))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    };

    

    async function updateBody(newBody) {
        if (!project) return;
        project.body = newBody;
        await saveProject(500);
    }

    return {
        project,
        isLoading,
        getActivities,
        updateBody,
        refreshProject,
        deleteProject,
        saveProject
    };
} 