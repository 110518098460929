<script setup>
import { cn } from "@/lib/utils";
import { defineProps } from "vue";
const props = defineProps({
  class: { type: null, required: false },
});
</script>

<template>
  <th :class="cn(
    'h-11 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 [&_svg]:size-4',
    props.class,
  )
    ">
    <slot />
  </th>
</template>
