<script setup>
import { computed, defineProps } from "vue";
import { ContextMenuSubTrigger, useForwardProps } from "radix-vue";
import { ChevronRight } from "lucide-vue-next";
import { cn } from "@/lib/utils";

const props = defineProps({
  disabled: { type: Boolean, required: false },
  textValue: { type: String, required: false },
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  class: { type: null, required: false },
  inset: { type: Boolean, required: false },
});

const delegatedProps = computed(() => {
  // eslint-disable-next-line no-unused-vars
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <ContextMenuSubTrigger v-bind="forwardedProps" :class="cn(
    'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground',
    inset && 'pl-8',
    props.class,
  )
    ">
    <slot />
    <ChevronRight class="!mr-0 text-muted-foreground ml-auto h-4 w-4" />
  </ContextMenuSubTrigger>
</template>
