<script setup>
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { Paperclip, File } from 'lucide-vue-next'
import { useRoute } from 'vue-router'
import { ref, defineModel, computed, defineProps } from 'vue'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { LibraryTableDialog } from '@/tmp/services/library/'
import { useRepo, Document, Folder } from '@/models'
import { toast } from 'vue-sonner'

const props = defineProps({
    max_documents: { type: Number, required: false },
    min_documents: { type: Number, required: false }
})

const attachments = defineModel({ type: Array, default: () => [] })
const isOpen = ref(false)

const disableAttachments = computed(() => {
    if (props.max_documents == 0) return true
    return false
})

const flattenDocuments = (items) => {
    const flattened = []
    for (const item of items) {
        if (item.entity === 'document' || item instanceof Document) {
            flattened.push(item)
        } else if (item.entity === 'folder' || item instanceof Folder) {
            const folder = useRepo(Folder).withAllRecursive().find(item.id)
            flattened.push(...flattenDocuments(folder?.children || []))
        }
    }
    return flattened
}

const confirmSelection = async (selectedItems) => {
    try {
        toast.info('Adding attachments...')

        const flattenedDocuments = flattenDocuments(selectedItems)
        const newCount = flattenedDocuments.length

        attachments.value = flattenedDocuments
        isOpen.value = false

        if (newCount > 0) {
            toast.success(`Successfully added ${newCount} attachment${newCount === 1 ? '' : 's'}`)
        } else {
            toast.info('No attachments selected')
        }
    } catch (error) {
        console.error(error)
        toast.error('Failed to add attachments')
    }
}

const route = useRoute()
</script>

<template>

    <TooltipProvider :delayDuration="0">
        <Tooltip>
            <TooltipTrigger as-child>
                <Button @click="isOpen = true" :disabled="disableAttachments" variant="ghost" size="icon"
                    class="rounded-full hover:bg-background/90 border-none hover:outline-none hover:border-none relative"
                    :class="{ 'bg-background rounded-full shadow-sm': attachments.length || isOpen }">
                    <Paperclip />
                    <Badge v-if="attachments.length" variant="ghost"
                        class="bg-secondary border-none text-white font-bold h-4 w-4 items-center justify-center absolute -right-0.5 -bottom-0.5 z-50">
                        {{ attachments.length }}
                    </Badge>
                </Button>
            </TooltipTrigger>
            <TooltipContent v-if="disableAttachments">
                <p>Attachments are not supported for this use case</p>
            </TooltipContent>
            <TooltipContent v-else-if="attachments.length" class="flex flex-col items-center space-y-2 py-2">
                <span class="text-xs text-muted-foreground text-left w-full">Attachments</span>
                <div v-for="attachment in attachments" :key="attachment.id"
                    class="flex flex-row w-full justify-start items-center">
                    <File class="h-4 w-4 mr-2" />
                    <span>{{ attachment.name }}</span>
                </div>
            </TooltipContent>
            <TooltipContent v-else>
                <p>Attach documents</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>

    <LibraryTableDialog :default-project-id="route.params?.projectId" :default-folder-id="null" v-model:open="isOpen"
        @submit="({ selectedItems }) => confirmSelection(selectedItems)" :default-selected="attachments"
        :filters="{ type: 'documents' }" selectable multiple allow-upload />

</template>