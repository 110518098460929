import { useRepo, PromptTemplate, SheetTemplate } from '@/models';
import { useQuery } from '@tanstack/vue-query';
import { computed, toValue } from 'vue';


function getType(template) {
    if (toValue(template) instanceof PromptTemplate) {
        return 'prompts'
    } else if (toValue(template) instanceof SheetTemplate) {
        return 'sheets'
    }
}

function getModel(template) {
    if (toValue(template) instanceof PromptTemplate) {
        return PromptTemplate
    } else if (toValue(template) instanceof SheetTemplate) {
        return SheetTemplate
    }
}

function getModelFromType(type) {
    return {
        'prompts': PromptTemplate,
        'sheets': SheetTemplate,
    }[toValue(type)]
}

export async function createTemplate({ name, type, description = null, content = null, ...other }) {
    const response = await useRepo(getModelFromType(toValue(type))).api().post(`/${toValue(type)}/templates/`, { 
        name, 
        description,
        content,
        ...other,
        prompts: other?.rawPrompts?.map?.((prompt, index) => ({
            ...prompt,
            index
        }))
    });
    return response.entities[0];
}

export async function updateTemplate(template) {
    const response = await useRepo(getModel(template)).api().patch(
        `/${getType(template)}/templates/${toValue(template).id}`, 
        toValue(template)
    );
    return response.entities[0];
}

export async function deleteTemplate(template) {
    await useRepo(getModel(template)).api().delete(
        `/${getType(template)}/templates/${toValue(template).id}`, 
        { delete: toValue(template).id }
    );
}

export function getTemplate(type, templateId) {
    const { isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['template', type, templateId],
        queryFn: async () => {
            if (!toValue(templateId)) return null;
            const response = await useRepo(getModelFromType(toValue(type))).api().get(`/${toValue(type)}/templates/${toValue(templateId)}`);
            return response.entities[0];
        },
    });
    return { 
        template: computed(() => useRepo(getModelFromType(toValue(type))).withAllRecursive().find(toValue(templateId)) || null), 
        isLoading, 
        isFetching, 
        isError, 
        error 
    };
}

export function getTemplates(type) {
    const { isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['templates', type],
        queryFn: async () => {
            const response = await useRepo(getModelFromType(toValue(type))).api().get(`/${toValue(type)}/templates/`);
            return response.entities;
        },
    });
    return { templates: computed(() => useRepo(getModelFromType(toValue(type))).withAllRecursive().orderBy('default', 'desc').orderBy('stub', 'asc').orderBy('created_at', 'desc').get()), isLoading, isFetching, isError, error };
}

export const TemplateService = {
    getType,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    getTemplate,
    getTemplates
};