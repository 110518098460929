import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'
import { User } from '@/models/User'

export class Prompt extends Model {
    static entity = 'prompts'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            label: this.string(''),
            format: this.string(''),
            content: this.string(''),

            // Relationships
            owner: this.belongsTo(User, 'user_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export class Parameter {
    constructor({
        type = '',
        default: defaultValue = null,
        nargs = 1,
        options = []
    } = {}) {
        this.type = type;
        this.default = defaultValue;
        this.nargs = nargs;
        this.options = options || [];
    }
}

export class PromptTemplate extends Model {
    static entity = 'prompt_templates'

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            description: this.string(null),
            content: this.string(''),
            parameters: this.attr({}),
            format: this.string(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            default: this.boolean(false),
            stub: this.string(null),

            // Relationships
            owner: this.belongsTo(User, 'user_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
