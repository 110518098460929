<script setup>
import { defineProps, toRefs, defineModel, inject } from 'vue'
import { usePosthog } from '@/composables/analytics';
import { ChevronRight, ArrowDownRight } from 'lucide-vue-next'
const { identifyUser } = usePosthog();
const props = defineProps(['sendMessage', 'suggestions'])
const { sendMessage, suggestions } = toRefs(props)
const text = defineModel()
const posthog = inject('posthog');
const handleSuggestionClick = async (suggestion) => {
    text.value = suggestion
    identifyUser()
    posthog.capture('$user_selected_suggestion', { suggestion: suggestion })
    await sendMessage.value()
}

</script>

<template>
    <div class="space-y-2">
        <div class="flex flex-row items-center">
            <ArrowDownRight class="h-6 w-6 mr-1" />
            <span class="text-lg font-semibold text-left">Related</span>
        </div>
        <div class="flex flex-col" v-for="suggestion in suggestions" :key="suggestion">
            <div @click="handleSuggestionClick(suggestion)" class="flex flex-row items-start cursor-pointer group">
                <ChevronRight class="mr-2 h-4 w-4 text-primary group-hover:text-secondary shrink-0 mt-1" />
                <span class="text-left text-primary group-hover:text-secondary">{{ suggestion }}</span>
            </div>
        </div>
    </div>
</template>