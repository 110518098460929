<script setup>
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,

} from '@/components/ui/dropdown-menu';
import { FolderUp, FileUp, Upload } from 'lucide-vue-next';
import { defineProps, ref, toRefs } from 'vue';
import { useUpload } from '@/tmp/composables/use-upload';
const fileInput = ref(null)
const folderInput = ref(null)


const props = defineProps({
    projectId: {
        type: String,
        default: null,
        required: false
    },
    folderId: {
        type: String,
        default: null,
        required: false
    }
})

const { projectId, folderId } = toRefs(props)
const upload = useUpload(projectId, folderId);
</script>

<template>
    <DropdownMenu>
        <DropdownMenuTrigger class="w-full">
            <slot>
                <Button size="sm" class="flex rounded-full items-center gap-2">
                    <Upload class="size-4" />
                    <span>Upload</span>
                </Button>
            </slot>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
            <DropdownMenuItem @click="fileInput.click()">
                <FileUp />
                <span>Upload files</span>
            </DropdownMenuItem>
            <input type="file" ref="fileInput" @change="upload.handleUpload" class="hidden" accept="pdf,docx,doc,txt,md"
                multiple />
            <DropdownMenuItem @click="folderInput.click()">
                <FolderUp />
                <span>Upload folder</span>
            </DropdownMenuItem>
            <input type="file" ref="folderInput" @change="upload.handleUpload" class="hidden"
                accept="pdf,docx,doc,txt,md" webkitdirectory directory multiple />
        </DropdownMenuContent>
    </DropdownMenu>
</template>
