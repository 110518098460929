
import { useRepo, Sheet, Prompt, SheetPrompt, Document, SheetDocument, ModelRun } from '@/models'
import { onMounted, watch, onUnmounted, toValue } from 'vue'
import { useSocket } from '@/composables'
import { SheetService } from '../service'
import { debounce } from 'lodash'

export function useSheetSocket(sheet) {
    const socket = useSocket('sheets')
    let pendingRuns = new Map();

    const processPendingRuns = debounce(() => {
        const runsToSave = Array.from(pendingRuns.values());
        if (runsToSave.length) {
            useRepo(ModelRun).save(runsToSave);
            pendingRuns.clear();
        }
    }, 10);

    onMounted(async () => {
        const promises = [
            socket.on('sheet', (data) => useRepo(Sheet).save(data)),
            socket.on('prompt', (data) => useRepo(Prompt).save(data)),
            socket.on('sheet-prompt', (data) => useRepo(SheetPrompt).save(data)),
            socket.on('document', (data) => useRepo(Document).save(data)),
            socket.on('sheet-document', (data) => useRepo(SheetDocument).save(data)),
            socket.on('run', (data) => {
                if (data.error) throw new Error(data.error)
                if (!data.data) return
                pendingRuns.set(data.data.id, data.data);
                processPendingRuns();
            })
        ]
        if (toValue(sheet)?.id) promises.push(SheetService.enterSheet({ socket, sheet }))
        await Promise.all(promises)
    })

    watch(sheet, async (newSheet, oldSheet) => {
        const newSheetId = toValue(newSheet)?.id
        const oldSheetId = toValue(oldSheet)?.id
        if (newSheetId !== oldSheetId) {
            if (newSheetId) await SheetService.enterSheet({ socket, sheet: newSheet })
            if (oldSheetId) await SheetService.leaveSheet({ socket, sheet: oldSheet })
        }
    }, { immediate: true, deep: true })

    onUnmounted(async () => {
        if (toValue(sheet)?.id) await SheetService.leaveSheet({ socket, sheet })
    })

    return {
        addPrompt: async ({ prompt, index = null }) => await SheetService.addPrompt({ socket, sheet, prompt, index }),
        updatePrompt: async ({ prompt, index = null }) => await SheetService.updatePrompt({ socket, sheet, prompt, index }),
        removePrompt: async ({ promptId }) => await SheetService.removePrompt({ socket, sheet, promptId }),
        addDocument: async ({ documentId, index = null }) => await SheetService.addDocument({ socket, sheet, documentId, index }),
        updateDocument: async ({ documentId, index = null }) => await SheetService.updateDocument({ socket, sheet, documentId, index }),
        removeDocument: async ({ documentId }) => await SheetService.removeDocument({ socket, sheet, documentId }),
        startRun: async ({ status = 'incomplete', promptIds = null, documentIds = null }) => await SheetService.startRun({ socket, sheet, status, promptIds, documentIds }),
        cancelRun: async ({ promptIds = null, documentIds = null }) => await SheetService.cancelRun({ socket, sheet, promptIds, documentIds }),
        applyTemplate: async (template) => await SheetService.applyTemplate({ socket, sheet, template }),
    }
}