import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'
import { ModelRun } from '@/models/Run'
import { Folder } from '@/models/Folder'
import { Project } from '@/models/Project'
import { User } from '@/models/User'
import { Activity } from '@/models/Activity'

export class Comment extends Model {
    static entity = 'comments'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            content: this.string(''),
            selection: this.string(''),
            replacement: this.string(null),
            status: this.string('pending'),
            severity: this.string('medium'),
            review_id: this.attr(null),
            sheet_id: this.attr(null),
            run_id: this.attr(null),
            document_id: this.attr(null),
            document_part_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            document: this.belongsTo(Document, 'document_id'),
            document_part: this.belongsTo(DocumentPart, 'document_part_id'),
            run: this.belongsTo(ModelRun, 'run_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}


export class DocumentPart extends Model {
    static entity = 'document_parts'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            document_id: this.attr(null),
            page_content: this.string(''),
            headings: this.attr({}),
            index: this.number(0),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            document: this.belongsTo(Document, 'document_id'),
            comments: this.hasMany(Comment, 'document_part_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export class Document extends Model {
    static entity = 'documents'

    static fields() {
        return {
            id: this.attr(null),
            title: this.string(''),
            content_type: this.string(''),
            upload_status: this.string('pending'),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            folder_id: this.attr(null),
            project_id: this.attr(null),
            tags: this.attr([]),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            icon: this.string('File'),
            color: this.string('blue-500'),

            // Relationships
            project: this.belongsTo(Project, 'project_id'),
            folder: this.belongsTo(Folder, 'folder_id'),
            parts: this.hasMany(DocumentPart, 'document_id'),
            comments: this.hasMany(Comment, 'document_id'),
            owner: this.belongsTo(User, 'user_id'),
            activity: this.hasMany(Activity, 'resource_id')
        }
    }

    get activity_at() {
        return new Date(Math.max(...this.activity.map(activity => activity.created_at), this.created_at))
    }

    get display() {
        return this.preview
    }

    get preview() {
        return {
            title: this.title,
            description: this.content_type,
            summary: this.content ? this.content.slice(0, 250) + '...' : 'No summary available',
            author: this.owner?.name,
            tags: this.tags
        }
    }

    get content() {
        return this.parts?.map(part => part.page_content).join('\n')
    }

    get name() {
        return this.title
    }

    set name(value) {
        this.title = value
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
