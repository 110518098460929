<script setup>
import { MainLayout, NavbarLayout, HeaderLayout } from '@/layouts';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage } from '@/components/ui/breadcrumb';
import { ResourceItem, ResourceIcon, ResourceName, ResourceDescription } from '@/tmp/components/resources';
import { useRoute, useRouter } from 'vue-router';
import { computed, h } from 'vue';
import { ChevronDown, Circle, Rss, ArrowUpRight } from 'lucide-vue-next';
import { SelectOptionButton } from '@/tmp/components/controls';
import { ProjectActions } from '@/tmp/services/projects';
import { LibraryTable } from '@/tmp/services/library';
import { Button } from '@/components/ui/button';
import { useProject } from '../composables';
import { Loading } from '@/components';

const router = useRouter();
const route = useRoute();
const projectId = computed(() => route.params?.projectId || null);
const { project, isLoading, saveProject } = useProject(projectId)

async function updateProjectStatus(status) {
    project.value.status = status
    await saveProject()
}
</script>

<template>
    <Loading v-if="isLoading" class="h-full" />
    <MainLayout v-else>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <ResourceItem :item="project" action-menu="dropdown">
                                    <BreadcrumbPage class="flex flex-row space-x-2 items-center cursor-pointer">
                                        <ResourceIcon />
                                        <ResourceName />
                                        <ChevronDown class="size-4 shrink-0 text-muted-foreground" />
                                    </BreadcrumbPage>
                                </ResourceItem>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #header>
            <HeaderLayout>
                <template #description>
                    <ResourceDescription class="pl-0.5" v-model="project.description" @save="saveProject" />
                </template>
                <template v-if="project" #left-actions>
                    <SelectOptionButton :modelValue="project.status" @update:modelValue="updateProjectStatus"
                        placeholder="Status" :options="[
                            { value: 'active', label: 'Active', icon: h(Circle, { class: 'size-3 shrink-0 text-green-500', fill: 'currentColor' }) },
                            { value: 'completed', label: 'Completed', icon: h(Circle, { class: 'size-3 shrink-0 text-blue-500', fill: 'currentColor' }) }
                        ]" />
                </template>
            </HeaderLayout>
        </template>
        <template #content>
            <div class="flex flex-col space-y-6 mx-auto">
                <ProjectActions class="px-2.5" />
                <div class="flex flex-col">
                    <div class="flex flex-row justify-between items-center px-2.5 pb-4">
                        <div class="flex flex-row justify-start items-center">
                            <Rss class="text-primary mr-2 w-5 h-5" />
                            <span class="text-left text-lg text-primary font-semibold">Activity</span>
                        </div>
                        <Button @click="router.push({ name: 'project-documents', params: { projectId: projectId } })"
                            size="sm" variant="link" class="justify-start text-muted-foreground gap-2">
                            <span>Show all</span>
                            <ArrowUpRight class="w-4 h-4" />
                        </Button>
                    </div>
                    <LibraryTable :project-id="projectId" selectable multiple />
                </div>
            </div>
        </template>
    </MainLayout>
</template>