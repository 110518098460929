<script setup>
import { defineProps, toRefs, defineExpose, computed } from 'vue'
import { useLibrary } from '../composables'
import { SidebarMenuSubItem, SidebarMenuSub } from '@/components/ui/sidebar';
import { Loader2 } from 'lucide-vue-next';
import LibraryTreeItem from './LibraryTreeItem.vue';
import { useRepo, Project } from '@/models';
import { DragTarget } from '@/tmp/components/behaviour/draggable';
const props = defineProps({
    projectId: {
        type: String,
        required: false,
        default: null
    },
    folderId: {
        type: String,
        required: false,
        default: null
    },
    placeholder: {
        type: String,
        required: false,
        default: 'This folder is empty'
    },
    subButton: {
        type: Boolean,
        required: false,
        default: false
    }
})

const { projectId, folderId } = toRefs(props);
const { children, folder, isLoading, open, handleDrop } = useLibrary(projectId, folderId)
const project = computed(() => projectId.value ? useRepo(Project).find(projectId.value) : null)

defineExpose({
    handleDrop
})
</script>

<template>
    <DragTarget :item="folder || project" @drop="handleDrop">
        <SidebarMenuSub>
            <SidebarMenuSubItem v-if="isLoading"
                class="flex items-center pl-2 justify-start h-full gap-2 text-muted-foreground text-xs">
                <Loader2 class="animate-spin w-4 h-4" />
                <span>Loading...</span>
            </SidebarMenuSubItem>
            <SidebarMenuSubItem v-else-if="children.length === 0"
                class="flex items-center pl-2 justify-start h-full gap-2 text-muted-foreground text-xs">
                <span>{{ placeholder }}</span>
            </SidebarMenuSubItem>
            <template v-else>
                <LibraryTreeItem @open="open" @drop="handleDrop" v-for="child in children" :key="child.id" :item="child"
                    :sub-button="subButton" />
            </template>
        </SidebarMenuSub>
    </DragTarget>
</template>