import { addToCollection, removeFromCollection, deleteCollection, updateCollection, getCollections, createCollection, navigateToCollection } from '../service';

export function useCollections() {
    const {
        collections,
        isLoading,
        isFetching,
        isError,
        error
    } = getCollections()

    return {
        collections,
        isLoading,
        isFetching,
        isError,
        error,
        addToCollection,
        removeFromCollection,
        deleteCollection,
        updateCollection,
        createCollection,
        navigateToCollection
    }
}