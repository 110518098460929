<script setup>
import { InlineCitation } from '@/tmp/components/inline-citation'
import { defineProps, toRefs, h, ref, watch } from 'vue'
import { marked } from 'marked';
import { useRepo } from 'pinia-orm'
import { References } from '@/models/References'

const referencesRepo = useRepo(References)

const props = defineProps(['answer', 'sources', 'runId'])
const { answer, sources, runId } = toRefs(props)
const referencesFull = ref({})
function replacementsFor(answerText) {
    let replacements = {}
    if (!answerText || !runId.value) return replacements
    const matches = answerText.matchAll(/\[(.+?)\]/g)
    for (let match of matches) {
        const fullMatch = match[0]
        const innerContent = match[1]
        let [type, id] = innerContent.split('#', 2)
        type = type.toLowerCase()

        const i = sources.value[type]?.findIndex(s => {
            if (type === 'documents') {
                return s.id === id
            }
            return s.metadata.parent_id === id
        })
        if (i >= 0) {
            const src = sources.value[type][i]
            if (!referencesFull.value['all']) referencesFull.value['all'] = [];
            if (!referencesFull.value['all'].includes(id)) {
                referencesFull.value['all'].push(id)
                const referenceData = {
                    run_id: runId.value,
                    source_id: src.id,
                    source_type: type,
                    order: referencesFull.value['all'].indexOf(id) + 1,
                }
                referencesRepo.save(referenceData)
            }
            replacements[fullMatch] = h(InlineCitation, {
                index: referencesFull.value['all'].indexOf(id),
                id: type === 'documents' ? src.metadata.parent_id : id,
                type: type,
                title: type === 'cases' ? src.metadata.id : src.metadata.title.replace(/^([^,]*?)\d{2,}(?:[- ]\d+-\d+-\d+-\d+-\d+-\d+)?/g, '$1').replace(/\s*,\s*/g, ', ').trim(),
                content: src.page_content.replace(src.metadata.title.replace(/\n/g, '') + '\n', '')
            })
        }
    }
    return replacements
}

watch(answer, () => {
    if (answer.value) {
        replacementsFor(answer.value)
    }
}, { immediate: true })


function parseWithComponents(inputString, replacements) {
    const tokens = marked.lexer(inputString)

    function processTextWithReplacements(text) {
        const parts = text.split(/(\[.+?\])/)
        return parts.map(part => replacements[part] || part)
    }

    function processInlineElements(tokens) {
        return tokens.flatMap(token => {

            if (token.type === 'text') {
                return processTextWithReplacements(token.raw)
            } else if (token.type === 'strong') {
                return h('strong', { class: 'font-semibold' }, processInlineElements(token.tokens))
            } else if (token.type === 'em') {
                return h('em', { class: 'italic' }, processInlineElements(token.tokens))
            } else if (token.type === 'codespan') {
                return h('code', {}, token.raw)
            } else if (token.type === 'link') {
                return h('a', { href: token.href }, processInlineElements(token.tokens))

            } else if (token.type === 'space') {
                return '\n'
            } else if (token.type === 'br') {
                return '\n'
            } else {
                // For any other inline elements, process them as text
                return processTextWithReplacements(marked.parser([token]))
            }
        })
    }

    function processToken(token) {
        if (token.type === 'paragraph') {
            return h('p', { class: 'text-p-answer' }, processInlineElements(token.tokens))
        } else if (token.type === 'strong') {
            return processInlineElements(token.tokens)
        } else if (token.type === 'em') {
            return h('em', { class: 'italic' }, processInlineElements(token.tokens))
        } else if (token.type === 'heading') {
            return h(`h${token.depth}`, { class: `text-${token.depth}-answer` }, processInlineElements(token.tokens))
        } else if (token.type === 'space') {
            return '\n'
        } else if (token.type === 'br') {
            return '\n'
        } else if (token.type === 'code') {
            return h('pre', {}, [h('code', {}, token.raw)])
        } else if (token.type === 'list') {
            const listItems = token.items.map((item, index) => processListItem({ ...item, index: index + 1 }, token.ordered));
            return h(token.ordered ? 'ol' : 'ul', { class: 'list-none pl-0' }, listItems);
        } else if (token.type === 'list_item') {
            return token.tokens.flatMap(processToken);
        } else if (token.type === 'table') {
            return processTable(token);
        } else {
            // For other block-level elements, we'll render as HTML and then process for replacements
            const renderedHtml = marked.parser([token])
            const div = document.createElement('div')
            div.innerHTML = renderedHtml
            return processTextWithReplacements(div.textContent)
        }
    }

    function processListItem(item, isOrdered) {
        const processedContent = processToken(item);

        function getBulletPoint(index) {
            return isOrdered
                ? h('span', { class: 'inline-block w-5 items-center text-center mr-0' }, `${index}.`)
                : h('span', { class: 'inline-block w-5 items-center text-center mr-0' }, '•');
        }

        // Helper function to safely process content
        function safeProcessInlineElements(content) {
            if (Array.isArray(content)) {
                return content.map(item => {
                    if (typeof item === 'string') {
                        return processInlineElements([{ type: 'text', raw: item }]);
                    }
                    return item;
                }).flat();
            } else if (typeof content === 'string') {
                return processInlineElements([{ type: 'text', raw: content }]);
            } else if (content && typeof content === 'object') {
                return [content]; // Assume it's already a VNode
            }
            return [];
        }

        // Check if the processed content contains nested lists
        const hasNestedList = Array.isArray(processedContent) && processedContent.some(el => el.type === 'ul' || el.type === 'ol');

        if (hasNestedList) {
            // For items with nested lists, we need to separate the text content from the nested list
            const textContent = [];
            const nestedLists = [];
            processedContent.forEach(el => {
                if (typeof el === 'string' || (el.type !== 'ul' && el.type !== 'ol')) {
                    textContent.push(el);
                } else {
                    nestedLists.push(el);
                }
            });

            return h('li', { class: 'flex mb-2 flex-col' }, [
                h('div', { class: 'flex' }, [
                    h('span', { class: 'text-muted-foreground mr-1' }, [getBulletPoint(item.index)]),
                    h('span', { class: 'text-list-answer' }, safeProcessInlineElements(textContent))
                ]),
                ...nestedLists.map(nestedList => h('div', { class: 'ml-4' }, [nestedList])) // Indent nested lists
            ]);
        } else {
            // Process the entire content safely
            const processedText = safeProcessInlineElements(processedContent);

            return h('li', { class: 'flex mb-2' }, [
                h('span', { class: 'text-muted-foreground mr-1' }, [getBulletPoint(item.index)]),
                h('span', { class: 'text-list-answer' }, processedText)
            ]);
        }
    }

    function processTable(token) {
        const header = token.header.map(cell => h('th', { class: 'px-4 py-2 border' }, processInlineElements(cell.tokens)));
        const body = token.rows.map(row => h('tr', {}, row.map(cell => h('td', { class: 'border px-4 py-2' }, cell.tokens.flatMap(processToken)))));
        return h('table', { class: 'table-auto mb-4 mt-4 text-sm rounded-xl' }, [
            h('thead', { class: 'font-semibold' }, h('tr', { class: 'bg-backgroundSecondary' }, header)),
            h('tbody', {}, body)
        ]);
    }


    return tokens.flatMap(processToken)
}

const render = () => {
    if (!answer.value) return null
    const replacements = replacementsFor(answer.value)
    return h('div', { class: "prose text-start" }, parseWithComponents(answer.value, replacements))
}
</script>

<template>
    <component :is="render" />
</template>

<style>
.text-2-answer {
    font-family: 'Inter', sans-serif;
    font-size: 1rem !important;
    line-height: 2.5rem;
    font-weight: 400;
}

.text-3-answer {
    font-family: 'Inter', sans-serif;
    font-size: 1rem !important;
    line-height: 2.5rem;
    font-weight: 400;
}

.text-4-answer {
    font-family: 'Inter', sans-serif;
    font-size: 1rem !important;
    line-height: 2.6rem;
    font-weight: 400;
}

.text-4-answer .strong {
    font-family: 'Inter', sans-serif;
    font-size: 1rem !important;
    line-height: 2.5rem;
    font-weight: 400;
}

.text-p-answer {
    font-size: 0.98rem;
    padding-bottom: 0.50rem;
}

.strong {
    font-weight: 400;
}
</style>