import { TemplateService } from '../service';

export function useTemplate(type, templateId, options = { refresh: true }) {
    const {
        template,
        isLoading,
        isFetching,
        isError,
        error
    } = TemplateService.getTemplate(type, templateId, options)

    return {
        template,
        isLoading,
        isFetching,
        isError,
        error,
        saveTemplate: async () => await TemplateService.updateTemplate(template),
        deleteTemplate: async () => await TemplateService.deleteTemplate(template)
    }
}