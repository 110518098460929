<script setup>
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import PromptForm from './PromptForm.vue';
import { ref, defineProps, toRefs } from 'vue';

const open = ref(false);

const props = defineProps({
    onDelete: {
        type: Function,
        required: false,
        default: null
    },
    prompt: {
        type: Object,
        required: false,
        default: null
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
})

const { prompt } = toRefs(props);

async function handleDelete() {
    await props.onDelete?.();
    open.value = false;
}
</script>

<template>
    <Popover v-model:open="open">
        <PopoverTrigger>
            <slot />
        </PopoverTrigger>
        <PopoverContent class="w-full">
            <PromptForm @submit="$emit('submit', $event); open = false" @cancel="open = false"
                @delete="onDelete ? handleDelete : null" :default-value="prompt" :disabled="disabled" />
        </PopoverContent>
    </Popover>
</template>