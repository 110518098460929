<script setup>
import { ChevronUp, ChevronDown } from 'lucide-vue-next';
import { defineProps, inject } from 'vue';

defineProps({
    field: { type: String, required: true }
})

const toggleSort = inject('toggleSort')
const isSorted = inject('isSorted')
</script>

<template>
    <div @click="toggleSort(field)" class="flex flex-col cursor-pointer hover:bg-accent rounded-md">
        <ChevronUp class="w-4 h-4" :class="{ 'opacity-50': isSorted(field, 'asc') }" />
        <ChevronDown class="w-4 h-4 -mt-2" :class="{ 'opacity-50': isSorted(field, 'desc') }" />
    </div>
</template>