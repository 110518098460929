<script setup>
import { defineProps, toRefs, ref, defineExpose, computed } from 'vue'
import { Selectable } from '@/tmp/components/behaviour/selectable';
import { Draggable } from '@/tmp/components/behaviour/draggable';
import LibraryTreeContent from './LibraryTreeContent.vue';

const props = defineProps({
    projectId: {
        type: String,
        required: false,
        default: null
    },
    subButton: {
        type: Boolean,
        required: false,
        default: false
    }
})

const { projectId } = toRefs(props);
const selectedItems = ref([])
const libraryTreeContent = ref(null)

defineExpose({
    handleDrop: computed(() => libraryTreeContent.value?.handleDrop)
})
</script>

<template>
    <Selectable v-model:selected-items="selectedItems" multiple>
        <Draggable v-model="selectedItems">
            <LibraryTreeContent ref="libraryTreeContent" placeholder="Nothing here yet" :project-id="projectId"
                :sub-button="subButton" />
        </Draggable>
    </Selectable>
</template>