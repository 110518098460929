<script setup>
import { defineProps, ref, onMounted, defineExpose } from 'vue'
import { cn } from '@/lib/utils';

defineProps({
    absoluteNavbar: {
        type: Boolean,
        default: false
    },
    absoluteFooter: {
        type: Boolean,
        default: false
    },
    footerClass: {
        type: String,
        default: ''
    },
    contentClass: {
        type: String,
        default: ''
    }
})

const container = ref(null)

defineExpose({
    container
})

const navbarRef = ref(null)
const footerRef = ref(null)
const headerRef = ref(null)
const navbarHeight = ref(0)
const footerHeight = ref(0)
const headerHeight = ref(0)

const updateElementHeights = () => {
    if (footerRef.value) {
        footerHeight.value = footerRef.value.offsetHeight
    }
    if (headerRef.value) {
        headerHeight.value = headerRef.value.offsetHeight
    }
    if (navbarRef.value) {
        navbarHeight.value = navbarRef.value.offsetHeight
    }
}

onMounted(() => {
    updateElementHeights()
    const resizeObserver = new ResizeObserver(updateElementHeights)
    if (footerRef.value) {
        resizeObserver.observe(footerRef.value)
    }
    if (headerRef.value) {
        resizeObserver.observe(headerRef.value)
    }
    if (navbarRef.value) {
        resizeObserver.observe(navbarRef.value)
    }
})
</script>

<template>
    <div ref="container"
        :class="cn('relative flex flex-col items-center h-[calc(100svh-theme(spacing.4))] rounded-2xl w-full bg-background', $attrs.class)">
        <!-- Navbar -->
        <div ref="navbarRef" class="top-0 w-full flex-none rounded-t-2xl px-1.5" :class="{
            'sticky': !absoluteNavbar,
            'absolute -mt-2.5 z-30': absoluteNavbar
        }">
            <slot name="navbar" />
        </div>

        <div class="flex flex-col flex-1 min-h-0 w-full relative rounded-2xl">
            <!-- Fixed Header -->
            <div ref="headerRef" class="sticky top-0 z-10 bg-background w-full flex-none rounded-t-2xl px-3 pb-3">
                <slot name="header" />
            </div>

            <!-- Scrollable Content -->
            <div class="flex-1 min-h-0 w-full relative rounded-2xl">
                <div :class="cn('absolute inset-0 bg-transparent', contentClass, 'overflow-auto')" :style="{
                    paddingBottom: $slots.footer && absoluteFooter ? `${footerHeight}px` : '0',
                    paddingTop: absoluteNavbar ? `${navbarHeight}px` : '0'
                }">
                    <slot name="content" />
                </div>
            </div>

            <!-- Footer -->
            <div v-if="$slots.footer" ref="footerRef" :class="cn('bg-background pb-1 mx-4 rounded-xl ', footerClass, {
                'absolute bottom-0 left-0 right-0 z-30': absoluteFooter,
                'sticky': !absoluteFooter
            })">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>