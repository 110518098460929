<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { PreviewGroup } from '@/tmp/components/preview';
import { useRepo, Case, Consolidation, Collection } from '@/models';

const emit = defineEmits(['showMore']);
const props = defineProps({
    type: {
        type: String,
        required: true
    },
    numResults: {
        type: Number,
        required: true
    },
    cols: {
        type: Number,
        required: false,
        default: 4
    },
    size: {
        type: String,
        required: false,
        default: 'sm'
    }
});

const repoMap = {
    cases: useRepo(Case),
    regulations: useRepo(Consolidation),
    collections: useRepo(Collection)
}

const type = computed(() => props.type);

const { data, isLoading } = useQuery({
    queryKey: [type, props.numResults],
    queryFn: async () => {
        const path = props.type === 'regulations' ? '/regulations/consolidations/recent' : `/${type.value}/`;
        const response = await repoMap[type.value].api().get(path, {
            params: {
                limit: props.numResults,
            },
            dataTransformer: (response) => {
                return response.data.map(item => {
                    if (item?.uid) {
                        item.id = item.uid;
                    }
                    return item;
                });
            }
        });
        return response.entities.slice(0, props.numResults);
    }
});
</script>

<template>
    <PreviewGroup :items="data" :type="props.type" :is-loading="isLoading" :cols="cols" :size="size"
        :num-results="numResults" @show-more="emit('showMore', type)">
        <slot />
    </PreviewGroup>
</template>