<script setup>
import { defineProps, onMounted } from 'vue';
import {
    ToolbarRoot,
    ToolbarSeparator
} from 'radix-vue';
import * as icons from 'lucide-vue-next';
import { ref } from 'vue';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
const toggleStateSingle = ref('center')
const toggleStateMultiple = ref([])

defineProps({
    editor: {
        type: Object,
        required: true
    }
})

const inputRef = ref(null)

onMounted(() => {
    inputRef.value.focus()
})
</script>

<template>
    <ToolbarRoot class="flex p-2 w-[610px] !min-w-max rounded-xl bg-card shadow-md gap-3"
        aria-label="Formatting options">
        <template v-if="editor.editable">
            <ToggleGroup v-model="toggleStateMultiple" type="multiple" size="sm">
                <ToggleGroupItem value="bold" aria-label="Toggle bold">
                    <icons.Bold class="size-4" />
                </ToggleGroupItem>
                <ToggleGroupItem value="italic" aria-label="Toggle italic">
                    <icons.Italic class="size-4" />
                </ToggleGroupItem>
                <ToggleGroupItem value="underline" aria-label="Toggle underline">
                    <icons.Underline class="size-4" />
                </ToggleGroupItem>
            </ToggleGroup>
            <ToolbarSeparator class="toolbar-separator" />
            <ToggleGroup v-model="toggleStateSingle" type="single" size="sm">
                <ToggleGroupItem value="left" aria-label="Toggle bold">
                    <icons.AlignLeft class="size-4" />
                </ToggleGroupItem>
                <ToggleGroupItem value="center" aria-label="Toggle italic">
                    <icons.AlignCenter class="size-4" />
                </ToggleGroupItem>
                <ToggleGroupItem value="right" aria-label="Toggle underline">
                    <icons.AlignRight class="size-4" />
                </ToggleGroupItem>
            </ToggleGroup>
            <ToolbarSeparator class="toolbar-separator" />
        </template>
        <div class="relative w-full">
            <icons.Sparkles class="absolute left-1 top-1/2 -translate-y-1/2 size-4 text-muted-foreground" />
            <Input ref="inputRef" placeholder="Ask anything..."
                class="w-full h-full text-md border-none bg-transparent focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 pl-7" />
        </div>
        <Button size="icon" class="rounded-full p-1.5 h-8 w-8 m-auto">
            <icons.ArrowRight :stroke-width="2.5" />
        </Button>
    </ToolbarRoot>
</template>

<style scoped>
.toolbar-separator {
    @apply my-1 w-px bg-border;
}
</style>