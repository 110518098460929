<script setup>
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import Share from '@/components/sidebar/footer/Share'
import { Button } from '@/components/ui/button';
import { defineProps, defineModel } from 'vue';
import { Sparkles } from 'lucide-vue-next';
import { useRouter } from 'vue-router';
import { PlanCard } from '@/components/settings';
import { useSubscriptionStore } from '@/stores/subscription';
import { inject, watch } from 'vue';
import { usePosthog } from '@/composables/analytics';
const subscription = useSubscriptionStore();
const { identifyUser } = usePosthog();
const posthog = inject('posthog');


const router = useRouter();

const open = defineModel({ default: false })
const props = defineProps(['text', 'variant', 'icon', 'size', 'share', "open", "no_button"]);
const upgrade = () => {
  router.push({ name: 'checkout', query: { plan: 'pro', billing_period: 'monthly', quantity: 1 } });
}

const proFeatures = [
  'Alles van basic',
  'Research+',
  'Documenten vault',
  'Multi-doc analyse via sheets',
  'Document review',

];

const basicFeatures = [
  'Research (basic)',
  'Gelimiteerde search credits',
  'Search geschiedenis'
];


watch(open, (newValue) => {
  if (newValue) {
    identifyUser()
    posthog.capture('$upgrade_dialog_opened');
  }
});

</script>

<template>
  <div>
    <Dialog :open="open" @update:open="open = !open">
      <DialogTrigger class="w-full" v-if="!props.no_button">
        <Button class="w-full text-xs justify-start gradient-border bg-muted" :size="props.size"
          :variant="props.variant">
          <Sparkles class="h-3.5 w-3.5 mr-2 " />
          {{ props.text }}
        </Button>
      </DialogTrigger>
      <DialogContent class="bg-backgroundSecondary">
        <DialogHeader>
          <DialogTitle>
            <div class="flex items-center mb-2">
              <Sparkles class="w-5 h-5 mr-2" />
              <h1 class="text-lg font-semibold tracking-tight">Upgrade naar Pro</h1>
            </div>
          </DialogTitle>
          <DialogDescription>
            <div v-if="props.share">
              <p class="text-sm text-muted-foreground mb-2">Je credits zijn op!</p>
              <p class="text-sm text-muted-foreground mb-2">Je kunt Zeno delen om je credits te verhogen</p>
              <Share />
            </div>

            <p class="text-sm text-muted-foreground mb-2">Upgrade naar Pro voor nog geen fractie van een gemiddeld
              uurtarief per maand en geniet van de volgende voordelen:</p>
          </DialogDescription>
        </DialogHeader>
        <div class="grid grid-cols-2 gap-4 mb-2">
          <PlanCard :class="'bg-transparent border-border border'" :plan="'Basic'" :features="basicFeatures"
            :price="'Gratis'" :isFree="subscription.plan === 'free'" />
          <PlanCard @click="upgrade" :class="'bg-muted gradient-border cursor-pointer hover:bg-accent '" :plan="'Pro'"
            :features="proFeatures" :price="'€89,- p/maand excl. btw'" :isFree="subscription.plan === 'free'" />

        </div>
      </DialogContent>
    </Dialog>
  </div>
</template>

<style scoped>
ul {
  list-style: none;
}
</style>