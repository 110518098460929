<script setup>
import { ActionMenu } from '@/tmp/components/action-menu';
import { useLibrary } from '@/tmp/services/library';
import { defineProps, toRefs, provide, computed, toValue } from 'vue';
import { useRepo } from 'pinia-orm';
import { Folder, Project, Collection } from '@/models';
import { ResourceIcon, ResourceName } from '.';

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    actionMenu: {
        type: String,
        required: false,
        default: 'context'
    },
    actionMenuDisabled: {
        type: Boolean,
        required: false,
        default: false
    }
})

const { item } = toRefs(props);
const folderId = computed(() => {
    if (item.value instanceof Folder) {
        return item.value?.id || null;
    }
    return item.value?.folder_id || null;
});
const projectId = computed(() => {
    if (item.value instanceof Project) {
        return item.value?.id || null;
    }
    return item.value?.project_id || null;
});
const parentId = computed(() => {
    if (item.value instanceof Folder) {
        return item.value?.parent_id || null;
    }
    return folderId.value;
});

const { destroy, create, move, handleDrop, save } = useLibrary(projectId, folderId);

const onMove = computed(() => {
    if (!(item.value instanceof Project)) {
        return ({ selectedItems, projectId: pId, folderId: fId }) => {
            let parent
            selectedItems = toValue(selectedItems)
            if (selectedItems.length == 1 && selectedItems[0] instanceof Folder) {
                parent = selectedItems[0]
            } else {
                parent = useRepo(Folder).find(fId) || useRepo(Project).find(pId) || null;
            }
            move(parent, [toValue(item)]);
        }
    }
    return null;
});

provide('item', item);

const editableIcon = computed(() => {
    return (item.value instanceof Project) || (item.value instanceof Collection)
});
</script>

<template>
    <ActionMenu @drop="handleDrop" v-model:name="item.name" v-model:icon="item.icon" v-model:color="item.color"
        :variant="actionMenu" :disabled="actionMenuDisabled" @delete="destroy(item)" @create="create" @move="onMove"
        :item="item" @rename="save(item)" @change-icon="save(item)" editable-name :editable-icon="editableIcon"
        :project-id="projectId" :folder-id="parentId">
        <slot>
            <div>
                <ResourceIcon />
                <ResourceName />
            </div>
        </slot>
    </ActionMenu>
</template>