<script setup>
import { defineProps, defineModel, inject } from 'vue'
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetDescription,
} from "@/components/ui/sheet"
import Cell from './Cell.vue'
import { Answer } from '@/tmp/components/answer'
import { ScrollArea } from '@/components/ui/scroll-area'
import { BrainCircuit, Text, LetterText, ListCheck } from 'lucide-vue-next'

defineProps({
    document: {
        type: Object,
        required: true
    },
    sheet: {
        type: Object,
        required: true
    },
    prompts: {
        type: Array,
        required: true
    }
})

const icon = (format) => {
    switch (format) {
        case 'text':
            return LetterText
        case 'boolean':
            return ListCheck
        default:
            return null
    }
}


const isOpen = defineModel()
const container = inject('container')
</script>

<template>
    <Sheet v-if="container !== null" :open="isOpen" class="max-w-full" @update:open="isOpen = $event">
        <SheetContent side="right" class="w-[40%] max-h-screen overflow-y-auto min-w-[40%]"
            :container="container.container">
            <SheetHeader>
                <SheetTitle>{{ document.title }}</SheetTitle>
                <SheetDescription>
                    Document overview and responses
                </SheetDescription>
            </SheetHeader>

            <ScrollArea class="mt-6 space-y-4">
                <div v-for="prompt in prompts" :key="prompt.id" class="space-y-6 border-t border-border">
                    <div class="space-y-2">
                        <div class="flex items-center gap-2 text-sm text-muted-foreground mt-4 mb-2">
                            <component :is="icon(prompt.format)" class="w-6 h-6" />
                            <h1 class="text-lg font-black">{{ prompt.label }}: {{ prompt.content }}</h1>
                        </div>
                    </div>
                    <div class="space-y-2">
                        <div class="flex items-center gap-2 text-md text-muted-foreground mt-2">
                            <Text class="w-4 h-4" />
                            <span>Answer</span>
                        </div>
                        <Cell :cell="sheet.cell(prompt, document)" :isTextWrapped="true" />
                    </div>
                    <div class="space-y-2">
                        <div class="flex items-center gap-2 text-md text-muted-foreground mt-2">
                            <BrainCircuit class="w-4 h-4" />
                            <span>Reasoning</span>
                        </div>
                        <Answer :answer="sheet.cell(prompt, document).answer" :runId="sheet.cell(prompt, document).id"
                            :sources="sheet.cell(prompt, document).sources" />
                    </div>
                </div>
            </ScrollArea>
        </SheetContent>
    </Sheet>
</template>
