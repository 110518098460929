<script setup>
import MasonryWall from '@yeger/vue-masonry-wall';
import { RegulationIcon, CaseIcon } from '@/components/icons'
import { Loading } from '@/components'
import { useSidepanel } from '@/tmp/components/sidepanel'
import { defineProps, onMounted, onUnmounted, ref, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import PreviewCard from './PreviewCard.vue'

const emit = defineEmits(['scroll-end'])

const sidepanel = useSidepanel()

const router = useRouter()
const scrollContainer = ref(null)

const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    isLoading: {
        type: Boolean,
        required: true
    },
    type: {
        type: String,
        required: true
    }
})

async function handleScroll(e) {
    const container = e.target;
    const scrollPosition = container.scrollTop + container.clientHeight;
    const documentHeight = container.scrollHeight;
    if (scrollPosition > documentHeight * 0.8 && !props.isLoading) emit('scroll-end');
}

onMounted(() => {
    if (scrollContainer.value) {
        scrollContainer.value.addEventListener('scroll', handleScroll);
    }
});

onUnmounted(() => {
    if (scrollContainer.value) {
        scrollContainer.value.removeEventListener('scroll', handleScroll);
    }
});

const navigateTo = (item) => {
    router.push({ name: 'type-explore-item', params: { type: props.type, resourceId: item.id } })
}
</script>

<template>
    <div ref="scrollContainer" class="overflow-y-auto h-full w-full px-4 pb-4">
        <Loading v-if="isLoading && !items" height="h-full" />
        <template v-else>
            <MasonryWall :items="items" :max-columns="3" :key-mapper="item => item.id" :gap="16"
                :scroll-container="scrollContainer" :column-width="300">
                <template #default="{ item }">
                    <PreviewCard @open-default="sidepanel.addTab(item)" @bookmark="console.log(item)"
                        @open-split="sidepanel.addTab(item)" @open-full="navigateTo(item)" v-bind="item.preview">
                        <template #source v-if="type === 'regulations'">
                            <RegulationIcon />
                            <span class="text-xs font-medium">Wetten.nl</span>
                        </template>
                        <template #source v-else-if="type === 'cases'">
                            <CaseIcon />
                            <span class="text-xs font-medium">Rechtspraak.nl</span>
                        </template>
                    </PreviewCard>
                </template>
            </MasonryWall>
            <div v-if="isLoading" class="flex justify-center items-center p-4">
                <i class="bx bx-loader-alt bx-spin text-lg text-accent-foreground mr-2" />
                <span class="loading-spinner">Loading results...</span>
            </div>
        </template>
    </div>
</template>