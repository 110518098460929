import { useQuery } from '@tanstack/vue-query';
import { Activity, useRepo } from '@/models';
import { toValue } from 'vue';

export function useRecents(numItems, projectId) {
    // const repoMap = {
    //     'document': Document,
    //     'thread': Thread,
    //     'sheet': Sheet,
    //     'project': Project
    // }
    return useQuery({
        queryKey: ['recents', numItems, projectId],
        queryFn: async () => {
            await useRepo(Activity).api().get('/activity/recents/', { params: { limit: toValue(numItems), project_id: toValue(projectId) || "*" } })
            let query = useRepo(Activity).withAllRecursive()
            if (toValue(projectId) !== undefined) query = query.where('project_id', toValue(projectId))
            query = query.orderBy('created_at', 'desc').limit(toValue(numItems))
            // const missingResources = query.get().filter(activity => !activity.resource).map(activity => ({ type: activity.resource_type, id: activity.resource_id }))
            // const missingResourcesByType = missingResources.reduce((acc, activity) => {
            //     acc[activity.type] = acc[activity.type] || []
            //     acc[activity.type].push(activity.id)
            //     return acc
            // }, {})
            // const promises = []
            
            // for (const [type, ids] of Object.entries(missingResourcesByType)) {
            //     for (const id of ids) {
            //         promises.push(useRepo(repoMap[type]).api().get(`/${type}s/${id}`))
            //     }
            // }
            // await Promise.all(promises)
            return query.get().map(activity => activity.resource).filter(resource => !!resource) || []
        }
    })
}