<script setup>
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { LetterText, ListCheck, Trash2 } from 'lucide-vue-next'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { HelpCircle } from 'lucide-vue-next'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { watch, ref } from 'vue'

import { defineEmits, defineProps } from 'vue'

const props = defineProps({
    onCancel: { type: Function },
    disabled: { type: Boolean, required: false, default: false },
    defaultValue: { type: Object, required: false }
})

const emit = defineEmits(['submit', 'cancel', 'delete'])
const prompt = ref(props.defaultValue)

// Define validation schema
const formSchema = toTypedSchema(z.object({
    label: z.string()
        .min(1, 'Label is required')
        .max(100, 'Label must be less than 100 characters'),
    content: z.string()
        .min(1, 'Prompt content is required')
        .max(10000, 'Prompt must be less than 10000 characters'),
    format: z.enum(['text', 'boolean'], {
        required_error: 'Please select a response format',
    }),
}))

// Initialize form with validation
const form = useForm({
    validationSchema: formSchema,
    initialValues: {
        label: prompt.value?.label ?? '',
        content: prompt.value?.content ?? '',
        format: prompt.value?.format ?? 'text'
    },
})

// Replace the existing watch with this updated version
watch(() => prompt.value, (newValue) => {
    if (newValue) {
        form.resetForm({
            values: {
                label: newValue.label ?? '',
                content: newValue.content ?? '',
                format: newValue.format ?? 'text'
            }
        })
    }
}, { immediate: true })

// Update submit handler
const onSubmit = form.handleSubmit((values) => {
    emit('submit', values)
})

const onDelete = () => {
    emit('delete', prompt.value)
}

const formats = [
    { label: 'Text', value: 'text', icon: LetterText },
    { label: 'Yes/No', value: 'boolean', icon: ListCheck }
]

</script>

<template>
    <form @submit="onSubmit" class="w-full space-y-4" :disabled="disabled">
        <FormField name="label" v-slot="{ field }">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Label</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>The column header that will appear in your sheet</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <FormControl>
                    <Input v-model="field.value" @update:modelValue="field.onChange" placeholder="Enter column label"
                        :disabled="disabled" />
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>

        <FormField name="content" v-slot="{ field }">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Prompt</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>The question or prompt that will be sent to Zeno for each row</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <FormControl>
                    <Textarea v-model="field.value" @update:modelValue="field.onChange" placeholder="Enter prompt text"
                        class="flex min-h-[80px] min-w-[300px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground"
                        :disabled="disabled" />
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>

        <FormField name="format" v-slot="{ field }">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Format</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>The expected format of Zeno's response</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <Select v-model="field.value" @update:modelValue="field.onChange" :disabled="disabled">
                    <FormControl>
                        <SelectTrigger>
                            <SelectValue placeholder="Select format" />
                        </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                        <SelectItem v-for="format in formats" :key="format.value" :value="format.value">
                            <div class="flex items-center gap-2">
                                <component :is="format.icon" class="w-4 h-4 flex-shrink-0" />
                                <span>{{ format.label }}</span>
                            </div>
                        </SelectItem>
                    </SelectContent>
                </Select>
                <FormMessage />
            </FormItem>
        </FormField>
        <div v-if="!!prompt?.id && !disabled" class="flex flex-row justify-between items-center">
            <Button class=" text-xs flex flex-row items-center space-x-2" variant="ghost" size="sm"
                @click.prevent.stop="onDelete">
                <Trash2 class="w-3 h-3 flex-shrink-0 text-muted-foreground" />
                <span class="text-xs text-muted-foreground">Delete</span>
            </Button>
            <div class="flex gap-2">
                <Button v-if="onCancel" type="button" variant="outline" @click="emit('cancel')">Cancel</Button>
                <Button type="submit">Submit</Button>
            </div>
        </div>
        <div v-else class="flex gap-2 justify-end">
            <Button v-if="onCancel" type="button" variant="outline" @click="emit('cancel')">Cancel</Button>
            <Button type="submit" :disabled="disabled">Submit</Button>
        </div>
    </form>
</template>
