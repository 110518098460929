import { ref, computed, toValue, watchEffect } from 'vue'

export function useSelectable(items, selectedItems, { multiple = true, selectMode = 'replace' } = {}) {
    const lastSelected = ref(null)

    function toggleSelect(item, event) {
        item = toValue(item)
        if (!multiple) {
            if (selectedItems.value.includes(item)) {
                selectedItems.value = []
                lastSelected.value = null
            } else {
                selectedItems.value = [item]
                lastSelected.value = item
            }
            return
        }
        
        const isCtrlPressed = event.metaKey || event.ctrlKey
        const isShiftPressed = event.shiftKey
    
        if (isShiftPressed && lastSelected.value) {
            const currentIndex = toValue(items).findIndex(i => i.id === item.id)
            const lastIndex = toValue(items).findIndex(i => i.id === lastSelected.value.id)
            const [start, end] = [Math.min(currentIndex, lastIndex), Math.max(currentIndex, lastIndex)]
            
            // Get the range of items and combine with existing selection
            const rangeItems = toValue(items).slice(start, end + 1)
            selectedItems.value = [...new Set([...toValue(selectedItems), ...rangeItems])]
            lastSelected.value = item
        } else if (isCtrlPressed) {
            const isItemSelected = selectedItems.value.some(i => i.id === item.id)
            if (isItemSelected) {
                selectedItems.value = selectedItems.value.filter(i => i.id !== item.id)
            } else {
                selectedItems.value = [...selectedItems.value, item]
            }
            lastSelected.value = item
        } else if (item?.id !== lastSelected.value?.id && selectMode === 'replace') {
            selectedItems.value = [item]
            lastSelected.value = item
        } else if (selectMode === 'toggle') {
            if (item?.id !== lastSelected.value?.id) {
                selectedItems.value = [...selectedItems.value, item]
                lastSelected.value = item
            } else {
                selectedItems.value = selectedItems.value.filter(i => i.id !== item.id)
                lastSelected.value = null
            }
        } else {
            selectedItems.value = []
            lastSelected.value = null
        }
    }

    function toggleSelectAll() {
        if (isAllSelected.value === true) {
            selectedItems.value = []
        } else {
            selectedItems.value = items.value
        }
    }

    function isSelected(item) {
        return selectedItems.value.map?.(i => i.id).includes(toValue(item).id)
    }

    const isAllSelected = computed(() => {
        if (items.value.length === 0) {
            return false
        }
        if (items.value.every(item => isSelected(item))) {
            return true
        }
        if (selectedItems.value.length > 0) {
            return 'indeterminate'
        }
        return false
    })

    watchEffect(() => {
        console.log('selectedItems', selectedItems.value)
    })

    return {
        selectedItems,
        toggleSelect,
        toggleSelectAll,
        isSelected,
        isAllSelected
    }
}