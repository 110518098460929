<script setup>
import { cn } from "@/lib/utils";
import { defineProps } from "vue";
const props = defineProps({
  class: { type: null, required: false },
});
</script>

<template>
  <tr
    :class="
      cn(
        'border-b transition-colors data-[state=selected]:bg-accent',
        props.class,
      )
    "
  >
    <slot />
  </tr>
</template>
