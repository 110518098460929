<script setup>
import { defineProps, ref, defineExpose } from 'vue';

defineProps({
    items: {
        type: Array,
        required: true
    }
});

const el = ref(null);

defineExpose({
    el
});
</script>

<template>
    <Teleport to="body">
        <div v-show="items.length" ref="el"
            class="fixed pointer-events-none bg-background border rounded-lg shadow-lg p-2 z-50" :style="{
                transform: 'translate(-50%, -50%)'
            }">
            <div class="flex items-center gap-2 px-3 py-2 bg-background border rounded-md shadow-lg">
                <span v-if="items.length === 1">
                    {{ items[0].name }}
                </span>
                <span v-else>
                    {{ items.length }} items
                </span>
            </div>
        </div>
    </Teleport>
</template>
