<script setup>
import { defineProps, toRefs } from 'vue'
import { Clock } from 'lucide-vue-next'
import { DataTable } from '@/tmp/components/data-table';
import { useRecents } from './use-recents';
const props = defineProps({
    projectId: {
        type: String,
        required: false,
        default: undefined
    },
    numItems: {
        type: Number,
        required: false,
        default: 10
    }
})

const { numItems, projectId } = toRefs(props)
const { data, isLoading } = useRecents(numItems, projectId)
</script>

<template>
    <DataTable :is-loading="isLoading" :items="data || []" time-column="Last activity"
        :time-column-options="[{ key: 'activity_at', label: 'Last activity' }]" selectable multiple context-menu
        @dblclick="open">
        <template #is-empty>
            <Clock class="size-12" />
            <span>Nothing here yet...</span>
        </template>
    </DataTable>
</template>
