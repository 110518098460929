<script setup>
import { defineProps, ref, computed, defineModel } from 'vue'
import {
    TableCell,
    TableRow
} from '@/components/ui/table'
import { FolderOpen, MoreHorizontal } from 'lucide-vue-next'
import { useLibrary } from '../composables';
import { DataTable } from '@/tmp/components/data-table';
import { useFilterable } from '@/tmp/composables';
import { ActionMenu } from '@/tmp/components/action-menu';
import { Folder, Project, useRepo } from '@/models';
import { DragTarget } from '@/tmp/components/behaviour/draggable';

const projectRepo = useRepo(Project);

const props = defineProps({
    selectable: {
        type: [Boolean, String],
        required: false,
        default: false
    },
    multiple: {
        type: Boolean,
        required: false,
        default: false
    },
    keepSelection: {
        type: Boolean,
        required: false,
        default: false
    },
    filters: {
        type: Object,
        required: false,
        default: () => ({})
    },
    embedded: {
        type: Boolean,
        required: false,
        default: false
    }
})

const query = ref('')
const projectId = defineModel('projectId', {
    type: String,
    required: false,
    default: null
})
const folderId = defineModel('folderId', {
    type: String,
    required: false,
    default: null
})
const { open, create, upload, folder, parent, children, isLoading, handleDrop } = useLibrary(projectId, folderId, { embedded: props.embedded })

const timeColumnOptions = [
    { key: 'created_at', label: 'Created' },
    { key: 'updated_at', label: 'Last updated' }
]
const timeColumn = ref(timeColumnOptions[0].label)
const timeColumnKey = computed(() => timeColumnOptions.find(option => option.label === timeColumn.value).key)

const { filteredItems } = useFilterable(children, {
    name: (item) => item.name.toLowerCase().includes(query.value.toLowerCase()),
    owner: (item) => props.filters?.members?.some(m => m.id === item.user_id) || !props.filters?.members?.length,
    type: (item) => [props.filters?.type, 'folders'].includes(item.constructor.entity) || props.filters?.type === 'all' || !props.filters?.type,
    dateRange: (item) => {
        const { start, end } = props.filters?.dateRange || { start: null, end: null }
        return (!start || item[timeColumnKey.value] >= start.toDate()) && (!end || item[timeColumnKey.value] <= end.toDate())
    }
})

const selectedItems = defineModel({
    type: Array,
    required: false,
    default: () => []
})

function updateLocation(pId, fId) {
    projectId.value = pId;
    folderId.value = fId;
}

const project = computed(() => projectRepo.find(projectId.value))
</script>

<template>
    <ActionMenu :icon="folder?.icon || 'Library'" :name="folder?.name || 'Library'" @create="create" @upload="upload"
        variant="context" :disabled="embedded">
        <DataTable :is-loading="isLoading" v-model:time-column="timeColumn" v-model:selected-items="selectedItems"
            v-model:query="query" :items="filteredItems" :time-column-options="timeColumnOptions" searchable
            :selectable="selectable" :multiple="multiple" :draggable="!embedded" @drop="handleDrop"
            :context-menu="!embedded" @long-dragover="item instanceof Folder ? open(item) : null"
            @dblclick="(item) => embedded ? item instanceof Folder ? updateLocation(item.project_id, item.id) : null : open(item)"
            :root="folder || project || null">
            <template v-if="parent !== undefined" #first-row>
                <DragTarget v-slot="{ isDraggedOver }" :item="parent" @long-dragover="open(parent)">
                    <TableRow @drop.prevent.stop="handleDrop(parent, $event)"
                        @dblclick="embedded ? updateLocation(parent?.project_id || projectId, parent?.id || null) : open(parent)"
                        class="cursor-pointer group rounded hover:bg-accent" :class="{ 'bg-muted': isDraggedOver }">
                        <TableCell v-if="selectable === 'checkbox'" role="checkbox" />
                        <TableCell colspan="3">
                            <div class="flex items-center gap-2 h-6">
                                <FolderOpen />
                                <MoreHorizontal class="-mb-2" />
                            </div>
                        </TableCell>
                    </TableRow>
                </DragTarget>
            </template>
            <template #is-empty>
                <FolderOpen class="size-12" />
                <span v-if="folderId">This folder is empty</span>
                <span v-else>Nothing here yet...</span>
            </template>
        </DataTable>
    </ActionMenu>
</template>
