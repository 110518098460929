<script setup>
import { ref, onMounted, h, defineEmits } from 'vue';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Project } from '@/models';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';
import { Button } from '@/components/ui/button';
import { ToastAction, useToast } from '@/components/ui/toast';
import { IconPicker } from '.';
import { PeopleCombobox } from '.';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as z from 'zod';

const { toast } = useToast();

// Define validation schema
const formSchema = toTypedSchema(z.object({
    name: z.string()
        .min(1, 'Project name is required')
        .max(100, 'Project name must be less than 100 characters'),
    description: z.string()
        .max(500, 'Description must be less than 500 characters')
        .optional(),
    members: z.array(z.object({
        id: z.string(),
    })).optional().default([]),
    icon: z.object({
        color: z.string(),
        icon: z.string(),
    }),
}));

// Initialize form with validation
const form = useForm({
    validationSchema: formSchema,
    initialValues: {
        name: '',
        description: '',
        members: [],
        icon: {
            color: 'primary',
            icon: 'Box'
        }
    },
});

const projectRepo = useAxiosRepo(Project).setAxios(api);

const emit = defineEmits(['submit']);

const portalContainer = ref(null);

onMounted(() => {
    portalContainer.value = document.querySelector('[role="dialog"]') || document.body;
});

// Wrap onSubmit with handleSubmit for validation
const onSubmit = form.handleSubmit(async (data) => {
    try {
        const response = await projectRepo.api().post('/projects/', {
            name: data.name,
            description: data.description,
            acl: {
                write: data.members?.map?.(m => m.id) || [],
            },
            icon: data.icon.icon,
            color: data.icon.color
        });
        emit('submit');
        toast({
            title: 'Project created',
            description: response.entities[0].name,
        });
    } catch (error) {
        console.error(error);
        toast({
            title: 'Uh oh! Something went wrong.',
            description: 'There was a problem creating your project.',
            variant: 'destructive',
            action: h(ToastAction, {
                altText: 'Try again',
            }, {
                default: () => onSubmit(data),
            }),
        });
    }
});
</script>

<template>
    <form @submit="onSubmit" class="space-y-3">
        <div class="flex flex-row gap-2">
            <FormField name="icon" v-model="form.icon" v-slot="{ componentField }">
                <FormItem class="w-fit">
                    <FormControl>
                        <IconPicker v-bind="componentField" v-model="form.icon" />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            </FormField>
            <FormField name="name" v-slot="{ componentField }">
                <FormItem class="flex-grow">
                    <FormControl>
                        <Input v-bind="componentField" v-model="form.name" placeholder="Enter project name" />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            </FormField>
        </div>

        <FormField name="description" v-slot="{ componentField }">
            <FormItem>
                <FormLabel>Description</FormLabel>
                <FormDescription>
                    Briefly describe your project.
                </FormDescription>
                <FormControl>
                    <Textarea v-bind="componentField" v-model="form.description" placeholder="Enter project description"
                        :rows="4" />
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>

        <FormField v-if="false" name="members" v-slot="{ componentField }">
            <FormItem>
                <FormLabel>Project Members</FormLabel>
                <FormDescription>
                    Select team members for this project.
                </FormDescription>
                <FormControl>
                    <PeopleCombobox v-bind="componentField" v-model="form.members" />
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <slot>
            <Button type="submit">Submit</Button>
        </slot>
    </form>
</template>
