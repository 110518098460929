<script setup>
import { provide, ref, defineModel, defineProps } from 'vue';
import { useDraggable } from './use-draggable';
import DragImage from './DragImage.vue';
import DragTarget from './DragTarget.vue';

const selectedItems = defineModel({
    type: Array,
    required: true
});

defineProps({
    root: {
        type: Object,
        required: false,
        default: undefined
    }
});

const dragImage = ref(null);

const {
    onDragstart,
    onDragover,
    onDragend,
    onDragenter,
    onDragleave,
    isItemDraggedOver,
    isItemDragged,
    draggedItems,
    isDragging
} = useDraggable(selectedItems, dragImage);

provide('draggableHooks', {
    onDragstart,
    onDragover,
    onDragend,
    onDragenter,
    onDragleave
});
provide('draggableHelpers', {
    isItemDraggedOver,
    isItemDragged
});
</script>

<template>
    <DragTarget v-if="root !== undefined" :item="root" @drop="(item, event) => $emit('drop', item, event)">
        <slot :is-dragging="isDragging" />
    </DragTarget>
    <slot v-else :is-dragging="isDragging" />
    <DragImage ref="dragImage" :items="draggedItems" />
</template>
