<script setup>
import { defineProps, defineEmits, toRefs, computed } from 'vue';
import { useSearch } from '../composables';
import { PreviewGroup } from '@/tmp/components/preview';

const emit = defineEmits(['showMore']);
const props = defineProps({
    query: {
        type: String,
        required: true
    },
    type: {
        type: String,
        required: true
    },
    numResults: {
        type: Number,
        required: true
    },
    cols: {
        type: Number,
        required: false,
        default: 4
    },
    size: {
        type: String,
        required: false,
        default: 'sm'
    }
});

const { query } = toRefs(props);
const type = computed(() => props.type === 'regulations' ? 'consolidations' : props.type);

const { results, isLoading } = useSearch({ query, type, limit: props.numResults });
</script>

<template>
    <PreviewGroup :items="results" :type="props.type" :is-loading="isLoading" :cols="cols" :size="size"
        :num-results="numResults" @show-more="emit('showMore', type)">
        <slot />
    </PreviewGroup>
</template>