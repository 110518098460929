<script setup>
import { ResourceIcon } from '@/resource';
import {
    EditableRoot,
    EditableEditTrigger,
    Primitive
} from 'radix-vue';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuSeparator,
    DropdownMenuGroup,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
    DropdownMenuPortal,
    DropdownMenuLabel
} from '@/components/ui/dropdown-menu';
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
    ContextMenuSeparator,
    ContextMenuGroup,
    ContextMenuSub,
    ContextMenuSubTrigger,
    ContextMenuSubContent,
    ContextMenuLabel
} from '@/components/ui/context-menu';
import {
    FolderPlus,
    PenLine,
    Copy,
    FolderOutput,
    Trash2,
    UserPlus,
    Save,
    Download,
    Text,
    Layout,
    Plus,
    CopyPlus,
    FileUp,
    FolderUp
} from 'lucide-vue-next';
import {
    ref,
    defineModel,
    defineProps
} from 'vue';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { ResourceTableDialog } from '@/tmp/components/resources';

const props = defineProps({
    variant: {
        type: String,
        default: 'dropdown'
    },
    editableIcon: {
        type: Boolean,
        default: false
    },
    editableName: {
        type: Boolean,
        default: false
    },
    onCopy: {
        type: Function,
        default: null
    },
    onMove: {
        type: Function,
        default: null
    },
    onDelete: {
        type: Function,
        required: false,
        default: null
    },
    onShare: {
        type: Function,
        default: null
    },
    onSaveAsTemplate: {
        type: Function,
        default: null
    },
    onDownload: {
        type: Function,
        default: null
    },
    onCreate: {
        type: Function,
        default: null
    },
    onUpload: {
        type: Function,
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    },
    item: {
        type: Object,
        required: false,
        default: null
    }
});

if (!['dropdown', 'context'].includes(props.variant)) {
    throw new Error('Invalid variant');
}

const name = defineModel('name');
const icon = defineModel('icon');
const color = defineModel('color', { default: 'primary' });

const fileInput = ref(null);
const folderInput = ref(null);
</script>


<template>
    <component :is="props.variant === 'dropdown' ? DropdownMenu : ContextMenu">
        <component :is="editableName ? EditableRoot : Primitive" v-model="name" activation-mode="none"
            submit-mode="enter" as-child>
            <component :is="props.variant === 'dropdown' ? DropdownMenuTrigger : ContextMenuTrigger"
                :disabled="disabled" as-child>
                <slot />
            </component>
            <component :is="props.variant === 'dropdown' ? DropdownMenuContent : ContextMenuContent">
                <template v-if="editableName || editableIcon">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuLabel : ContextMenuLabel">
                        <ResourceIcon v-model:icon="icon" v-model:color="color" :editable="editableIcon" />
                        <span>{{ name }}</span>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onCreate">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component @click="onCreate('folder')"
                            :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                            <FolderPlus />
                            <span>New folder</span>
                        </component>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="editableName || onCopy || onMove">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <EditableEditTrigger as-child v-if="editableName">
                            <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                <PenLine />
                                <span>Rename</span>
                            </component>
                        </EditableEditTrigger>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onCopy">
                            <Copy />
                            <span>Duplicate</span>
                        </component>
                        <ResourceTableDialog v-if="onMove" @submit="onMove" title="Select a destination"
                            :default-project-id="item?.project_id"
                            :default-folder-id="item?.folder_id || item?.parent_id">
                            <component @click.prevent.stop @select.prevent
                                :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                <FolderOutput />
                                <span>Move to</span>
                            </component>
                        </ResourceTableDialog>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onUpload">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            @click="fileInput.click()">
                            <FileUp />
                            <span>Upload files</span>
                        </component>
                        <input type="file" ref="fileInput" @change="onUpload" class="hidden"
                            accept="pdf,docx,doc,txt,md" multiple />
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            @click="folderInput.click()">
                            <FolderUp />
                            <span>Upload folder</span>
                        </component>
                        <input type="file" ref="folderInput" @change="onUpload" class="hidden"
                            accept="pdf,docx,doc,txt,md" webkitdirectory directory multiple />
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onShare || onSaveAsTemplate || onDownload">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onShare">
                            <UserPlus />
                            <span>Share</span>
                        </component>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onSaveAsTemplate">
                            <Save />
                            <span>Save as template</span>
                        </component>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onDownload">
                            <Download />
                            <span>Download</span>
                        </component>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onCreate">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component :is="props.variant === 'dropdown' ? DropdownMenuSub : ContextMenuSub">
                            <component @click="onCreate('thread')"
                                :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem" as-child>
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubTrigger : ContextMenuSubTrigger">
                                    <Text />
                                    <span>Threads</span>
                                </component>
                            </component>
                            <component :is="props.variant === 'dropdown' ? DropdownMenuPortal : 'div'">
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubContent : ContextMenuSubContent">
                                    <component @click="onCreate('thread')"
                                        :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                        <Plus />
                                        <span>Empty thread</span>
                                    </component>
                                    <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                        <CopyPlus />
                                        <span>From template</span>
                                    </component>
                                </component>
                            </component>
                        </component>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuSub : ContextMenuSub">
                            <component @click="onCreate('sheet')"
                                :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem" as-child>
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubTrigger : ContextMenuSubTrigger">
                                    <Layout />
                                    <span>Sheets</span>
                                </component>
                            </component>
                            <component :is="props.variant === 'dropdown' ? DropdownMenuPortal : 'div'">
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubContent : ContextMenuSubContent">
                                    <component @click="onCreate('sheet')"
                                        :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                        <Plus />
                                        <span>Empty sheet</span>
                                    </component>
                                    <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                        <CopyPlus />
                                        <span>From template</span>
                                    </component>
                                </component>
                            </component>
                        </component>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup" v-if="onDelete">
                    <AlertDialog>
                        <AlertDialogTrigger as-child>
                            <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                                class="text-destructive" @select.prevent>
                                <Trash2 />
                                <span>Delete</span>
                            </component>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This action cannot be undone. This will permanently delete the item.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction @click="onDelete">Delete</AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </component>
            </component>
        </component>
    </component>
</template>
