<script setup>
import { computed, toRefs, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { PreviewCard } from '@/tmp/components/board';
import { useBookmark } from '../composables';
import { CaseIcon } from '@/components/icons';
import { useSidepanel } from '@/tmp/components/sidepanel';
import { toValue, watchEffect } from 'vue';

const { addTab } = useSidepanel()

const props = defineProps({
    bookmarkId: {
        type: String,
        required: true
    }
})
const { bookmarkId } = toRefs(props)

const { bookmark, isLoading } = useBookmark(bookmarkId)

const description = computed(() => {
    return toValue(bookmark).subject.kind + ' ' + new Date(toValue(bookmark).subject.decision_date).toLocaleDateString() + ' ' + toValue(bookmark).subject.body_of_government?.name
})

const summary = computed(() => {
    const item = toValue(bookmark).subject
    return item.summary || (item.text ? item.text.slice(0, 250) + '...' : 'No summary available');
})

const router = useRouter()
function openFull() {
    const routeName = toValue(bookmark).subject.constructor.name.toLowerCase()
    router.push({
        name: routeName,
        params: { [routeName + 'Id']: toValue(bookmark).subject.id }
    })
}

watchEffect(() => {
    console.log(toValue(bookmark)?.subject)
})
</script>

<template>
    <PreviewCard v-if="!isLoading" :title="bookmark.subject?.id" :description="description"
        :author="bookmark.subject?.body_of_government?.name"
        :tags="bookmark.subject?.areas_of_law?.map(area => area.name)" :summary="summary" @open-full="openFull"
        @open-default="openFull" @open-split="addTab(bookmark.subject)">
        <template v-if="bookmark.subject?.constructor.name === 'Case'" #source>
            <CaseIcon />
            <span>Rechtspraak.nl</span>
        </template>
    </PreviewCard>
</template>