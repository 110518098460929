import { ref, computed, provide } from 'vue';

export function useSortable(items, { defaults = { by: 'time', dir: 'desc' }, metrics = {} } = {}) {
    const sortBy = ref(defaults.by)
    const sortDirection = ref(defaults.dir)

    function invertSortDirection() {
        if (sortDirection.value === 'asc') {
            sortDirection.value = 'desc'
        } else {
            sortDirection.value = 'asc'
        }
    }

    function toggleSort(by) {
        if (sortBy.value === by) {
            invertSortDirection()
        } else {
            sortBy.value = by
            sortDirection.value = 'asc'
        }
    }

    function isSorted(by, direction) {
        return sortBy.value === by && sortDirection.value === direction
    }

    const sortedItems = computed(() => {
        return [...items.value].sort((a, b) => {
            if (metrics[sortBy.value]) {
                return sortDirection.value === 'asc' ? 
                    metrics[sortBy.value](a, b) : 
                    metrics[sortBy.value](b, a)
            } else {
                const aValue = getSortableValue(a[sortBy.value]);
                const bValue = getSortableValue(b[sortBy.value]);
                return sortDirection.value === 'asc' ? 
                    compareValues(aValue, bValue) : 
                    compareValues(bValue, aValue);
            }
        })
    })


    provide('toggleSort', toggleSort)
    provide('isSorted', isSorted)

    return {
        sortedItems,
    }
}

// Helper functions
function getSortableValue(value) {
    if (value === null || value === undefined) return '';
    
    // Handle dates
    if (value instanceof Date) return value.getTime();
    if (!isNaN(Date.parse(value))) return new Date(value).getTime();
    
    // Handle numbers (including strings containing numbers)
    if (!isNaN(value)) return Number(value);
    
    // Handle strings
    return String(value).toLowerCase();
}

function compareValues(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}