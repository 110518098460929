<script setup>
import { defineProps } from 'vue';
import { cn } from '@/lib/utils'
import { Primitive } from 'radix-vue'

const props = defineProps({
  class: { type: String, default: '' },
  asChild: { type: Boolean, default: false },
  as: { type: String, default: 'span' }
})
</script>

<template>
  <Primitive data-sidebar="group-label" :as="as" :as-child="asChild" :class="cn(
    'duration-200 flex h-8 shrink-0 items-center rounded-md px-2 text-lg mb-1 font-medium text-sidebar-primary outline-none ring-sidebar-ring transition-[margin,opa] ease-linear focus-visible:ring-2 [&>svg]:size-4 [&>svg]:shrink-0',
    'group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0',
    props.class)">
    <slot />
  </Primitive>
</template>
