import { useQuery } from '@tanstack/vue-query'
import { useRepo, SheetTemplatePrompt, Prompt } from '@/models'
import { api } from '@/plugins/api'
import { toValue, computed } from 'vue'

export function useSheetTemplate(template) {
    const templateId = computed(() => toValue(template).id);
    const { isLoading } = useQuery({
        queryKey: ['templates', 'sheets', templateId, 'prompts'],
        queryFn: async () => {
            await useRepo(SheetTemplatePrompt).api().get(`/sheets/templates/${toValue(templateId)}/sheet-template-prompts`)
            const response = await useRepo(Prompt).api().get(`/sheets/templates/${toValue(templateId)}/prompts`)
            return response.entities
        }
    })

    const createPrompt = async (prompt, index = null) => {
        const response = await api.post(`/sheets/templates/${toValue(templateId)}/prompts`, {
            ...toValue(prompt),
            index: index || toValue(template).prompts.length
        })
        const [newPrompt, sheetTemplatePrompt] = response.data
        useRepo(Prompt).save(newPrompt)
        useRepo(SheetTemplatePrompt).save(sheetTemplatePrompt)
    }

    const updatePrompt = async (prompt, index = null) => {
        const response = await api.patch(`/sheets/templates/${toValue(templateId)}/prompts/${toValue(prompt).id}`, {
            ...toValue(prompt),
            index: index || toValue(template).prompts.length
        })
        const [newPrompt, sheetTemplatePrompts] = response.data
        useRepo(Prompt).save(newPrompt)
        useRepo(SheetTemplatePrompt).save(sheetTemplatePrompts)
    }

    const deletePrompt = async (prompt) => {
        const response = await api.delete(`/sheets/templates/${toValue(templateId)}/prompts/${toValue(prompt).id}`)
        const sheetTemplatePrompts = response.data
        useRepo(Prompt).destroy(toValue(prompt).id)
        useRepo(SheetTemplatePrompt).save(sheetTemplatePrompts)
    }

    return {
        isLoading,
        createPrompt,
        updatePrompt,
        deletePrompt
    }
}