<script setup>
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { ChevronDown } from 'lucide-vue-next'
import PeopleCombobox from './PeopleCombobox.vue'
import { defineModel, computed, defineExpose, h } from 'vue'
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar'
import { cn } from '@/lib/utils'

const selectedMembers = defineModel()
const displayValue = computed(() => {
    if (selectedMembers.value.length === 0) {
        return h('span', 'People')
    }

    return selectedMembers.value.map((member, index) =>
        h('Avatar', {
            key: member.id,
            class: ['size-6', 'shrink-0', 'ring-2', 'ring-background',
                { '-ml-3': index > 0 },
                `z-[${50 - index}]`]
        }, [
            h('AvatarImage', { src: member.profile_picture_url }),
            h('AvatarFallback', member.name.charAt(0))
        ])
    )
})

defineExpose({
    displayValue
})
</script>

<template>
    <Popover>
        <PopoverTrigger asChild>
            <slot>
                <Button variant="outline" size="sm" class="flex items-center gap-2">
                    <span v-if="selectedMembers.length === 0">People</span>
                    <Avatar v-for="(member, index) in selectedMembers" :key="member.id"
                        class="size-6 shrink-0 ring-2 ring-background"
                        :class="cn([{ '-ml-3': index > 0 }, `z-[${50 - index}]`])">
                        <AvatarImage :src="member.profile_picture_url" />
                        <AvatarFallback>{{ member.name.charAt(0) }}</AvatarFallback>
                    </Avatar>
                    <ChevronDown class="size-4 shrink-0 text-muted-foreground" />
                </Button>
            </slot>
        </PopoverTrigger>
        <PopoverContent class="w-[200px] p-0">
            <PeopleCombobox v-model="selectedMembers" />
        </PopoverContent>
    </Popover>
</template>