<script setup>
import { useVModel } from "@vueuse/core";
import { defineProps, defineEmits } from "vue";
import { cn } from "@/lib/utils";

const props = defineProps({
  class: { type: null, required: false },
  defaultValue: { type: [String, Number], required: false },
  modelValue: { type: [String, Number], required: false },
  draggable: { type: Boolean, required: false, default: false },
});

const emits = defineEmits(["update:modelValue"]);

const modelValue = useVModel(props, "modelValue", emits, {
  passive: true,
  defaultValue: props.defaultValue,
});
</script>

<template>
  <textarea v-model="modelValue" :class="cn(
    'flex min-h-[60px] w-full caret-secondary border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
    props.class
  )
    " :draggable="props.draggable" />
</template>
