<script setup>
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator } from '@/components/ui/dropdown-menu';
import { defineProps, defineModel } from 'vue';
import * as icons from 'lucide-vue-next';

const color = defineModel('color', { type: String });
const icon = defineModel('icon', { type: String });
const curatedIcons = [
    "Box", "Cuboid", "Cone", "Pyramid", "Cylinder", "Shapes", "Square", "SquareDot", "SquareMinus", "SquareSlash",
    "SquareRadical", "SquareActivity", "SquareEqual", "InspectionPanel", "Circle", "CircleDot", "CircleMinus",
    "CircleSlash", "CircleDashed", "CircleDotDashed", "Blend", "Diamond", "DiamondMinus", "Triangle", "Hexagon",
    "Bolt", "Octagon", "Torus", "Spade", "Club", "Cloud", "Sparkle", "Zap", "Droplet", "Flame", "Waves", "Wind",
    "Tornado", "Snowflake", "Hash", "Flower", "Leaf", "LeafyGreen", "Sprout", "LandPlot", "TreeDeciduous",
    "TreePalm", "TreePine", "Tent", "TentTree"
]
const filteredIcons = Object.fromEntries(Object.entries(icons).filter(([key]) => curatedIcons.includes(key)));

defineProps({
    colorOptions: {
        type: Array,
        required: false,
        default: () => ['primary', 'stone-500', 'slate-400', 'blue-900', 'indigo-600', 'emerald-600', 'amber-600', 'yellow-500', 'red-400']
    },
    editable: {
        type: Boolean,
        required: false,
        default: false
    },
    size: {
        type: Number,
        required: false,
        default: 24
    },
    fill: {
        type: String,
        required: false,
        default: 'none'
    }
});
</script>

<template>
    <DropdownMenu v-if="editable">
        <DropdownMenuTrigger>
            <component :is="icons[icon]" :class="`text-${color}`" :size="size" :fill="fill"
                class="cursor-pointer hover:text-primary transition-colors h-full" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" :alignOffset="-10">
            <DropdownMenuGroup class="flex items-center justify-between px-2 py-1.5">
                <component :is="icons[icon]" :size="18" :class="`text-${color}`" :fill="fill" />
                <div @select.prevent v-for="colorOption in colorOptions" :key="colorOption" :class="[
                    `bg-${colorOption}`,
                    'size-4 rounded-full cursor-pointer transition-none hover:bg-none !hover:bg-transparent !hover:text-foreground'
                ]" @click="color = colorOption">
                </div>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuGroup class="overflow-y-auto max-h-[300px] grid grid-cols-10 items-center">
                <DropdownMenuItem @select.prevent v-for="(Icon, name) in filteredIcons" :key="name"
                    class="cursor-pointer" @click="icon = name">
                    <component :is="Icon" :size="16" :fill="fill" />
                </DropdownMenuItem>
            </DropdownMenuGroup>
        </DropdownMenuContent>
    </DropdownMenu>
    <component v-else :is="icons[icon]" :class="`text-${color}`" :size="size" :fill="fill" class="h-full" />
</template>
