<script setup>
import { defineProps } from 'vue'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { useSidebar } from './utils'
import { Menu } from 'lucide-vue-next'

const props = defineProps({
  class: { type: String, default: '' },
})

const { toggleSidebar } = useSidebar()
</script>

<template>
  <Button data-sidebar="trigger" variant="ghost" size="icon"
    :class="cn('flex-shrink-0 w-5 h-5 text-muted-foreground hover:bg-transparent hover:border-transparent hover:text-primary', props.class)"
    @click="toggleSidebar">
    <Menu />
    <span class="sr-only">Toggle Sidebar</span>
  </Button>
</template>
