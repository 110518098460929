<script setup>
import { ToastProvider } from 'radix-vue'
import { defineProps } from 'vue'

const props = defineProps({
  label: { type: String, default: 'Notification' },
  duration: { type: Number, default: 5000 },
  swipeDirection: { type: String, default: 'right' },
  swipeThreshold: { type: Number, default: 50 }
})
</script>

<template>
  <ToastProvider v-bind="props">
    <slot />
  </ToastProvider>
</template>
