import { Model } from 'pinia-orm';
import { Folder } from '@/models/Folder';
import { ModelRun } from '@/models/Run';
import { DateCast } from '@/models/utils'
import { Project } from '@/models/Project'
import { User } from '@/models/User'
import { Activity } from '@/models/Activity'

export class Thread extends Model {
    static entity = 'threads'

    static fields() {
        return {
            id: this.attr(null),
            folder_id: this.attr(null),
            name: this.string('Untitled'),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            settings: this.attr({}),
            project_id: this.attr(null),
            icon: this.string('Text'),
            color: this.string('amber-400'),

            // Relationships
            folder: this.belongsTo(Folder, 'folder_id'),
            runs: this.hasMany(ModelRun, 'thread_id'),
            project: this.belongsTo(Project, 'project_id'),
            owner: this.belongsTo(User, 'user_id'),
            activity: this.hasMany(Activity, 'resource_id')
        }
    }

    get activity_at() {
        return new Date(Math.max(...this.activity.map(activity => activity.created_at), this.created_at))
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }

    get parent() {
        return this.folder;
    }

    set parent(parent) {
        this.folder = parent;
    }

    get isLocked() {
        return this.runs?.filter(r => r.status === 'running').length > 0;
    }
}
