<script setup>
import { IconPicker } from '.';
import { defineProps, defineModel } from 'vue';
import * as icons from 'lucide-vue-next';

const color = defineModel('color', { type: String });
const icon = defineModel('icon', { type: String });

defineProps({
    editable: {
        type: Boolean,
        required: false,
        default: false
    },
    size: {
        type: Number,
        required: false,
        default: 24
    },
    fill: {
        type: String,
        required: false,
        default: 'none'
    }
});
</script>

<template>
    <IconPicker v-if="editable" :fill="fill" :size="size" v-model:icon="icon" v-model:color="color" />
    <component v-else :is="icons[icon]" :class="`text-${color}`" :size="size" :fill="fill" class="h-full shrink-0" />
</template>
