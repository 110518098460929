<script setup>
import { computed, defineProps } from "vue";
import { TabsTrigger, useForwardProps } from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps({
  value: { type: String, required: true },
  disabled: { type: Boolean, required: false },
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  class: { type: null, required: false },
});

const delegatedProps = computed(() => {
  // eslint-disable-next-line no-unused-vars
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <TabsTrigger v-bind="forwardedProps" :class="cn(
    'inline-flex items-center justify-center cursor-pointer whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium bg-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-backgroundSecondary data-[state=active]:text-foreground data-[state=active]:shadow data-[state=active]:border data-[state=active]:border-border',
    props.class
  )
    ">
    <slot />
  </TabsTrigger>
</template>
