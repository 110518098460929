import { useAxiosRepo } from '@pinia-orm/axios';
import { Folder, Thread, Sheet, Document } from '@/models';
import { api } from '@/plugins/api';
import { toast } from 'vue-sonner';
import { useUpload } from '@/components/actions/use-upload';
import { useRouter } from 'vue-router';
import { computed, toValue, toRef } from 'vue';
import { useQuery } from '@tanstack/vue-query';


export function useLibrary(projectId, folderId, { embedded = false } = {}) {
    const folderRepo = useAxiosRepo(Folder).setAxios(api);
    const threadRepo = useAxiosRepo(Thread).setAxios(api);
    const sheetRepo = useAxiosRepo(Sheet).setAxios(api);
    const documentRepo = useAxiosRepo(Document).setAxios(api);
    const repoMap = {
        'folder': folderRepo,
        'thread': threadRepo,
        'sheet': sheetRepo,
        'document': documentRepo
    }

    projectId = toRef(projectId);
    folderId = toRef(folderId);

    const folder = computed(() => {
        return folderRepo.with('parent').find(toValue(folderId));
    });
    const parent = computed(() => {
        return folder.value?.parent;
    });

    let { handleUpload: upload } = useUpload(projectId, folderId);
    const router = useRouter();

    async function create(type, { name = 'New ' + type, autoOpen = true } = {}) {
        try {
            toast.info('Creating ' + type);
            const response = await repoMap[type].api().post('/' + type + 's/', {
                name: name,
                project_id: toValue(projectId),
                [type === 'folder' ? 'parent_id' : 'folder_id']: toValue(folderId)
            });
            toast.success('Successfully created ' + type);
            if (autoOpen) {
                open(response.entities[0]);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to create ' + type);
        }
    }

    async function move(target, items) {
        if (target !== null && !(target instanceof Folder)) {
            target = target.parent;
        }
        if (target === undefined || !items || items.includes(target)) return;
    
        const promises = [];
        for (const item of items) {
            const endpoint = `/actions/${item.entity}s/${item.id}/move`;
            const payload = { destination_folder_id: target?.id || null };
            promises.push(api.post(endpoint, payload));
        }
        const results = await Promise.all(promises);
        results.forEach((result, index) => {
            const item = items[index];
            repoMap[item.entity].save(result.data);
        });
    }

    async function save(target) {
        try {
            const response = await repoMap[target.constructor.name.toLowerCase()]
                .api()
                .put(`/${target.constructor.name.toLowerCase()}s/${target.id}`, target);
            toast.success(`Successfully updated '${response.entities[0].name}'`);
        } catch (error) {
            console.error(error);
            toast.error(`Failed to update '${target.name}'`);
        }
    }

    async function destroy(target) {
        try {
            await repoMap[target.constructor.name.toLowerCase()]
                .api()
                .delete(`/${target.constructor.name.toLowerCase()}s/${target.id}`, { delete: target.id });
            toast.success(`Successfully deleted '${target.name}'`);
        } catch (error) {
            console.error(error);
            toast.error(`Failed to delete '${target.name}'`);
        }
    }

    function open(target) {
        if (!embedded) {
            const type = target?.constructor?.name?.toLowerCase() || 'folder';
            const routeType = type === 'folder' ? 'documents' : type;
            const routeName = toValue(projectId) ? 'project-' + routeType : routeType;
            const route = { name: routeName, params: { projectId: toValue(projectId) } };
            if (type === 'folder') {
                if (target?.id) route.query = { folderId: target?.id };
            } else {
                route.params[`${type}Id`] = target.id;
            }
            router.push(route);
        }
        if (target instanceof Folder) {
            projectId.value = target.project_id;
            folderId.value = target.id;
        } else if (target === null) {
            folderId.value = null;
        }
    }

    async function fetchChildren(pId, fId = "*") {
        const promises = [];
        for (const [type, repo] of Object.entries(repoMap)) {
            promises.push(repo
                .api()
                .get('/' + type + 's/', {
                    params: {
                        project_id: toValue(pId),
                        [type === 'folder' ? 'parent_id' : 'folder_id']: toValue(fId)
                    }
                }));
        }
        await Promise.all(promises)
        return getChildren(pId, fId);
    }

    function getChildren(pId, fId = undefined) {
        const items = [];
        for (const [type, repo] of Object.entries(repoMap)) {
            items.push(...repo
                .withAllRecursive()
                .where('project_id', toValue(pId))
                .where(type === 'folder' ? 'parent_id' : 'folder_id', (value) => toValue(fId) === undefined || value === toValue(fId))
                .get());
        }
        return items;
    }

    const children = computed(() => getChildren(toValue(projectId), toValue(folderId)));

    const { isLoading } = useQuery({
        queryKey: ['children', projectId, folderId],
        queryFn: () => fetchChildren(projectId, folderId)
    });

    async function handleDrop(item, event) {
        if (event.dataTransfer.files.length > 0) {
            const { upload } = useUpload(item?.project_id || null, item?.folder_id || item?.id || null);
            await upload(event.dataTransfer.items);
        } else {
            await move(item, JSON.parse(event.dataTransfer.getData('text/plain')))
        }
    }

    return {
        folder,
        parent,
        open,
        move,
        create,
        save,
        destroy,
        upload,
        children,
        isLoading,
        getChildren,
        handleDrop,
        fetchChildren
    };
}