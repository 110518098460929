<script setup>
import { defineProps, inject, computed, toRefs } from 'vue';
import DragTarget from './DragTarget.vue';
import { Primitive } from 'radix-vue';

const props = defineProps({
    item: {
        type: Object,
        required: true
    }
})
const { item } = toRefs(props);

const {
    onDragstart,
    onDragend
} = inject('draggableHooks');
const {
    isItemDragged,
    isItemDraggedOver
} = inject('draggableHelpers');

const isDragged = computed(() => isItemDragged(item.value));
const isDraggedOver = computed(() => isItemDraggedOver(item.value));
</script>

<template>
    <Primitive draggable="true" @dragstart.stop="onDragstart($event, item)" @dragend.stop="onDragend" as-child
        :class="{ 'hidden': isDragged }">
        <DragTarget :item="item">
            <slot :is-dragged="isDragged" :is-dragged-over="isDraggedOver" />
        </DragTarget>
    </Primitive>
</template>