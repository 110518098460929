<script setup>
import { defineProps } from 'vue'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'

const props = defineProps({
  class: { type: String, default: '' },
})
</script>

<template>
  <Separator data-sidebar="separator" :class="cn('mx-3 w-auto bg-sidebar-border', props.class)">
    <slot />
  </Separator>
</template>
