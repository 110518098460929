import { Model } from 'pinia-orm';
import { DateCast } from '@/models/utils'
import { User } from '@/models/User'
import { ModelRun } from '@/models/Run'
import { Project } from '@/models/Project'
import { Review } from '@/models/Review'
import { Sheet } from '@/models/Sheet'
import { Thread } from '@/models/Thread'
import { Case } from '@/models/Case'
import { Document } from '@/models/Document'

export class Bookmark extends Model {
    static entity = 'bookmarks'
    static fields() {
        return {
            id: this.attr(null),
            note: this.string(''),
            consolidation_id: this.attr(null),
            collection_id: this.attr(null),
            project_id: this.attr(null),
            run_id: this.attr(null),
            review_id: this.attr(null),
            sheet_id: this.attr(null),
            thread_id: this.attr(null),
            case_id: this.attr(null),
            document_id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            project: this.belongsTo(Project, 'project_id'),
            run: this.belongsTo(ModelRun, 'run_id'),
            review: this.belongsTo(Review, 'review_id'),
            sheet: this.belongsTo(Sheet, 'sheet_id'),
            thread: this.belongsTo(Thread, 'thread_id'),
            case: this.belongsTo(Case, 'case_id'),
            document: this.belongsTo(Document, 'document_id')
        }
    }

    get subject() {
        return this.case
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export class Collection extends Model {
    static entity = 'collections'
    static fields() {
        return {
            id: this.attr(null),
            project_id: this.attr(null),
            icon: this.string('Box'),
            color: this.string('foreground'),
            name: this.string('Untitled'),
            description: this.string(''),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            bookmarks: this.hasMany(Bookmark, 'collection_id'),
            project: this.belongsTo(Project, 'project_id'),
            runs: this.hasMany(ModelRun, 'collection_id'),
            owner: this.belongsTo(User, 'user_id')
        }
    }

    get display() {
        return this.preview
    }

    get preview() {
        return {
            title: this.name,
            description: this.description,
            summary: '',
            author: this.owner?.name,
            tags: []
        }
    }

    get children() {
        return this.bookmarks || []
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
