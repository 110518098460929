<script setup>
import Suggestions from './Suggestions.vue'
import Progress from './Progress.vue'
import { Answer } from '@/tmp/components/answer'
import { computed, defineProps, ref, toRefs, watchEffect } from 'vue'
import { References } from '@/models/References'
import { useSearch } from '@/composables'

const props = defineProps(['run', 'threadId', 'isLast'])
const { run, threadId, isLast } = toRefs(props)
const { text, sendMessage } = useSearch('thread', threadId.value)

const references = ref([])

const filteredReferences = computed(() => {
    const referencedCaseIds = new Set(run.value?.referenced_sources?.cases || []);
    const referencedConsolidationIds = new Set(run.value?.referenced_sources?.consolidations || []);
    const referencedDocumentIds = new Set(run.value?.referenced_sources?.documents || []);

    const filteredCases = (run.value?.sources?.cases || []).filter(source =>
        referencedCaseIds.has(source.id)
    );

    const filteredConsolidations = (run.value?.sources?.consolidations || []).filter(source =>
        referencedConsolidationIds.has(source.id)
    );

    const filteredDocuments = (run.value?.sources?.documents || []).filter(source =>
        referencedDocumentIds.has(source.id)
    );

    return [...filteredCases, ...filteredConsolidations, ...filteredDocuments];
});


watchEffect(() => {
    if (run.value?.id) {
        references.value = References.getReferences(run.value.id)
    }
});
</script>

<template>
    <div class="flex flex-col w-full p-4 space-y-4">
        <Progress v-if="!run?.answer" :plan="run?.plan" />
        <Answer :show="run?.answer" :answer="run?.answer" :sources="run?.sources" :runId="run?.id" :status="run?.status"
            :filteredReferences="filteredReferences" :sendMessage="sendMessage" />
        <Suggestions v-if="isLast && (run?.status === 'success') && run?.suggested_follow_ups?.length > 0"
            v-model="text" :sendMessage="sendMessage" :suggestions="run?.suggested_follow_ups" />
    </div>
</template>

<style scoped>
.sticky {
    position: sticky;
    max-height: calc(100vh - 120px);
    /* Account for navbar and searchbar */
    overflow-y: auto;
}

@media (max-width: 768px) {
    .reference-list-hidden-mobile {
        display: none;
    }
}
</style>