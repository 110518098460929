<script setup>
import { Select, SelectTrigger, SelectContent, SelectItem } from '@/components/ui/select';
import { computed, defineModel, defineProps } from 'vue';
import { Button } from '@/components/ui/button';
import { ChevronDown } from 'lucide-vue-next';

const selectedOption = defineModel({ default: null })
const props = defineProps({
    options: { type: Array, required: true },
    placeholder: { type: String, default: 'Select option' }
})

const displayValue = computed(() =>
    props.options.find(opt => opt.value === selectedOption.value)?.label || props.placeholder
)
</script>

<template>
    <Select v-model="selectedOption">
        <SelectTrigger asChild>
            <Button variant="outline" size="sm" class="flex items-center gap-2 w-full">
                <component v-if="options.find(opt => opt.value === selectedOption)?.icon"
                    :is="options.find(opt => opt.value === selectedOption)?.icon" />
                <span>{{ displayValue }}</span>
                <ChevronDown class="size-4 shrink-0 text-muted-foreground" />
            </Button>
        </SelectTrigger>
        <SelectContent>
            <SelectItem v-for="option in options" :key="option.value" :value="option.value" check-position="right"
                class="cursor-pointer">
                <div class="flex items-center gap-2">
                    <component v-if="option?.icon" :is="option.icon" />
                    <span>{{ option.label }}</span>
                </div>
            </SelectItem>
        </SelectContent>
    </Select>
</template>