<script setup>
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter, DialogClose, DialogDescription } from '@/components/ui/dialog';
import CollectionForm from './CollectionForm.vue';
import { Button } from '@/components/ui/button';
import { ref } from 'vue';

const open = ref(false);
</script>

<template>
    <Dialog v-model:open="open">
        <DialogTrigger asChild>
            <slot />
        </DialogTrigger>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Create Collection</DialogTitle>
                <DialogDescription>
                    Create a new collection.
                </DialogDescription>
            </DialogHeader>
            <CollectionForm @submit="open = false">
                <DialogFooter class="flex gap-1 justify-end">
                    <DialogClose>
                        <Button variant="outline">Cancel</Button>
                    </DialogClose>
                    <Button type="submit">Create</Button>
                </DialogFooter>
            </CollectionForm>
        </DialogContent>
    </Dialog>
</template>
