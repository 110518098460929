<script setup>
import { SidebarMenuItem, SidebarMenuButton, SidebarMenuSub, SidebarMenuSubItem, SidebarMenuSubButton } from '@/components/ui/sidebar';
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';
import { ResourceItem, ResourceName, ResourceIcon } from '@/tmp/components/resources';
import { LibraryButton } from '@/tmp/services/library';
import { useRouter, useRoute } from 'vue-router';
import { defineProps, computed } from 'vue';
import { Layers } from 'lucide-vue-next';
import { RecentsButton } from '@/tmp/components/recents';

const router = useRouter();
const route = useRoute();

const props = defineProps({
    project: {
        type: Object,
        required: true
    }
})

const isActive = computed(() => route.params.projectId === props.project.id);
const isOverviewActive = computed(() => route.name === 'project' && isActive.value);
const openProject = () => router.push({ name: 'project', params: { projectId: props.project.id } })
</script>

<template>
    <SidebarMenuItem :class="{ 'bg-sidebar-accent rounded-xl': isActive }">
        <ResourceItem :item="project">
            <Collapsible :open="isActive" class="group/collapsible" as-child>
                <SidebarMenuButton :tooltip="project.name" @click="openProject" size="lg">
                    <ResourceIcon :stroke-width="isActive ? 2.5 : 2" />
                    <ResourceName :class="{ 'font-semibold': isActive }" />
                </SidebarMenuButton>
                <CollapsibleContent>
                    <SidebarMenuSub class="border-none -ml-0 pb-2 mr-1">
                        <SidebarMenuSubItem>
                            <ResourceItem :item="project">
                                <SidebarMenuSubButton @click="openProject" :is-active="isOverviewActive">
                                    <Layers :stroke-width="isOverviewActive ? 2.5 : 2" />
                                    <span>Overview</span>
                                </SidebarMenuSubButton>
                            </ResourceItem>
                        </SidebarMenuSubItem>
                        <SidebarMenuSubItem v-if="false">
                            <RecentsButton :project-id="project.id" sub-button />
                        </SidebarMenuSubItem>
                        <SidebarMenuSubItem>
                            <ResourceItem :item="project">
                                <LibraryButton :project="project" sub-button />
                            </ResourceItem>
                        </SidebarMenuSubItem>
                    </SidebarMenuSub>
                </CollapsibleContent>
            </Collapsible>
        </ResourceItem>
    </SidebarMenuItem>
</template>