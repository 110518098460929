<script setup>
import { ref, h, defineEmits, toValue } from 'vue';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { ToastAction, useToast } from '@/components/ui/toast';
import { IconPicker } from '@/tmp/components/controls';
import { PeopleCombobox } from '@/tmp/components/controls';
import { createCollection } from '../service';

const { toast } = useToast();

const form = ref({
    name: '',
    description: '',
    members: [],
    icon: {
        color: 'primary',
        icon: 'Shapes'
    }
});

const emit = defineEmits(['submit']);

const onSubmit = async (data) => {
    console.log(data);
    try {
        const response = await createCollection({
            name: toValue(data.name),
            description: toValue(data.description),
            acl: {
                write: toValue(data.members)?.map(m => m.id) || [],
            },
            icon: toValue(data.icon).icon,
            color: toValue(data.icon).color
        });
        emit('submit');
        toast({
            title: 'Collection created',
            description: response.entities[0].name,
        });
    } catch (error) {
        console.error(error);
        toast({
            title: 'Uh oh! Something went wrong.',
            description: 'There was a problem creating your collection.',
            variant: 'destructive',
            action: h(ToastAction, {
                altText: 'Try again',
            }, {
                default: () => onSubmit(data),
            }),
        });
    }
};
</script>

<template>
    <Form @submit="onSubmit" class="space-y-3">
        <div class="flex flex-row gap-2">
            <FormField name="icon" v-model="form.icon" v-slot="{ componentField }">
                <FormItem class="w-fit">
                    <FormControl>
                        <IconPicker v-bind="componentField" v-model="form.icon" as-button />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            </FormField>
            <FormField name="name" v-slot="{ componentField }">
                <FormItem class="flex-grow">
                    <FormControl>
                        <Input v-bind="componentField" v-model="form.name" placeholder="Enter project name" />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            </FormField>
        </div>

        <FormField name="description" v-slot="{ componentField }">
            <FormItem>
                <FormLabel>Description</FormLabel>
                <FormDescription>
                    Briefly describe the purpose of your collection.
                </FormDescription>
                <FormControl>
                    <Textarea v-bind="componentField" v-model="form.description"
                        placeholder="Enter collection description" :rows="4" />
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>

        <FormField name="members" v-slot="{ componentField }">
            <FormItem>
                <FormLabel>Share with</FormLabel>
                <FormDescription>
                    Select team members to share this collection with.
                </FormDescription>
                <FormControl>
                    <PeopleCombobox v-bind="componentField" v-model="form.members" />
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <slot>
            <Button type="submit">Submit</Button>
        </slot>
    </Form>
</template>
