<script setup>
import { inject, computed, toRefs, defineProps, onMounted } from 'vue';
import { Primitive } from 'radix-vue';

const toggleSelect = inject('toggleSelect')
const isSelected = inject('isSelected')
const items = inject('items')

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    preventSelect: {
        type: Boolean,
        required: false,
        default: false
    }
})
const { item } = toRefs(props)
const isItemSelected = computed(() => isSelected(item))

onMounted(() => {
    if (!items.value.some(i => i.id === item.value.id)) {
        items.value.push(item.value)
    }
})
</script>

<template>
    <Primitive @click="(event) => !preventSelect ? toggleSelect(item, event) : null" as-child>
        <slot :isSelected="isItemSelected" :toggleSelect="(event) => toggleSelect(item, event)" />
    </Primitive>
</template>
