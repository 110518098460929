<script setup>
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { SidebarMenuButton, SidebarMenuSubButton, SidebarMenuSubAction, SidebarMenuAction } from '@/components/ui/sidebar';
import { Library, ChevronRight } from 'lucide-vue-next';
import LibraryTreeRoot from './LibraryTreeRoot.vue';
import { ref, computed, defineProps } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { LibraryService } from '../service';

const router = useRouter()
const route = useRoute()

const props = defineProps({
    project: {
        type: Object,
        required: false,
        default: null
    },
    size: {
        type: String,
        required: false,
        default: 'md'
    },
    subButton: {
        type: Boolean,
        required: false,
        default: false
    },
    tooltip: {
        type: String,
        required: false,
        default: 'Library'
    }
})

const baseRoute = computed(() => props.project ? 'project-documents' : 'documents');
const routeOptions = computed(() => props.project ? [baseRoute.value, 'project-document', 'project-thread', 'project-sheet'] : [baseRoute.value, 'document', 'thread', 'sheet']);
const params = computed(() => props.project ? { projectId: props.project.id } : {});
const isExpanded = ref(routeOptions.value.includes(route.name) && route.params?.projectId === props.project?.id);
const isActive = computed(() => route.name === baseRoute.value && route.params?.projectId === props.project?.id && (!route.query?.folderId || !isExpanded.value));
const openLibrary = () => {
    router.push({ name: baseRoute.value, params: params.value })
    isExpanded.value = true
};
</script>

<template>
    <DragTarget :item="project" @drop="(item, event) => LibraryService.handleDrop(item, event)">
        <Collapsible v-model:open="isExpanded" class="group/collapsible" as-child>
            <DragTarget :item="project" @drop="(item, event) => LibraryService.handleDrop(item, event)">
                <component :is="subButton ? SidebarMenuSubButton : SidebarMenuButton" @click="openLibrary"
                    :is-active="isActive" :size="size" :tooltip="tooltip">
                    <Library :stroke-width="isActive ? 2.5 : 2" />
                    <span>
                        {{ tooltip }}
                    </span>
                </component>
                <CollapsibleTrigger as-child>
                    <component :is="subButton ? SidebarMenuSubAction : SidebarMenuAction" show-on-hover>
                        <ChevronRight class="transition-transform duration-300"
                            :class="isExpanded ? 'rotate-90' : ''" />
                    </component>
                </CollapsibleTrigger>
            </DragTarget>
            <CollapsibleContent class="-mr-[0.22rem]">
                <LibraryTreeRoot :project-id="project?.id" :sub-button="subButton" />
            </CollapsibleContent>
        </Collapsible>
    </DragTarget>
</template>