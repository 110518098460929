<script setup>
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Paperclip } from 'lucide-vue-next'
import { ref, defineModel } from 'vue'

const attachments = defineModel({ type: Array, default: () => [] })
const isOpen = ref(false)
</script>

<template>
    <Dialog v-model:open="isOpen">
        <DialogTrigger asChild>
            <Button variant="ghost" size="icon"
                class="rounded-full hover:bg-background/90 border-none hover:outline-none hover:border-none relative"
                :class="{ 'bg-accent rounded-full shadow-sm': attachments.length || isOpen }">
                <Paperclip />
                <Badge v-if="attachments.length" variant="ghost"
                    class="bg-secondary border-none text-white font-bold h-4 w-4 items-center justify-center absolute -right-0.5 -bottom-0.5 z-50">
                    {{ attachments.length }}
                </Badge>
            </Button>
        </DialogTrigger>

        <DialogContent>
            <DialogHeader class="flex flex-row items-center gap-2">
                <Paperclip />
                <DialogTitle>Attachments</DialogTitle>
            </DialogHeader>

            <!-- Add your attachment content here -->
            <div class="grid gap-4 py-4">
                <div v-if="attachments.length === 0" class="text-center text-muted-foreground">
                    No attachments yet
                </div>
                <div v-else class="grid gap-2">
                    <!-- List your attachments here -->
                    <div v-for="attachment in attachments" :key="attachment.id" class="flex items-center gap-2">
                        <!-- Add attachment display logic -->
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>
</template>