<script setup>
import { defineProps, defineModel } from 'vue';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

const name = defineModel('name', { type: String });

defineProps({
    size: {
        type: String,
        required: false,
        default: 'xl'
    },
    editable: {
        type: Boolean,
        required: false,
        default: false
    }
});
</script>

<template>
    <Input v-if="editable" v-model="name"
        :class="cn('font-bold border-none bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-0 p-0 w-full', `text-${size}`, $attrs.class)" />
    <span v-else :class="cn('font-bold', `text-${size}`, $attrs.class)">{{ name }}</span>
</template>
