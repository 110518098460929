<script setup>
import { cn } from '@/lib/utils';
import { defineProps } from 'vue';
import { SidebarTrigger, useSidebar } from '@/components/ui/sidebar';

defineProps({
    secondary: {
        type: Boolean,
        default: false
    }
})

const { isMobile } = useSidebar();
</script>

<template>
    <header
        :class="cn('flex flex-col shrink-0 items-center justify-between gap-2 z-50 h-full rounded-t-2xl py-1.5', $attrs.class)">
        <div class="flex flex-row w-full items-center justify-between rounded-t-2xl">
            <div v-if="$slots.left" class="flex items-center justify-start gap-3">
                <slot name="left" />
            </div>
            <div class="flex items-center justify-start gap-3 w-full overflow-x-auto">
                <slot name="content" />
            </div>
            <div v-if="$slots.right || !secondary" class="flex items-center gap-3 justify-end px-3">
                <slot name="right" />
                <SidebarTrigger v-if="isMobile && !secondary" />
            </div>
        </div>
    </header>
</template>
