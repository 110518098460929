import { useRepo, Sheet, SheetPrompt, SheetDocument, SheetTemplate, Prompt, Document, ModelRun, SheetTemplatePrompt } from '@/models'
import { useQuery } from '@tanstack/vue-query'
import { toValue, computed } from 'vue'
import * as XLSX from 'xlsx'
import { api } from '@/plugins/api'


export function getSheet({ sheetId, withAll = false }) {
    const { isLoading } = useQuery({
        queryKey: ['sheets', sheetId, withAll],
        queryFn: async () => {
            const promises = []
            promises.push(useRepo(Sheet).api().get(`/sheets/${toValue(sheetId)}`))
            if (toValue(withAll)) {
                promises.push(useRepo(Prompt).api().get(`/sheets/${toValue(sheetId)}/prompts`))
                promises.push(useRepo(SheetPrompt).api().get(`/sheets/${toValue(sheetId)}/sheet-prompts`))
                promises.push(useRepo(Document).api().get(`/sheets/${toValue(sheetId)}/documents`))
                promises.push(useRepo(SheetDocument).api().get(`/sheets/${toValue(sheetId)}/sheet-documents`))
                promises.push(useRepo(ModelRun).api().get(`/sheets/${toValue(sheetId)}/runs`))
            }
            return Promise.all(promises)
        }
    })
    return {
        isLoading,
        sheet: computed(() => useRepo(Sheet).withAllRecursive().find(toValue(sheetId)))
    }
}

export async function updateSheet(sheet) {
    await useRepo(Sheet).api().patch(
        `/sheets/${toValue(sheet).id}`,
        toValue(sheet)
    );
    return useRepo(Sheet).withAllRecursive().find(toValue(sheet).id);
}

export async function deleteSheet(sheet) {
    await useRepo(Sheet).api().delete(
        `/sheets/${toValue(sheet).id}`,
        { delete: toValue(sheet).id }
    );
}

export async function createSheet({ name = null, projectId = null, folderId = null, documents = null, prompts = null } = {}) {
    const response = await useRepo(Sheet).api().post('/sheets/', {
        name: toValue(name) || 'New Sheet',
        project_id: toValue(projectId) || null,
        folder_id: toValue(folderId) || null,
        documents: toValue(documents) || null,
        prompts: toValue(prompts) || null
    })
    return useRepo(Sheet).withAllRecursive().find(response.entities[0].id);
}

export async function enterSheet({ socket, sheet }) {
    await socket.emit('enter_sheet', { sheet_id: toValue(sheet).id })
}

export async function leaveSheet({ socket, sheet }) {
    await socket.emit('leave_sheet', { sheet_id: toValue(sheet).id })
}

export async function addPrompt({ socket, sheet, prompt, index = null }) {
    await socket.emit('add_prompt', { sheet_id: toValue(sheet).id, index: index, ...prompt })
}

export async function updatePrompt({ socket, sheet, prompt, index }) {
    if (!toValue(prompt).content) return
    await socket.emit('update_prompt', { sheet_id: toValue(sheet).id, prompt: toValue(prompt), index: index })
}

export async function removePrompt({ socket, sheet, promptId }) {
    if (!promptId) return
    await socket.emit('remove_prompt', { sheet_id: toValue(sheet).id, prompt_id: promptId })
    useRepo(SheetPrompt).where('prompt_id', promptId).where('sheet_id', toValue(sheet).id).delete()
}

export async function addDocument({ socket, sheet, documentId, index }) {
    if (!documentId || index === null || index === undefined) return
    await socket.emit('add_document', { sheet_id: toValue(sheet).id, index: index, document_id: documentId })
}

export async function updateDocument({ socket, sheet, documentId, index = null }) {
    await socket.emit('update_document', { sheet_id: toValue(sheet).id, document_id: documentId, index: index })
}

export async function removeDocument({ socket, sheet, documentId }) {
    if (!documentId) return
    await socket.emit('remove_document', { sheet_id: toValue(sheet).id, document_id: documentId })
    useRepo(SheetDocument).where('document_id', documentId).where('sheet_id', toValue(sheet).id).delete()
}

export async function startRun({ socket, sheet, status = 'incomplete', promptIds = null, documentIds = null }) {
    await socket.emit('start_run', { sheet_id: toValue(sheet).id, status: status, prompt_ids: promptIds, document_ids: documentIds })
}

export async function cancelRun({ socket, sheet, promptIds = null, documentIds = null }) {
    await socket.emit('cancel_run', { sheet_id: toValue(sheet).id, prompt_ids: promptIds, document_ids: documentIds })
}

export function exportToExcel({ sheet, name = null }) {
    const excelData = toValue(sheet).documents.map(document => ({
        Document: document.title,
        ...Object.fromEntries(
            toValue(sheet).prompts.map(prompt => [
                prompt.content,
                toValue(sheet).cell(prompt, document).output
            ])
        )
    }))
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, `${toValue(sheet).name || name || 'sheet'}.xlsx`)
}

export async function saveAsTemplate({ sheet, template = null, name = null }) {
    if (!toValue(template)) {
        await useRepo(SheetTemplate).api().post('/sheets/templates/', {
            name: toValue(sheet).name || name,
            description: toValue(sheet)?.description,
            prompts: toValue(sheet).prompts.map((prompt, index) => ({
            label: prompt.label,
            content: prompt.content,
            format: prompt.format,
                index: index
            }))
        })
    } else {
        const createPrompt = async (prompt, index = null) => {
            const response = await api.post(`/sheets/templates/${toValue(template).id}/prompts`, {
                ...toValue(prompt),
                index: index || toValue(template).prompts.length
            })
            const [newPrompt, sheetTemplatePrompt] = response.data
            useRepo(Prompt).save(newPrompt)
            useRepo(SheetTemplatePrompt).save(sheetTemplatePrompt)
        }
        
        const deletePrompt = async (prompt) => {
            const response = await api.delete(`/sheets/templates/${toValue(template).id}/prompts/${toValue(prompt).id}`)
            const sheetTemplatePrompts = response.data
            useRepo(Prompt).destroy(toValue(prompt).id)
            useRepo(SheetTemplatePrompt).save(sheetTemplatePrompts)
        }
        const promptsToAdd = toValue(sheet).prompts.map((prompt, index) => ({
            label: prompt.label,
            content: prompt.content,
            format: prompt.format,
            index: index
        }))
        const promptsToDelete = toValue(template).prompts
        const promises = []
        for (const [index, prompt] of promptsToAdd.entries()) {
            promises.push(createPrompt(prompt, index))
        }
        for (const prompt of promptsToDelete) {
            promises.push(deletePrompt(prompt))
        }
        await Promise.all(promises)
    }
}

export async function applyTemplate({ socket, sheet, template }) {
    const prompts = toValue(template).prompts
    const n = toValue(sheet).prompts.length;
    const addPromptPromises = prompts.map(async (prompt, index) => {
        await addPrompt({ socket, sheet, prompt, index: n + index })
    })
    await Promise.all(addPromptPromises)
}

export const SheetService = {
    enterSheet,
    leaveSheet,
    addPrompt,
    updatePrompt,
    removePrompt,
    addDocument,
    updateDocument,
    removeDocument,
    startRun,
    cancelRun,
    exportToExcel,
    saveAsTemplate,
    getSheet,
    updateSheet,
    deleteSheet,
    createSheet,
    applyTemplate
}