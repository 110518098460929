<script setup>
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { ListFilter } from 'lucide-vue-next'
import { ref, defineModel } from 'vue'

const sources = defineModel({ type: Object, default: () => { } })
const isOpen = ref(false)
</script>

<template>
    <Dialog v-model:open="isOpen">
        <DialogTrigger asChild>
            <Button variant="ghost" size="icon"
                class="rounded-full hover:bg-background/90 border-none hover:outline-none hover:border-none relative"
                :class="{ 'bg-accent rounded-full shadow-sm': !!sources || isOpen }">
                <ListFilter />
            </Button>
        </DialogTrigger>

        <DialogContent>
            <DialogHeader class="flex flex-row items-center gap-2">
                <ListFilter />
                <DialogTitle>Sources</DialogTitle>
            </DialogHeader>

            <!-- Add your attachment content here -->
            <div class="grid gap-4 py-4">
                <div v-if="!sources" class="text-center text-muted-foreground">
                    No sources yet
                </div>
            </div>
        </DialogContent>
    </Dialog>
</template>