<script setup>
import MasonryWall from '@yeger/vue-masonry-wall';
import { useCollection } from '../composables';
import BookmarkCard from './BookmarkCard.vue';
import { Loading } from '@/components';
import { defineProps, toRefs, ref } from 'vue';

const props = defineProps({
    collectionId: {
        type: String,
        required: true,
    }
});

const { collectionId } = toRefs(props)
const { bookmarks, isLoading } = useCollection(collectionId)
const scrollContainer = ref(null)
</script>

<template>
    <div ref="scrollContainer" class="overflow-y-auto h-full w-full px-4 pb-4">
        <Loading v-if="isLoading" />
        <MasonryWall v-else :items="bookmarks" :max-columns="3" :key-mapper="item => item.id" :gap="16"
            :scroll-container="scrollContainer" :column-width="300">
            <template #default="{ item }">
                <BookmarkCard :bookmark-id="item.id" />
            </template>
        </MasonryWall>
    </div>
</template>