<script setup>
import { EditableIcon } from '@/tmp/components/controls';
import { inject, defineProps } from 'vue';

defineProps({
    editable: {
        type: Boolean,
        default: false,
    },
});

const item = inject('item');
</script>

<template>
    <EditableIcon :icon="item.icon" :color="item.color" :editable="editable" />
</template>
